import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { VariablesAbsenceService } from 'src/app/services/variable-absence-service';
import { IVariableAbsence, VariableAbsence } from 'src/app/class/variableAbsence';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-variable-absence-create',
  templateUrl: './variable-absence-create.component.html',
  styleUrls: ['./variable-absence-create.component.scss']
})
export class VariableAbsenceCreateComponent implements OnInit {
  customers: any;
  absenceCreateForm: FormGroup;
  searchText;
  submitted;
  absenceFile: any;
  selectedCustomer: any;
  isSaving = false;
  unit = [
    { id: 1, name: 'heure' },
    { id: 2, name: 'jour' },
  ];
  typeAbsence = [{ id: 1, code: 'CP', name: 'Congés Payés' },
  { id: 2, code: 'MALADIE', name: 'Arrêt maladie' },
  { id: 3, code: 'SANS_SOLDE', name: 'Congés sans solde' },
  { id: 4, code: 'NON_JUSTIF', name: 'Non justifié' },
  { id: 5, code: 'AUTRE', name: 'Autre' }
];
  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    protected alertService: AlertService,
    protected formService: FormChangeService,
    protected customerService: CustomerService,
    private authenSer: AuthenticationService,
    private variableAbsenceService: VariablesAbsenceService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  rechercher() {
    // let filter = this.getFilter();
    if (this.authenSer.isAdmin) {
      // this.getCustomersFromBackEnd(filter)
      //   .subscribe(
      //     data => {
      //       let tmp = data as Array<any>;
      //       if (tmp) {
      //         if (filter) {
      //           if (filter['searchText']) {
      //             tmp = this.findTextInPlaces(tmp, filter['searchText']);
      //           }
      //         }
      //       }

      //       this.customers = tmp;
      //     }
      //     , error => {

      //       let msg = error['error'] ? error['error']['error'] + ': ' : error;
      //       this.alertService.error(msg);
      //       this.formService.raiseCustomerListEvent({});

      //     });

    }
  }
  initForm() {

    this.absenceCreateForm = this.fb.group({
      customerId: [],
      codeAbsence: ['', Validators.required],
      name: ['', Validators.required],

      dateCreation: [],
      dateModification: [],
      id: [],
      unit: ['', Validators.required],
      type: ['', Validators.required],
    });
  }
  get f() { return this.absenceCreateForm.controls; }

  save(): void {
    this.submitted = true;

    if (!this.absenceCreateForm.invalid) {
      this.absenceFile = this.createFromForm();
      // this.subscribeToSaveResponse(
        this.variableAbsenceService.postVariableAbsenceCreate(this.absenceFile, null)
        .subscribe(
          (data) => {
            this.isSaving = false;
            this.alertService.success('Variable Sauvegardé !', false);
            // this.formService.raisereloadSearchRequestEvent();
            this.formService.raisereReloadAbsencesEvent();
            this.activeModal.close(true);
          },
          () => {
            this.alertService.error('Erreur dans le formulaire !', false);
            this.activeModal.close(false);
          }
        );

    } else {
      return;
    }

  }
  close(){
    this.activeModal.close(false);
  }
  private createFromForm(): IVariableAbsence {

    return {
      ...new VariableAbsence(),
      // id: this.absenceCreateForm.get(['id'])!.value,
      // customerId: this.customerId,
      codeAbsence: this.absenceCreateForm.get(['codeAbsence'])!.value,
      name: this.absenceCreateForm.get(['name'])!.value,
      unit: this.absenceCreateForm.get(['unit'])!.value,
      type: this.absenceCreateForm.get(['type'])!.value,
    };
  }
  // private findTextInCustomers(customers, text) {
  //   text = text?text.split(' '):'';
  //   return customers.filter(function (item) {

  //     return text.every(function (el) {
  //       return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1
  //         || item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
  //     });
  //   });
  // }
  // protected subscribeToSaveResponse(result: Observable<HttpResponse<IVariableAbsence>>): void {
  //   result.subscribe(
  //     () => this.onSaveSuccess(),
  //     () => this.onSaveError()
  //   );
  // }

  // protected onSaveSuccess(): void {
  //   this.isSaving = false;

  //   this.alertService.success('Variable Sauvegardé !', false);
  //   // this.formService.raisereloadSearchRequestEvent();
  //   this.formService.raiseAbsenceListEvent();
  //   this.close();


  // }

  // protected onSaveError(): void {
  //   this.alertService.error('Erreur dans le formulaire !', false);
  // }

  trackById(index: number, item: any): any {
    return item.id;
  }

  getCustomersFromBackEnd(filter) {
    return this.customerService.getCustomers();
  }
  private findTextInPlaces(employees, text) {
    text = text?text.split(' '):'';
    return employees.filter(function (item) {

      return text.every(function (el) {
        return item['name'].toUpperCase().indexOf(el.toUpperCase()) > -1
          || item['firstname'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }
  // private getFilter() {
  //   let filter = {
  //     searchText: this.searchText ? this.searchText : null
  //   };
  //   return filter;
  // }
}
