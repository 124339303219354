import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { VariablesPayeService } from '../services/variable-paye-service';
import { VariablePayeUpdateComponent } from './variable-paye-update.component';
import { VariablePayeCreateComponent } from './variable-paye-create.component';
import { AlertService } from '../services/alert-service.service';
import { FormChangeService } from '../services/form-change.service';
import { CustomerService } from '../services/customer.service';
import { filter } from 'rxjs/operators';
import { Role } from '../class/role';
import { AuthenticationService } from '../services/authentication.service';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { TenantService } from '../services/tenant.service';
import { PayeSystem } from '../class/unit.enum';

@Component({
  selector: 'app-variable-paye',
  templateUrl: './variable-paye.component.html',
  styleUrls: ['./variable-paye.component.scss']
})
export class VariablePayeComponent implements OnInit {
  eventSubscriber?: Subscription;
  variablesPayeLst = new Array();
  selectedVariables: any;
  customerName: any;
  tenantPayeSystem;
  columnsHeaderToDisplay = new Array();

  constructor(protected variablesPayeService: VariablesPayeService, protected modalService: NgbModal,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    protected alertService: AlertService,
    protected customerService: CustomerService,
    private tenanteServ: TenantService) { }

  ngOnInit(): void {
    this.columnsHeaderToDisplay = ["Code paye",'Libellé','Unité','Valeur par défaut',
  "Texte d'information",'Saisie obligatoire','Date de création','Date de modification',
  ];
    this.tenanteServ.getTenants().subscribe(
      (data)=>{
        this.tenantPayeSystem = data['systemPaie'];

        if(PayeSystem.SAGE.toString() == PayeSystem[this.tenantPayeSystem] ){
          this.columnsHeaderToDisplay.push("Type d'élément SAGE");
        }
      },
      (error)=>{

      },
      ()=>{
        this.columnsHeaderToDisplay.push('Actions');
      }
    )
    this.rechercher();
    this.subscribeformChange();
  }
hasSage(){
  return PayeSystem[this.tenantPayeSystem] == PayeSystem.SAGE.toString();
}
  subscribeformChange() {
    this.formService.formVariablePaieSearchChanged.subscribe(
      (param) => {

        this.variablesPayeLst = param;
      });
  }
  rechercher() {
    this.variablesPayeService.getVariablePaye().subscribe(
      (param: any) => {
        this.variablesPayeLst = param;
        debugger;
      },
      error => {
        console.log(error);
      }
    );

  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppression d'une variable";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }
  delete(variablePaye: any): void {
      this.variablesPayeService.delete(variablePaye)
      .subscribe(
        data =>{
          this.rechercher();
          this.alertService.success("Variable Supprimée !");
          this.formService.raisereloadSearchRequestEvent();
        } ,
        error => {
          this.alertService.warn('Erreur de suppression! Votre variable n\'a pas été supprimée.');
        }
      );
  }
  edit(variable) {
    const modalRef = this.modalService.open(VariablePayeUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.variable = variable;
    modalRef.componentInstance.mandatory = variable.mandatory;
  }
  create(): void {
    debugger;
    const modalRef = this.modalService.open(VariablePayeCreateComponent, { backdrop: 'static' });
  }

}
