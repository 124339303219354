import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from 'src/app/services/employee.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-employees-import',
  templateUrl: './employees-import.component.html',
  styleUrls: ['./employees-import.component.scss']
})
export class EmployeesImportComponent implements OnInit {
  selectedCustomer: any;
  customers: any;
  model: any = {};
  returnUrl = '/home';
  fileToUpload: File;
  customerId;
  siteId;
  lstSites = new Array();
  loading =false;
  constructor(public authenticationService: AuthenticationService,
    private employeeService: EmployeeService,
    public alertService: AlertService,
    public router: Router,
    private authenSer: AuthenticationService,
    protected customerService: CustomerService,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.getCustomersForSelect();
    this.getSitesForSelect("");
  }

  clientSelected(){
    let cusId = this.customerId;
    this.getSitesForSelect(cusId);
  }
  getListSite(){
    return this.lstSites;
  }
  getSitesForSelect(selectedCustomerId): void {
    if (this.authenSer.isAdmin) {
      this.getSitesFromBackEndWithCustomerId(selectedCustomerId).subscribe(
        (dataSite) => {
          this.lstSites = new Array();
          let tmp = dataSite as Array<any>;
          tmp.forEach(site =>{
            this.lstSites.push(site);
          });
        },
        (error) => {
          console.log(error);
          // let msg = error["error"] ? error["error"]["error"] + ": " : error;
          // this.alertService.error(msg);
        }
      );
    } else {
      this.getSitesFromBackEnd().subscribe(
        (dataSite) => {
          let tmp = dataSite as Array<any>;
          tmp.forEach(site =>{
            this.lstSites.push(site);
          });

        },
        (error) => {
          console.log(error);
          // let msg = error["error"] ? error["error"]["error"] + ": " : error;
          // this.alertService.error(msg);
        }
      );
    }
  }
  getSitesFromBackEnd() {
    return this.employeeService.getSites(this.authenSer.getUserLoggedIn());
  }

  getSitesFromBackEndWithCustomerId(id) {
    return this.employeeService.getSiteCustomerId(
      id,
      this.authenSer.getUserLoggedIn()
    );
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  closeModal(): void {
    this.activeModal.close();
  }
  getCustomersForSelect(): void {

    this.getCustomersFromBackEnd().subscribe(
      dataSite => {
        let tmp = dataSite as Array<any>;
        this.customers = tmp;
      },
      error => {
        let msg = error['error'] ? error['error']['error'] + ': ' : error;
        this.alertService.error(msg);
      }
    );
  }
  getCustomersFromBackEnd() {
    return this.customerService.getCustomers();
  }
  send() {
    this.loading = true;

    return this.employeeService.sendImportEmployee(this.customerId, this.siteId, this.fileToUpload)
      .subscribe(
        data => {
          this.router.navigateByUrl('/salaries', { skipLocationChange: true }).then(() => {
            this.router.navigate(['import-employee']);
          });
          this.loading = false;
          let self = this;
          setTimeout(function () {
            self.alertService.success('Document envoyé !', true);
          }, 500);
          this.closeModal();
        },
        error => {
          this.loading = false;
          const msg = 'erreur lors de l\'envoie du formulaire';
          this.alertService.error(msg);
        });
  }
}

