import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { CustomerService } from '../services/customer.service';
import { AlertService } from '../services/alert-service.service';
import { debug } from 'util';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  data = {};
  constructor(private authenSer: AuthenticationService,
    private customerServ: CustomerService,
    private alertServ: AlertService) { }

  ngOnInit(
    ) {
      this.customerServ.getUserTenent().subscribe(
        data =>{
          this.data = data;

        },
        (error) =>{
          // this.alertServ.error(error);
        }
      );

  }

}
