import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormChangeService } from '../services/form-change.service';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert-service.service';
import { UserService } from '../services/user-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { CustomerService } from '../services/customer.service';
import { ProspectUpdateComponent } from './prospect-update/prospect-update.component';
import { ProspectCreateComponent } from './prospect-create/prospect-create.component';
import { ProspectService } from '../services/prospect.service';
import { Router } from '@angular/router';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { prospectStatus, soncas } from '../class/unit.enum';

@Component({
  selector: 'app-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProspectsComponent implements OnInit, OnDestroy {

  constructor(private formService: FormChangeService,
    private authenSer: AuthenticationService,
    private alertService: AlertService,
    private prospectServ: ProspectService,
    private modalService: NgbModal,
    private router: Router

  ) { }
  ngOnDestroy(): void {
    clearInterval(this.timerKeepAlive);
  }
  timerKeepAlive:any;
  searchText;
  prospectStatusEnum = prospectStatus;
  soncasEnum = soncas;
  lstProspects = new MatTableDataSource(new Array());
  displayedColumns = ['expandIcon','firstContact','soncas','status','companyName','contactMail','contactTel','nextContact','Actions'
  ];
  innerDisplayedColumns = ['lastContact','modeLastContact','adress','note'];
  expandedElement: {} | null;

  // dataSource =
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  getKeys(object): string[] {
    return Object.keys(object);
  }

  ngOnInit() {
    this.subscribeformChange();
    this.lstProspects.sort = this.sort;
    this.keepSessionAlive();
  }
  keepSessionAlive() {
    console.log('call KeepAlive');
    this.timerKeepAlive = setInterval(()=>{this.authenSer.keepAlive().subscribe(
      (data) =>{
        console.log('session keepAlive');
      },
      (error) => {
        console.log(error);
      }
    );
    }
    , 120000);
    }
  subscribeformChange() {
    this.formService.formProspectSearchChanged.subscribe(
      (param) => {

        this.lstProspects = new MatTableDataSource(param);

      });

  }

  create(): void {
    this.router.navigateByUrl('/create-prospect');
  }
  edit(prospect) {

    this.router.navigateByUrl('/update-prospect', { state: {prospect:prospect}});

  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'un prospect";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }
  delete(id) {
    this.prospectServ.deleteProspects(id).subscribe(
      () => this.onDeleteSuccess(),
      () => this.onDeleteError()
    );

  }
  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raiseReloadProspectSearchRequestEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }

  onChange() {
    this.formService.raiseReloadProspectSearchRequestEvent();
  }
}
