import { Component, OnInit, Input } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { FormChangeService } from '../services/form-change.service';
import { EmployeeService } from '../services/employee.service';
import { AlertService } from '../services/alert-service.service';
import { CustomerService } from '../services/customer.service';
import { AuthenticationService } from '../services/authentication.service';
import { NgModel } from '@angular/forms';
import { UserService } from '../services/user-service';



@Component({
  selector: 'app-form-min-user',
  templateUrl: './form-min-user.component.html',
  styleUrls: ['./form-min-user.component.scss']
})
export class FormMinUserComponent implements OnInit {
  sites: {};
  selectedCustomer: string;
  customers;
  selectedSite: any[];

  selectAll: boolean;
  searchText: string;
  searchTextCust: string;
  newSearched = true;
  numeroMin: number;
  numeroMax: number;
  model: NgModel;
  countAnnonces;
  @Input() withPeriod: boolean;
  period: any;
  selectedPeriod: string;
  periodList: any[];
  constructor(
    private alertService: AlertService,
    private employeeService: EmployeeService,
    private customerService: CustomerService,
    private authenSer: AuthenticationService,
    private formService: FormChangeService,
    public userService: UserService,
    public router: Router) {
  }


  ngOnInit() {
    if(this.authenSer.isAdmin){
      this.loadCustomers();
    }
    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadSearchRequestUser.subscribe(
      () => {
        this.rechercher();
      });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private filterUsersByText(users) {
    let filter = this.getTextFilter();
    if (filter) {
      let text = filter.split(' ');

      return users.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = item['name'].toUpperCase().indexOf(el.toUpperCase()) > -1
            || item['firstname'].toUpperCase().indexOf(el.toUpperCase()) > -1
            || item['login'].toUpperCase().indexOf(el.toUpperCase()) > -1;
            return tmp;
        });
        return flt;
      });
    } else {
      return users;
    }
  }

  private getTextFilter() {
    return this.searchText;
  }
private loadCustomers() {
  this.getCustomersFromBackEnd()
      .subscribe(
        data => {
          this.customers = data as Array<any>;
        }
        , error => {
          console.log(error);
          this.formService.raiseCustomerListEvent({});
        });
}
rechercher() {
    this.getUserFromBackend()
      .subscribe(
        data => {
          let tmpUsers = data as Array<any>;
          if (this.selectedCustomer && this.selectedCustomer.length > 0) {
            tmpUsers = tmpUsers.filter(el => {
              let buff: any[] = [];
              buff[0] = this.selectedCustomer;

              return buff.find(cus => cus === el.customerId);
            });

          }
          tmpUsers = this.filterUsersByText(tmpUsers);

          this.formService.raiseFormMinUserSearch(tmpUsers);

        }
        , error => {

          console.log(error);
          this.formService.raiseFormMinUserSearch({});

        });
  }


  getUserFromBackend() {
    return this.userService.getUser();
  }

  getSiteFromBackendCustomerId(customerId: string) {
    let currentUser = this.authenSer.getUserLoggedIn();
    return this.employeeService.getSiteCustomerId(customerId, currentUser);

  }

  getSiteFromBackend() {
    let currentUser = this.authenSer.getUserLoggedIn();
    return this.employeeService.getSites(currentUser);
  }

  onFormChange() {
    this.rechercher();
  }

  getCustomersFromBackEnd() {
    return this.customerService.getCustomers();
  }

}
