import { Component, OnInit, ViewChild, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import {Subscription, Observable } from 'rxjs';
import { FormChangeService } from '../services/form-change.service';
import { AlertService } from '../services/alert-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { CustomersUpdateComponent } from './customers-update.component';
import { CustomerService } from '../services/customer.service';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CustomersCreateComponent } from './customers-create/customers-create.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, OnDestroy {
  subscriptions = new Array<Subscription>();
  lstCustomers = [];
  filter: any;
  columnsHeaderToDisplay = ["Nom de l'entreprise",'Siret','Telephone','Email','Actions'
  ];
  overlayRef: OverlayRef | null;

  constructor(public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private alertService: AlertService,
    private formService: FormChangeService,
    private customerService: CustomerService,
    public router: Router,
    protected modalService: NgbModal) { }

    ngOnDestroy(): void {
      this.subscriptions.forEach(sub =>{
        sub.unsubscribe();
      });
    }
  ngOnInit() {
    this.subscribeformChange();
  }

  subscribeformChange() {
    this.subscriptions.push(
      this.formService.formCustomerSearchChanged.subscribe(
      (param) => {
        this.lstCustomers = param;
        if(this.lstCustomers){
          this.lstCustomers.forEach(el =>{
            if (!el.hasOwnProperty('companyName')){
              el['companyName'] = '';
            }
          });
        }
      }));
  }
  openInvalidatePeriodConfirm(idcustomer){
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Réouverture de la période de paie précédente";
    modalRef.componentInstance.message = "Souhaitez vous réouvrir la saisie pour la période précédente ?";
    modalRef.componentInstance.okBtnMsg = 'Oui';
    modalRef.componentInstance.cancelBtnMsg = 'Non';
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.invalidateLastPeriod(idcustomer);
      }
    });
  }
  invalidateLastPeriod(idCustomer) {
      this.customerService.invalidateCustomerLastPeriod(idCustomer)
      .subscribe(
        (result) => {
          this.alertService.success('La dernière période de paie est invalidée. La période de paie est ouverte pour la saisie', false);
          this.formService.raisereReloadCustomerEvent();
        },
        (result) => {
          this.alertService.error('Erreur. Veuillez contacter le service PAIE PUSH', false);
        }
      );
  }

  deleteCustomer(idCustomer) {
    this.subscriptions.push(
      this.customerService.deleteCustomer(idCustomer)
      .subscribe(
        (result) => {
          this.alertService.success('Dossier supprimé !', false);
          this.formService.raisereReloadCustomerEvent();
        },
        (result) => {
          this.alertService.error('Erreur', false);
        }
      ));

  }
  editCustomer(customer) {
    this.router.navigateByUrl('/update-customers', { state: {customer:customer}});
  }

  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppression d'un dossier";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.deleteCustomer(id);
      }
    });
  }

}
