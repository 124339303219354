import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AccessMode } from 'src/app/class/unit.enum';
import { EmployeeService } from 'src/app/services/employee.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from 'src/app/services/form-change.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-consultation-employee',
  templateUrl: './consultation-employee.component.html',
  styleUrls: ['./consultation-employee.component.scss']
})
export class ConsultationEmployeeComponent implements OnInit {

  employeesUpdateForm: FormGroup;
  employee: any;
  employeeSite;
  employeeCustomer;
  mode: string;
  selectedSite: string = null;
  selectedCustomer: string = null;
  selectedCivilite: string = null;
  employeeUpdate: any;
  emailInvalid: boolean;
  valeurAcorriger = [];
  numSsInvalid: boolean;
  cpInvalid: boolean;
  lstSites = [];
  lstCustomers = [];
  typeContrat = [
    {id: 1, code: 'CDI',name: 'CDI'},
    {id: 2, code: 'CDD', name: 'CDD'}
  ];
  genres = [
    {id: 1, code:'MADAME', name:'Madame'},
    {id: 2, code:'MADEMOISELLE', name:'Mademoiselle'},
    {id: 3, code:'MONSIEUR', name: 'Monsieur'}
  ];
  accessModeEnum = AccessMode;
  constructor(protected employeeService: EmployeeService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    protected formService: FormChangeService,
    private customerService: CustomerService,
    private route: Router) {

      this.employee = this.route.getCurrentNavigation().extras.state?this.route.getCurrentNavigation().extras.state.employee:null;

    }

  ngOnInit(): void {
      // this.getEmployeeCustmerInfos();

  }
  findEmployeeSite() {
      this.customerService.getSiteById(this.employee.siteId)
          .subscribe(
            (data) => {
              let tmp = data ;
              this.employeeSite = tmp;
            },
            (error) => {
              console.log(error);
            }
          );
    }

    //TODO: adapter pour supper admin
  getEmployeeCustmerInfos(): void {
  if(this.employee != null && this.authenSer.isAdmin){

    this.customerService.getCustomerById(this.employee.customerId).subscribe(
        (data) => {
          let tmp = data;
          this.employeeCustomer = tmp;
          this.findEmployeeSite();
        },
        (error) => {
          console.log(error);
          // let msg = error["error"] ? error["error"]["error"] + ": " : error;
          // this.alertService.error(msg);
        }
      );
   }

  }

  isValid(f){

  }
}
