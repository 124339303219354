import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { EmployeeService } from '../../services/employee.service';
import { HttpResponse } from '@angular/common/http';
import { FormChangeService } from '../../services/form-change.service';
import { AlertService } from '../../services/alert-service.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../../services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessMode, typeContrat } from 'src/app/class/unit.enum';

@Component({
  selector: 'app-employee',
  templateUrl: './employees-update.component.html',
   styleUrls: ['./employees-update.component.scss']
})
export class EmployeesUpdateComponent implements OnInit {

  employeesUpdateForm: FormGroup;
  employee: any;
  selectedSite: string = null;
  selectedCustomer: string = null;
  selectedCivilite: string = null;
  employeeUpdate: any;
  emailInvalid: boolean;
  valeurAcorriger = [];
  numSsInvalid: boolean;
  cpInvalid: boolean;
  lstSites = [];
  lstCustomers = [];
  typeContratEnum = typeContrat;
  keys = Object.keys;
  genres = [
    {id: 1, code:'MADAME', name:'Madame'},
    {id: 2, code:'MADEMOISELLE', name:'Mademoiselle'},
    {id: 3, code:'MONSIEUR', name: 'Monsieur'}
  ];
  accessModeEnum = AccessMode;
  constructor(protected employeeService: EmployeeService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    protected formService: FormChangeService,
    private customerService: CustomerService,
    private route: Router) {

      this.employee = this.route.getCurrentNavigation().extras.state?this.route.getCurrentNavigation().extras.state.employee:null;
    }

  ngOnInit(): void {
    debugger;
    this.findSites();
    if (this.authenSer.isAdmin) {
      this.getCustomersForSelect();
    }
    this.initForm();
    if(this.employee){
      this.updateForm(this.employee);
    }

  }
  getSitesFromBackEnd() {
    return this.employeeService.getSites(this.authenSer.getUserLoggedIn());
  }
  findSites() {
    // if (this.authenSer.isAdmin) {
      this.getSitesFromBackEnd()
          .subscribe(
            (data) => {
              let tmp = data as Array<any>;
              this.lstSites = tmp;
            },
            (error) => {
              console.log(error);
            }
          );
    // }
  }
  clientSelected(){
    this.getSitesForSelect(this.employee.customerId);
  }
  getListSite(){
    return this.lstSites;
  }

  getCustomersForSelect(): void {
    this.getCustomersFromBackEnd().subscribe(
      (data) => {
        let tmp = data as Array<any>;
        this.lstCustomers = tmp;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getCustomersFromBackEnd() {
    if(this.authenSer.isAdmin){
      return this.customerService.getCustomers();
    }
  }
  getSitesFromBackEndWithCustomerId(id) {
    return this.employeeService.getSiteCustomerId(
      id,
      this.authenSer.getUserLoggedIn()
    );
  }
  getSitesForSelect(selectedCustomerId): void {
    debugger;
    this.selectedSite= null;
    if (this.authenSer.isAdmin) {
      this.getSitesFromBackEndWithCustomerId(selectedCustomerId).subscribe(
        (dataSite) => {
          let tmp = dataSite as Array<any>;
          this.lstSites = tmp;
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.employeeService.getSites(this.authenSer.getUserLoggedIn()).subscribe(
        (dataSite) => {
          let tmp = dataSite as Array<any>;
          this.lstSites = tmp;
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  initForm() {
    this.employeesUpdateForm = this.fb.group({
      adress: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      civilite: new FormControl({value:'',disabled: this.authenSer.isUser}, [Validators.required]),
      cp: new FormControl("", [Validators.required, Validators.maxLength(5)]),
      email: ["", Validators.email],
      firstname: new FormControl({value:'',disabled: this.authenSer.isUser}, [Validators.required]),
      matricule: new FormControl({value:'',disabled: this.authenSer.isUser}, [Validators.required]),
      name: new FormControl({value:'',disabled: this.authenSer.isUser}, [Validators.required]),
      contractType: new FormControl({value:'',disabled: this.authenSer.isUser}, [Validators.required]),
      dateBirth: new FormControl({value:'',disabled: this.authenSer.isUser}, [Validators.required]),
      cityBirth: new FormControl({value:'',disabled: this.authenSer.isUser}, [Validators.required]),
      countryBirth: new FormControl({value:'',disabled: this.authenSer.isUser}, [Validators.required]),
      nationality: new FormControl({value:'',disabled: this.authenSer.isUser}, [Validators.required]),
      nbHoursMonthly: new FormControl('', [Validators.required]),
      employment: new FormControl('', [Validators.required]),
      category: new FormControl(''),
      coeff: new FormControl(''),
      salaryBrutMonthly: new FormControl('', [Validators.required]),
      dateEntrance: new FormControl({value:'',disabled: this.authenSer.isUser}, [Validators.required]),
      numSs: new FormControl('', [Validators.maxLength(15)]),
      numDpae: new FormControl(''),
      numTitreSejour: new FormControl(''),
      dateExp: new FormControl(''),
      delivrePar: new FormControl(''),
      comm: new FormControl(''),
      siteId: new FormControl(''),
      tel1: [],
    });
    let contractTypeValue=  this.employee.contractType;
    if(contractTypeValue
      && typeContrat[contractTypeValue] == typeContrat.CDD){
        this.employeesUpdateForm.addControl('endContract' ,new FormControl({value:'',disabled: this.authenSer.isUser}, [Validators.required]));
    }
    if(this.authenSer.isAdmin){
      this.employeesUpdateForm.addControl(
        'customerId', new FormControl({value:''}, [Validators.required]));
    }
  }

  updateForm(variable: any): void {

    debugger;
    this.employeesUpdateForm.patchValue({
      adress: variable.adress,
      city: variable.city,
      civilite: variable.civilite,
      cp: variable.cp,
      email: variable.email,
      firstname: variable.firstname,
      name: variable.name,
      contractType: variable.contractType,
      dateBirth: variable.dateBirth ? moment(variable.dateBirth).format('YYYY-MM-DD'):null,
      cityBirth: variable.cityBirth,
      countryBirth: variable.countryBirth,
      nationality: variable.nationality,
      nbHoursMonthly: variable.nbHoursMonthly,
      employment: variable.employment,
      category: variable.category,
      coeff: variable.coeff,
      salaryBrutMonthly: variable.salaryBrutMonthly,
      dateEntrance: variable.dateEntrance ? moment(variable.dateEntrance).format('YYYY-MM-DD'): null,
      numSs: variable.numSs,
      numDpae: variable.numDpae,
      numTitreSejour: variable.numTitreSejour,
      dateExp: variable.dateExp,
      delivrePar: variable.delivrePar,
      comm: variable.comm,
      siteId: variable.siteId,
      tel1: variable.tel1,
      matricule: variable.matricule
    });

    if(this.istypeCDD()){
      this.employeesUpdateForm.patchValue({
        endContract: variable.endContract? moment(variable.endContract).format('YYYY-MM-DD'): null,
      });
    }
    if (this.authenSer.isAdmin){
      this.employeesUpdateForm.patchValue({
        customerId: variable.customerId,
      });
    }
  }

  private createFromForm(): any {

    let entrenceDate = null;
    if(this.employeesUpdateForm.get(['dateEntrance']).value){
      entrenceDate = moment.utc(this.employeesUpdateForm.get(['dateEntrance']).value);
    }
    debugger;
    let tmp =  {
      adress: this.employeesUpdateForm.get(['adress'])!.value,
      city: this.employeesUpdateForm.get(['city'])!.value,
      civilite: this.employeesUpdateForm.get(['civilite'])!.value,
      cp: this.employeesUpdateForm.get(['cp'])!.value,
      email: this.employeesUpdateForm.get(['email'])!.value,
      firstname: this.employeesUpdateForm.get(['firstname'])!.value,
      matricule: this.matriculeValue(),
      name: this.employeesUpdateForm.get(['name'])!.value,
      contractType: this.employeesUpdateForm.get(['contractType']).value ? this.employeesUpdateForm.get(['contractType']).value : null,
      dateBirth: this.employeesUpdateForm.get(['dateBirth'])!.value,
      cityBirth: this.employeesUpdateForm.get(['cityBirth'])!.value,
      countryBirth: this.employeesUpdateForm.get(['countryBirth'])!.value,
      nationality: this.employeesUpdateForm.get(['nationality'])!.value,
      nbHoursMonthly: this.employeesUpdateForm.get(['nbHoursMonthly'])!.value,
      employment: this.employeesUpdateForm.get(['employment'])!.value,
      category: this.employeesUpdateForm.get(['category'])!.value,
      coeff: this.employeesUpdateForm.get(['coeff'])!.value,
      salaryBrutMonthly: this.employeesUpdateForm.get(['salaryBrutMonthly'])!.value,
      //dateEntrance: entrenceDate,
      dateEntrance: this.employeesUpdateForm.get(['dateEntrance'])!.value,
      numSs: this.employeesUpdateForm.get(['numSs'])!.value,
      numDpae: this.employeesUpdateForm.get(['numDpae'])!.value,
      numTitreSejour: this.employeesUpdateForm.get(['numTitreSejour'])!.value,
      dateExp:this.employeesUpdateForm.get(['dateExp'])!.value,
      delivrePar: this.employeesUpdateForm.get(['delivrePar'])!.value,
      comm: this.employeesUpdateForm.get(['comm'])!.value,
      siteId: this.employeesUpdateForm.get(['siteId'])!.value,
      tel1: this.employeesUpdateForm.get(['tel1'])!.value,
      customerId: this.employee.customerId,
      id: this.employee.id
    };
    if(this.istypeCDD()){
      tmp["endContract"] = this.employee.endContract;
    }
    return tmp;
  }

  matriculeValue(){
    if (this.authenSer.isAdmin){
      return this.employeesUpdateForm.get(["matricule"])!.value;
    } else {
      return this.employee.matricule;
    }
  }
  istypeCDD(){
    let contractTypeValue=  this.employeesUpdateForm.get(["contractType"]).value;
    return contractTypeValue
        && typeContrat[contractTypeValue] == typeContrat.CDD
  }
 typeContractChange(){
  let contractTypeValue=  this.employeesUpdateForm.get(["contractType"]).value;
    if(this.istypeCDD()) {
      this.employeesUpdateForm.addControl("endContract",new FormControl('', [Validators.required]));
    } else {
      this.employeesUpdateForm.removeControl("endContract");
      }
 }
  update() {

    this.employeeUpdate = this.createFromForm();

          this.employeeService.putEmployee(this.employeeUpdate, this.authenSer.getUserLoggedIn())
          .subscribe(
            (data) => {
              this.alertService.success('Modification effectuée.', true);
              // this.formService.raisereloadSearchRequestEvent();
              this.route.navigateByUrl('/salaries');
          },
            (error) => {
              this.alertService.warn('Erreur de modification.', true);
            }
          );
  }
  isValid(f){

  }
}
