import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'app-condition-general-vente',
  templateUrl: './condition-general-vente.component.html',
  styleUrls: ['./condition-general-vente.component.scss']
})
export class ConditionGeneralVenteComponent implements OnInit {
  askAcceptence;
  validated;
  accepted;
  autrisations;
  formG: FormGroup;
  constructor( public activeModal: NgbActiveModal, private route: ActivatedRoute,
    private router: Router, private fb: FormBuilder, private userServ: UserService    ) { }

  ngOnInit() {
    this.askAcceptence  = this.route.snapshot.params['validation'];
    this.formG = this.fb.group({
      accepted: [],
      autrisations: [],
  });
}
  validate() {

    this.userServ.acceptCvg().subscribe(
      (data)=>{
        this.validated = true;
        this.router.navigate(['/home']);
      },
      (error)=>{
        console.log(error);
      }
    );
  }
}
