import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { EmployeeService } from "../../services/employee.service";
import { HttpResponse } from "@angular/common/http";
import { FormChangeService } from "../../services/form-change.service";
import { AlertService } from "../../services/alert-service.service";
import * as moment from "moment";
import { Observable } from "rxjs";
import { AuthenticationService } from "../../services/authentication.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "../../services/customer.service";
import { Router } from "@angular/router";
import { typeContrat } from 'src/app/class/unit.enum';

@Component({
  selector: "app-employee",
  templateUrl: "./employees-create.component.html",
  styleUrls: ["./employees-create.component.scss"],
})
export class EmployeesCreateComponent implements OnInit {
  employeesCreateForm: FormGroup;

  selectedSite: string = null;
  selectedCustomer: string = null;
  selectedCivilite: string = null;
  employeeToSave: any;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  cpInvalid: boolean;
  lstSites = [];
  lstCustomers =  [];
  typeContratEnum = typeContrat;
  keys = Object.keys;
  startingDate = new Date();
  genres = [
    {id: 1, code:'MADAME', name:'Madame'},
    {id: 2, code:'MADEMOISELLE', name:'Mademoiselle'},
    {id: 3, code:'MONSIEUR', name: 'Monsieur'}
  ];
  constructor(
    protected employeeService: EmployeeService,
    protected customerService: CustomerService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private formService: FormChangeService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
    if (this.authenSer.isAdmin) {
      this.getCustomersForSelect();
    }
    this.getSitesForSelect("");
  }

  initForm() {
    this.employeesCreateForm = this.fb.group({
      adress: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      civilite: new FormControl("", [Validators.required]),
      cp: new FormControl("", [Validators.required, Validators.maxLength(5)]),
      email: ["", Validators.email],
      firstname: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      contractType: new FormControl("", [Validators.required]),
      dateBirth: new FormControl("", [Validators.required]),
      cityBirth: new FormControl("", [Validators.required]),
      countryBirth: new FormControl("", [Validators.required]),
      nationality: new FormControl("", [Validators.required]),
      nbHoursMonthly: new FormControl("", [Validators.required]),
      employment: new FormControl("", [Validators.required]),
      category: [],
      coeff: [],
      salaryBrutMonthly: new FormControl("", [Validators.required]),
      dateEntrance: new FormControl("", [Validators.required]),
      numSs: new FormControl("", [Validators.maxLength(15)]),
      numDpae: new FormControl(""),
      numTitreSejour: new FormControl(""),
      dateExp: new FormControl(""),
      delivrePar: new FormControl(""),
      comm : new FormControl(""),
     siteId: new FormControl(""),
      tel1: [],
    });
    if(this.authenSer.isAdmin){
      this.employeesCreateForm.addControl(
        'customerId', new FormControl("", [Validators.required]));
        this.employeesCreateForm.addControl(
          'matricule', new FormControl("", [Validators.required]));
    }
  }
  clientSelected(){
    let cusId = this.customerValue();
    this.getSitesForSelect(cusId);
  }
  getListSite(){
    return this.lstSites;
  }
  getSitesForSelect(selectedCustomerId): void {
    if (this.authenSer.isAdmin) {
      this.getSitesFromBackEndWithCustomerId(selectedCustomerId).subscribe(
        (dataSite) => {
          let tmp = dataSite as Array<any>;
          this.lstSites = tmp;
        },
        (error) => {
          let msg = error["error"] ? error["error"]["error"] + ": " : error;
          this.alertService.error(msg);
        }
      );
    } else {
      this.getSitesFromBackEnd().subscribe(
        (dataSite) => {
          let tmp = dataSite as Array<any>;
          this.lstSites = tmp;
        },
        (error) => {
          let msg = error["error"] ? error["error"]["error"] + ": " : error;
          this.alertService.error(msg);
        }
      );
    }
  }
  istypeCDD(){
    let contractTypeValue=  this.employeesCreateForm.get(["contractType"]).value
    return contractTypeValue
        && typeContrat[contractTypeValue] == typeContrat.CDD
  }
 typeContractChange(){
  let contractTypeValue=  this.employeesCreateForm.get(["contractType"]).value
    if(this.istypeCDD()) {
      this.employeesCreateForm.addControl("endContract",new FormControl('', [Validators.required]));
    } else {
      this.employeesCreateForm.removeControl("endContract");
      }
 }
  getCustomersForSelect(): void {
    this.getCustomersFromBackEnd().subscribe(
      (data) => {
        let tmp = data as Array<any>;
        this.lstCustomers = tmp;
      },
      (error) => {
        let msg = error["error"] ? error["error"]["error"] + ": " : error;
        this.alertService.error(msg);
      }
    );
  }

  getSitesFromBackEnd() {
    return this.employeeService.getSites(this.authenSer.getUserLoggedIn());
  }

  getSitesFromBackEndWithCustomerId(id) {
    return this.employeeService.getSiteCustomerId(
      id,
      this.authenSer.getUserLoggedIn()
    );
  }

  getCustomersFromBackEnd() {
    if(this.authenSer.isAdmin){
      return this.customerService.getCustomers();
    }
  }

  updateSelect() {
    this.getSitesForSelect(this.selectedCustomer);
  }

  private createFromForm(): any {
    let entrenceDate = null;
    if (this.employeesCreateForm.get(['dateEntrance']).value){
      entrenceDate = moment(this.employeesCreateForm.get(['dateEntrance']).value).date().toLocaleString();//("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    }
    let tmp = {
      adress: this.employeesCreateForm.get(["adress"])!.value,
      city: this.employeesCreateForm.get(["city"])!.value,
      civilite: this.employeesCreateForm.get(["civilite"])!.value,
      cp: this.employeesCreateForm.get(["cp"])!.value,
      email: this.employeesCreateForm.get(["email"])!.value,
      firstname: this.employeesCreateForm.get(["firstname"])!.value,
      matricule: this.matriculeValue(),
      name: this.employeesCreateForm.get(["name"])!.value,
      contractType: this.employeesCreateForm.get(["contractType"]).value ? this.employeesCreateForm.get(["contractType"]).value:null,
      dateBirth: this.employeesCreateForm.get(["dateBirth"])!.value,
      cityBirth: this.employeesCreateForm.get(["cityBirth"])!.value,
      countryBirth: this.employeesCreateForm.get(["countryBirth"])!.value,
      nationality: this.employeesCreateForm.get(["nationality"])!.value,
      nbHoursMonthly: this.employeesCreateForm.get(["nbHoursMonthly"])!.value,
      employment: this.employeesCreateForm.get(["employment"])!.value,
      category: this.employeesCreateForm.get(["category"])!.value,
      coeff: this.employeesCreateForm.get(["coeff"])!.value,
      salaryBrutMonthly: this.employeesCreateForm.get(["salaryBrutMonthly"])!
        .value,
      //dateEntrance: entrenceDate,
      dateEntrance: this.employeesCreateForm.get(["dateEntrance"])!.value,
      numSs: this.employeesCreateForm.get(["numSs"])!.value,
      numDpae: this.employeesCreateForm.get(['numDpae'])!.value,
      numTitreSejour: this.employeesCreateForm.get(['numTitreSejour'])!.value,
      dateExp:this.employeesCreateForm.get(['dateExp'])!.value,
      delivrePar: this.employeesCreateForm.get(['delivrePar'])!.value,
      comm: this.employeesCreateForm.get(['comm'])!.value,
      siteId: this.employeesCreateForm.get(["siteId"])!.value,
      tel1: this.employeesCreateForm.get(["tel1"])!.value,
      customerId: this.customerValue(),
    };
    if(this.istypeCDD()){
      debugger;
      tmp["endContract"] =this.employeesCreateForm.get(["endContract"])!.value;
    }
    debugger;
    return tmp;
  }
  customerValue() {
    if (this.authenSer.isAdmin) {
      return this.employeesCreateForm.get(["customerId"])!.value;
    } else {
      let tmp = this.authenSer.getUserLoggedIn().customerId;
      return tmp;
    }
  }
  matriculeValue(){
    if (this.authenSer.isAdmin){
      return this.employeesCreateForm.get(["matricule"])!.value;
    } else {
      return '';
    }
  }
  onChangeCivilite(value): void {
    this.selectedCivilite = value;
  }
  save(): void {

    this.valeurAcorriger = [];
    this.employeeToSave = this.createFromForm();

    this.employeeService
      .saveEmployee(this.employeeToSave, this.authenSer.getUserLoggedIn())
      .subscribe(
        (data) => {
          this.alertService.success("Employé Sauvegardé !", true);
          this.formService.raisereloadSearchRequestEvent();
          this.route.navigateByUrl("/salaries");
        },
        (error) => {
          this.alertService.error("Erreur dans le formulaire !", true);
        }
      );
  }
}
