import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { MessagesService } from "./services/messages.service";
import { AlertService } from "./services/alert-service.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "../app/modal/modal.component";
import { Observable, Subscription } from "rxjs";
import { AuthenticationService } from './services/authentication.service';
import { FormChangeService } from './services/form-change.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "devarchi-paie & personnel";
  messages = new Array<any>();
  subscriptions = new Array<Subscription>();
  constructor(
    public messagesSer: MessagesService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private formService: FormChangeService,
    public authenSer: AuthenticationService
  ) {

  }
  ngAfterViewInit(): void {

  }
  // ngOnDestroy(): void {

  //   this.subscriptions.forEach(sub=>{
  //     sub.unsubscribe();
  //   })
  // }
  ngOnInit() {
    if(this.authenSer.isUser){
      this.subscriptions.push(
        this.formService.userMessagesChanged.subscribe(
        (data) =>{
          if(data && data.length > 0){
            this.messages = data;
          }
        },
        (error) =>{

        }))
      }
    // if(this.authenSer.isUser){
    //   this.subscriptions.push(
    //     this.formService.userMessagesChanged.subscribe(
    //     (data) =>{
    //       if(data && data.length > 0){
    //         this.messages = data;
    //       }
    //     },
    //     (error) =>{

    //     }))
    //   }
  }

  getMessages(){

    if(this.authenSer.isUser){
      return this.messages;
    }
    return;
  }

  openMessage(msg) {
    const modalRef = this.modalService.open(ModalComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Message de l'administrateur ";
    modalRef.componentInstance.msg = msg;
    modalRef.result.then(messageId => {
      if (false != messageId) {
        this.messagesSer.acknowledgeMessage(messageId).subscribe(
          data => {
            this.messages = this.messages.filter(el => el.id != messageId);
          },
          error => {

            const msg = "erreur lors de l'aquitement du message";
            this.alertService.error(msg);
          }
        );
      }
    });
  }

}
