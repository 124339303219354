import { Component, OnInit, Input, Pipe } from "@angular/core";
import { NgbActiveModal, NgbTimeStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Submission } from "src/app/class/submission";
import { VariablesAbsenceService } from "src/app/services/variable-absence-service";
import { AuthenticationService } from 'src/app/services/authentication.service';
import * as moment from 'moment';
import { FormChangeService } from 'src/app/services/form-change.service';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { PrettyprintPipe } from 'src/app/prettyprint.pipe';
import { ModalRASComponent } from 'src/app/modal/modalRAS.component';
import { SubmissionService } from 'src/app/services/submission.service';
import { AlertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-absences-create',
  templateUrl: './absences-create.component.html',
  styleUrls: ['./absences-create.component.scss'],
})
export class AbsencesCreateComponent implements OnInit {
  JSON: JSON;
  @Input() title;
  @Input() employee: any;
  @Input() period: any;
  @Input() soldCompte: boolean;
  @Input() checkRas: boolean;
  @Input() resultDay: any = {};
  listVars: Array<any>;
  showDelete = false;
  employeeAbsForm: FormGroup;
  items = new  Array<any>();
  myForm: FormGroup;
  nbDay = new Array<any>();
  constructor(public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private authenSer: AuthenticationService,
    private absenceVarService: VariablesAbsenceService,
    private formService: FormChangeService,
    private submissionService: SubmissionService,
    private modalService: NgbModal,
    private alertServ: AlertService,
  ) {

  }
  checkendAbsence(event, index){
    //

    let form = this.dataAbsence.value;
    let end = form[index]?form[index].endAbsence: null;
    let start = form[index]?form[index].startAbsence: null;
    let startHalfDay = form[index].startAbsenceAprem != undefined ? form[index].startAbsenceAprem : false;
    let endHalfDay = form[index].endAbsenceMatin != undefined ? form[index].endAbsenceMatin : false;
    debugger;
    if(event && event['target'] && event['target'].checked == true){
      endHalfDay = true;
    }
    if(end){
      if(start){
      this.absenceVarService.getNbrJour(start, startHalfDay, end, endHalfDay).subscribe(
        data =>{

          this.nbDay[index]= data;
        },
        error =>{

        }
      );
    } else {
      this.nbDay[index]= null;
    }
  }
  }
  onKeyUpNbrJourEvent(event: KeyboardEvent, absenceIndex): void {
    let nbrJour = this.nbDay[absenceIndex];
    let form = this.dataAbsence.value;
    let start = form[absenceIndex]?form[absenceIndex].startAbsence: null;
    let startHalfDay = form[absenceIndex].startAbsenceAprem != undefined ? form[absenceIndex].startAbsenceAprem : false;
    let endHalfDay = form[absenceIndex].endAbsenceMatin != undefined ? form[absenceIndex].endAbsenceMatin : false;

    if(start && nbrJour){
        this.absenceVarService.getEndDate(start,startHalfDay, endHalfDay, this.nbDay).subscribe(
          (data) =>{
            ((this.employeeAbsForm.get("dataAbsences") as FormArray)
            .at(absenceIndex) as FormGroup)
            .controls['endAbsence']
            .setValue(moment(data).format('YYYY-MM-DD'));

          },
          (error)=>{
          }
          );

      }
 }
  checkstartAbsence(event,index){

    let form = this.dataAbsence.value;
    let start = form[index]?form[index].startAbsence:null;
    let end = form[index]?form[index].endAbsence:null;
    let startHalfDay = form[index].startAbsenceAprem != undefined ? form[index].startAbsenceAprem : false;
    let endHalfDay = form[index].endAbsenceMatin != undefined ? form[index].endAbsenceMatin : false;
    debugger;
    if(event && event['target'] && event['target'].checked == true){
      startHalfDay = true
    }
    if(start){
      if(end){
      this.absenceVarService.getNbrJour(start, startHalfDay ,end, endHalfDay).subscribe(
        data =>{

      this.nbDay[index]= data;
        },
        error =>{

        }
      );
    } else {
      this.nbDay[index]= null;
    }
  }
  }

  get dataAbsence() {
    return this.employeeAbsForm.get('dataAbsences') as FormArray;
  }
  addDataAbsence() {
    this.dataAbsence.push(this.fb.group({
          variableId: new FormControl('',[Validators.required]),
          startAbsence: new FormControl('',[Validators.required]),
          startAbsenceMatin : false,
          startAbsenceAprem : false,
          endAbsence: new FormControl('',[Validators.required]),
          endAbsenceMatin : false,
          endAbsenceAprem : false,
          nbDay: '',
          nbDayRemaining: ''
    }));
    this.nbDay.push(null);
    if (this.dataAbsence.length > 1){
      this.showDelete = true;
    }
  }

  deleteDataAbsence() {
    this.dataAbsence.controls.pop();
    this.dataAbsence.value.pop();
    this.nbDay.pop();
    if (this.dataAbsence.length > 1){
      this.showDelete = true;
    } else {
      this.showDelete = false;
    }
  }
  ngOnInit() {

    this.absenceVarService.getVariableAbsence(this.authenSer.getUserLoggedIn()).subscribe(
      data => {
        this.items = data as Array<any>;
      },
      error => {
        console.log(error);
      }
    );
    this.employeeAbsForm = this.fb.group({
      dataAbsences: this.fb.array([
        this.fb.group({
          variableId: new FormControl('',[Validators.required]),
          startAbsence: new FormControl('',[Validators.required]),
          startAbsenceMatin : false,
          startAbsenceAprem : false,
          endAbsence: new FormControl('',[Validators.required]),
          endAbsenceMatin : false,
          endAbsenceAprem : false,
          nbDay: '',
          nbDayRemaining: '' })
        ])
    });
    this.nbDay.push(null);

  }

  saisieRasPaie(event) {
    const modalRefere = this.modalService.open(ModalRASComponent, {
      backdrop: "static",
    });
    modalRefere.componentInstance.title =
      "Saisie des Variables de paie pour " + this.employee.firstname + "  " + this.employee.name;
    modalRefere.componentInstance.message = "Confirmez le RAS ?";
    modalRefere.componentInstance.withCancel = true;
    debugger;
    modalRefere.result.then((result) => {
      debugger;
      if (result != false) {
        let emptyDataPaie = {
          employeeId: this.employee.id,
          //period: this.period,
        };
        this.submissionService.postNoReportAbsence(emptyDataPaie).subscribe(
          (data: any) => {
            // this.formService.raiseReloadSaisiesRequestEvent();
            // this.chkBoxDataPayeRas[employee.id] = true;
            this.checkRas = true;
            // this.close('RAS');
          },
          (error) => {
            console.log(error);
            this.alertServ.error("Erreur lors de la Saisie RAS")
            this.checkRas = false;
            //this.close(false);
            // this.formService.raiseReloadSaisiesRequestEvent();
          }
        );
      } else {
        //this.close(false);
        this.checkRas = false;
        // this.chkBoxDataPayeRas[employee.id] = false;
      }
    });
  }
  validate() {
    if (this.soldCompte && this.checkRas) {
      this.close('RAS')
    } else {
      let formData = this.employeeAbsForm.value.dataAbsences as Array<any>;
      let data = new Array<any>();
      if(formData){
        formData.forEach(newDataAbsence => {
          let absence = {};
          absence['employeeId'] = this.employee.id;
          absence['period'] = this.period
          absence['startAbsence'] = newDataAbsence.startAbsence;
          let startHalfDay = newDataAbsence.startAbsenceAprem != undefined ? newDataAbsence.startAbsenceAprem : false;
          absence['startHalfDay'] = startHalfDay;

          absence['endAbsence'] = newDataAbsence.endAbsence;
          let endHalfDay = newDataAbsence.endAbsenceMatin != undefined ? newDataAbsence.endAbsenceMatin : false;
          absence['endHalfDay'] = endHalfDay;
          absence['variableId'] = newDataAbsence.variableId;
          data.push(absence);
        });
    }
      // if (this.soldCompte && this.checkRas){
      //   this.close('RAS')
      // } else {
        this.checkAbsences(data);
        this.close(data);
    }
    // }
  }
  close( data){
    this.activeModal.close(data);
  }
  checkAbsences(data: Array<any>) {
    data.filter(el => {
      let start = moment(el.startAbsence);
      if (el.startHalfDay) {
        start = moment(start).hours(12);
      }
      let end = moment(el.startAbsence);
      if (el.endHalfDay) {
        end = moment(end).hours(12);
      }
    });
  }
}
