import { Component, OnInit, Input, Inject, LOCALE_ID, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TimeFormatter } from '../class/time-formatter';
import * as moment from 'moment';
import { Observable, timer } from 'rxjs';
import { NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { EmployeeService } from '../services/employee.service';
import { FormChangeService } from '../services/form-change.service';
import { AuthenticationService } from '../services/authentication.service';
import { EmployeesUpdateComponent } from '../employees/employees-update/employees-update.component';
import { CustomerService } from '../services/customer.service';
import { AlertService } from '../services/alert-service.service';


@Component({
  selector: 'app-customer-min',
  templateUrl: './customers-min.component.html',
//  styleUrls: ['./customers-min.component.scss']
})
export class CustomersMinComponent implements OnInit {
  subscription: any;

  constructor(
    private authenSer: AuthenticationService,
    public router: Router, private modalService: NgbModal,
    private customerService: CustomerService,  protected alertService: AlertService,) { }
  @Input() customer: any;

  ngOnInit() {
  }
}
