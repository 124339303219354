import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { VariablesAbsenceService } from 'src/app/services/variable-absence-service';
import { IVariableAbsence, VariableAbsence } from 'src/app/class/variableAbsence';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';


@Component({
  selector: 'app-variable-absence-update',
  templateUrl: './variable-absence-update.component.html',
  styleUrls: ['./variable-absence-update.component.scss']
})
export class VariableAbsenceUpdateComponent implements OnInit {
  variableAbs: any;
  variableFile: any;
  customers: any;
  absenceUpdateForm: FormGroup;
  searchText;
  selectedType;
  submitted;
  absenceFile: any;
  selectedCustomer: any;
  isSaving = false;
  unit = [
    { id: 1, name: 'heure' },
    { id: 2, name: 'jour' },
  ];
    typeAbsence = [{ id: 1, code: 'CP', name: 'Congés Payés' },
  { id: 2, code: 'MALADIE', name: 'Arrêt maladie' },
  { id: 3, code: 'SANS_SOLDE', name: 'Congés sans solde' },
  { id: 4, code: 'NON_JUSTIF', name: 'Non justifié' },
  { id: 5, code: 'AUTRE', name: 'Autre' }
];
  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    protected alertService: AlertService,
    protected formService: FormChangeService,
    protected customerService: CustomerService,
    private authenSer: AuthenticationService,
    private variableAbsenceService: VariablesAbsenceService
  ) { }

  ngOnInit() {

    this.initForm();
    this.editForm(this.variableAbs);
  }
  initForm() {

    this.absenceUpdateForm = this.fb.group({
      customerId: [],
      codeAbsence: ['', Validators.required],
      name: ['', Validators.required],
      dateCreation: [],
      dateModification: [],
      id: [],
      unit: ['', Validators.required],
      type: ['', Validators.required],
    });
  }

  get f() { return this.absenceUpdateForm.controls; }

  close(): void {
    this.activeModal.close();
  }

  editForm(varAbsence: VariableAbsence): void {
    this.absenceUpdateForm.patchValue({
      customerId: varAbsence.customerId,
      codeAbsence: varAbsence.codeAbsence,
      name: varAbsence.name,
      dateCreation: varAbsence.dateCreation,
      dateModification: varAbsence.dateModification,
      id: varAbsence.id,
      unit: varAbsence.unit,
      type: varAbsence.type
    });

  }
  update(): void {
    this.submitted = true;

    if (!this.absenceUpdateForm.invalid) {
      this.variableFile = this.createFromForm();
        this.variableAbsenceService.putVariableAbsence(this.variableFile).subscribe(
          (data) => {
              this.onSaveSuccess();
          },
          (error) => {
              this.onSaveError();}
        );
    } else {
      return;
    }
  }

  private createFromForm(): IVariableAbsence {

    const today: string = Date.now().toString();
    return {
      ...new VariableAbsence(),
      id: this.absenceUpdateForm.get(['id'])!.value,
      codeAbsence: this.absenceUpdateForm.get(['codeAbsence'])!.value,
      name: this.absenceUpdateForm.get(['name'])!.value,
      unit: this.absenceUpdateForm.get(['unit'])!.value,
      type: this.absenceUpdateForm.get(['type'])!.value,
      dateModification: today
    };
  }

  protected onSaveSuccess(): void {
    this.submitted = false;
    this.alertService.success('Modification effectuée.', false);
    this.close();
    this.formService.raisereReloadAbsencesEvent();

  }

  protected onSaveError(): void {
    this.alertService.warn('Erreur de modification.', false);
    this.submitted = false;
  }

  trackById(index: number, item: any): any {
    return item.id;
  }

}
