import { Component, OnInit, ViewContainerRef, ViewChild, TemplateRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { take, filter } from 'rxjs/operators';
import { AlertService } from '../services/alert-service.service';
import { FormChangeService } from '../services/form-change.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TemplatePortal } from '@angular/cdk/portal';
import { fromEvent, Subscription } from 'rxjs';
import { SubmissionService } from '../services/submission.service';
import { AuthenticationService } from '../services/authentication.service';
import { MomentPipe } from '../moment-pipe.pipe';

@Component({
  selector: 'app-absences',
  templateUrl: './absences.component.html',
  styleUrls: ['./absences.component.scss']
})
export class AbsencesComponent implements OnInit {
  lstEmployee = [];
  lstTypeAbsence = [
    'Congés Payés',
    'Arrêt maladie',
    'RTT',
    'Congé sans Solde',
    'Autre'];
    selectedPeriod;
  filter: any;
  @ViewChild('absenceMenu', { static: false }) absenceMenu: TemplateRef<any>;

  overlayRef: OverlayRef | null;

  constructor(public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private alertService: AlertService,
    private formService: FormChangeService,
    public router: Router,
    private submissionService: SubmissionService,
    private authenSer: AuthenticationService,
    public momentPipe: MomentPipe,
    ) { }

  ngOnInit() {
    this.subscribeformChange();
    this.getPeriod()
  }
  protected getPeriod() {
    this.submissionService.getPeriod(this.authenSer.getUserLoggedIn()).subscribe(
      (param) => {
        if (param) {
          this.selectedPeriod = param;
        }
      },
      error => {
        console.log(error);
      }
    );

  }
  subscribeformChange() {
    this.formService.formAbsencesListChanged.subscribe(
      (param) => {
        this.lstEmployee = param;
      });
  }

  sub: Subscription;


  open({ x, y }: MouseEvent, user) {
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.absenceMenu, this.viewContainerRef, {
      $implicit: user
    }));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())

  }

  delete(user) {
    this.close();
  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }


  employeeAbsences() {

  }
  employeeInfos() { }

}
