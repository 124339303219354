import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert-service.service';
import { Router } from '@angular/router';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-send-document',
  templateUrl: './send-document.component.html',
  styleUrls: ['./send-document.component.scss']
})
export class SendDocumentComponent implements OnInit {
  model: any = {};
  returnUrl = '/home';
  fileToUpload: File;
  constructor(public authenticationService: AuthenticationService,
    private employeeService: EmployeeService,
    public alertService: AlertService,
    public router: Router) { }

  ngOnInit() {

    //if (this.authenticationService.isUserLoggedIn()){
    //this.router.navigate(['/home']);
    //}
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  send() {
    return this.employeeService.sendDoc(this.model, this.fileToUpload)
      .subscribe(
        data => {
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['sendDoc']);
          });
          let self = this;
          setTimeout(function () {
            self.alertService.success('Document envoyé !', true);
          }, 500);
        },
        error => {

          const msg = 'erreur lors de l\'envoie du formulaire';
          this.alertService.error(msg);
        });
  }
}
