import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal, NgbTimeStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Submission } from "../class/submission";
import { VariablesPayeService } from "../services/variable-paye-service";
import { AuthenticationService } from '../services/authentication.service';
import { ModalRASComponent } from './modalRAS.component';
import { SubmissionService } from '../services/submission.service';
import { AlertService } from '../services/alert-service.service';
@Component({
  selector: "app-modal2",
  templateUrl: "./modal2.component.html",
  styleUrls: ["./modal2.component.scss"]
})
export class Modal2Component implements OnInit {
  @Input() title;
  @Input() employee: any;
  @Input() selectedPeriod;
  @Input() soldCompte: boolean;
  @Input() checkRas: boolean;
  objSubmission: Submission;
  message: any;
  emptyformError;
  affichageRequisBool: boolean;
  model: any = {};
  listVars: Array<any>;
  showErrors;
  constructor(
    public activeModal: NgbActiveModal,
    private authenSer: AuthenticationService,
    private paieVarService: VariablesPayeService,
    private submissionService: SubmissionService,
    protected modalService: NgbModal,
    private alertServ: AlertService
  ) { }

  validate() {
    if(this.soldCompte && this.checkRas){
      this.activeModal.close('RAS');
    } else {
      this.affichageRequisBool = true;
      let data = {};
      data['dataPaies'] = new Array<any>();
      let errors;
      debugger;
      for(let key of Object.keys(this.model)){
        let dataPaie = {};
        let val =this.model[key];
        let varcontrol ;

        this.listVars.filter(vr => vr.id == key).map(v => {
          varcontrol = v;
        });
        if(varcontrol.mandatory && !val){
          let tmp = varcontrol;
          errors = true;
          continue;
        }
        dataPaie['variableId'] = key;
        dataPaie['value'] = this.model[key];
        dataPaie['employeeId'] = this.employee.id;
        data['dataPaies'].push(dataPaie);
        debugger;
      };

        if (errors) {
          this.showErrors = true;
        return ;
      }
      if(data['dataPaies'].length == 0 ){
        this.emptyformError = true;
        return ;
      }
      data['employeeId'] = this.employee.id;

      if (this.message) {
        data['comment'] = this.message;

      }
      this.activeModal.close(data);
    }
  }
  ngOnInit() {

    this.paieVarService.getVariablePaye().subscribe(
      data => {

        this.listVars = data as Array<any>;
        let employeeVars = this.employee.dataPaies;
        if (employeeVars) {
          employeeVars.forEach(empVar => {

            this.listVars.filter(el => {
              return el.id === empVar.id;
            }).map(el => {
              this.model[el.id] = empVar.value;
            });
          });
          this.message = '';
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  saisieRasPaie() {
    const modalRefere = this.modalService.open(ModalRASComponent, {
      backdrop: "static",
    });
    modalRefere.componentInstance.title =
      "Saisie RAS pour " + this.employee.firstname + "  " + this.employee.name;
    modalRefere.componentInstance.message = "Confirmez aucune absence ?";

    modalRefere.result.then((result) => {
      if (result != false) {
        let emptyDataAbsence = {
          employeeId: this.employee.id,
          //period: this.selectedPeriod,
        };

        this.submissionService
          .postNoReportAbsence(emptyDataAbsence)
          .subscribe(
            (data: any) => {
              // this.formService.raiseReloadSaisiesRequestEvent();
              // this.activeModal.close('RAS');
            },
            (error) => {
              // this.formService.raiseReloadSaisiesRequestEvent();
              this.alertServ.error("Erreur lors de la saisie RAS");
              console.log(error);
              // this.activeModal.close(null);
            }
          );
      } else {
        // this.chkBoxAbsenceRas[employee.id] = false;
      }
    });
  }
}
