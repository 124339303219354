
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { VariablesPayeService } from '../services/variable-paye-service';
import { IVariablePaie } from '../class/variablesPaie';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../services/alert-service.service';
import { FormChangeService } from '../services/form-change.service';
import { PayeSystem } from '../class/unit.enum';
import { TenantService } from '../services/tenant.service';
import { AuthenticationService } from '../services/authentication.service';
import { CustomerService } from '../services/customer.service';


@Component({
    selector: 'app-variable-paye-update',
    templateUrl: './variable-paye-update.component.html',
    styleUrls: ['./variable-paye.component.scss']
})
export class VariablePayeUpdateComponent implements OnInit {
    mandatory: boolean;
    systemVariable: boolean;
    variable;
    submitted = false;
    userEditForm: FormGroup;
    valeurAcorriger: string[] = [];
    variableFile: any;
    customers;
    // selectedCustomer: any;
    unit = [
        { id: 1, name: 'heure' },
        { id: 2, name: 'Km' },
        { id: 3, name: '€' },
        { id: 4, name: 'Nb' },
        { id: 5, name: 'Jour' }
    ];
    selectedUnit;
    payeSystemEnum = PayeSystem;
    typeElement = [
        { id: '000', name: '0 : Nombre d\'une rubrique' },
        { id: '001', name: '1 : base d\'une rubrique' },
        { id: '002', name: '2 : Taux salarial d\'une rubrique' },
        { id: '003', name: '3 : Montant salarial d\'une rubrique' },
        { id: '004', name: '4 : Taux patronal d\'une rubrique' },
        { id: '005', name: '5 : Montant patronal d\'une rubrique' },
        { id: '006', name: '6 : Plafond d\'une rubrique' },
        { id: '007', name: '7 : Plancher d\'une rubrique' },
        { id: '008', name: '8 : Rapoort B d\'une rubrique' },
        { id: '009', name: '9: Rapoort C d\'une rubrique' },
        { id: '011', name: '11: Assiette de calcul d\'une rubrique' },
        { id: '255', name: '255: Constante' }
    ];
  tenantPayeSystem: string;

    constructor(protected variablePayeService: VariablesPayeService,
        protected activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
        protected alertService: AlertService,
        private authenSrv: AuthenticationService,
        protected formService: FormChangeService,
        private customerSrv: CustomerService,
        private tenantServ: TenantService) { }

    ngOnInit(): void {
      this.initForm();

      this.tenantServ.getTenants().subscribe(
        (data)=>{
          this.tenantPayeSystem = data['systemPaie'];
          debugger;
          if (PayeSystem.SAGE.toString() == PayeSystem[this.tenantPayeSystem] ){
            this.userEditForm.addControl("typeElementSage",new FormControl('', [Validators.required]));
          }
          debugger;
          if(this.authenSrv.UserHasAdminPrevilege) {
            // this.userEditForm.addControl("customers",new FormControl(''));
             this.customerSrv.getCustomers().subscribe(
              (data)=>{
                this.customers = data;
                // this.userEditForm.get('customers').patchValue(this.variable['customers']);
                debugger;
                this.preselectedCustomers();
              },
              (error)=>{
                console.log(error);
              }
            )
          }
          this.updateForm(this.variable);
        },
        (error)=>{
          console.log(error);
        }
      );
    }

    public preselectedCustomers() {
      const selected = this.variable.customers.map(item => item.id);
      this.userEditForm.get('customers').patchValue(selected);
    }
    public onSelectAllCustomers() {
      const selected = this.customers.map(item => item.id);
      this.userEditForm.get('customers').patchValue(selected);
    }
    initForm() {
        this.userEditForm = this.fb.group({
            id: [],
            codePaie: [],
            name: [],
            unit: [],
            defaultValue: [],
            info: [],
            mandatory: [],
            systemVariable:[],
            date_creation: [],
            date_modification: [],
            customers: []
            // typeElementSage: []//TODO:si sage
        });
    }
    systemVarChanged() {
      let value = this.userEditForm.get(["systemVariable"]).value;
      debugger;
      if (value) {
        this.userEditForm.addControl("codePaie",new FormControl('', [Validators.required]));

      } else {
        this.userEditForm.removeControl("codePaie");
      }
    }
    obligChanged() {
      let obligValue = this.userEditForm.get(["mandatory"])!.value;
      debugger;
      if (obligValue) {
        this.userEditForm.addControl("defaultValue",new FormControl('', [Validators.required]));

      } else {
        this.userEditForm.removeControl("defaultValue");
      }
    }
    // dans le cas de l'update on récupère les données pour les afficher
    updateForm(variable: any): void {

        this.userEditForm.patchValue({
            id: variable.id,
            codePaie: variable.codePaie,
            name: variable.name,
            unit: variable.unit,
            defaultValue: variable.defaultValue,
            info: variable.info,
            mandatory: variable.mandatory,
            systemVariable: variable.systemVariable,
            typeElementSage: variable.typeElementSage, //TODO:si sage
            customers: variable.customers,

        });
    }

    close(): void {
        this.activeModal.close();

    }


    update(): void {
        this.submitted = true;
        this.variableFile = this.createFromForm();
        let custList = this.userEditForm.get(["customers"])!.value;
        debugger;
        this.subscribeToSaveResponse(this.variablePayeService.putVariablePayeCreate(this.variableFile,custList));

    }

    private createFromForm() {

        const today: string = Date.now().toString();
        let tmp = {
            id: this.userEditForm.get(['id'])!.value,
            name: this.userEditForm.get(['name'])!.value,
            unit: this.userEditForm.get(['unit'])!.value,
            info: this.userEditForm.get(['info'])!.value,
            mandatory: this.userEditForm.get(["mandatory"]).value,//this.mandatory,
            systemVariable: this.userEditForm.get(["systemVariable"]).value,
            dateModification: today,
        };
        let obligValue = this.userEditForm.get(["mandatory"]).value;
        if (obligValue) {
          tmp["defaultValue"] = this.userEditForm.get(["defaultValue"])!.value;
        } else {
          tmp["defaultValue"] = null;
        }

        let systemVarValue = this.userEditForm.get(["systemVariable"])!.value;
        if (systemVarValue) {
          tmp["codePaie"] = this.userEditForm.get(["codePaie"])!.value;
        } else {
          tmp["codePaie"] = null;
        }

        if(PayeSystem.SAGE.toString() == PayeSystem[this.tenantPayeSystem]){
          tmp['typeElementSage']= this.userEditForm.get(["typeElementSage"])!.value;
        }
        return tmp;
    }

    protected subscribeToSaveResponse(result: Observable<HttpResponse<IVariablePaie>>): void {
        result.subscribe(
            () => this.onSaveSuccess(),
            () => this.onSaveError()
        );
    }

    protected onSaveSuccess(): void {
        this.submitted = false;
        this.alertService.success('Modification effectuée.', false);
        this.close();
        // this.formService.raisereloadSearchRequestEvent();
        this.formService.reloadVariablePaieSearchRequestEvent();

    }

    protected onSaveError(): void {
        this.alertService.warn('Erreur de modification.', false);
        this.submitted = false;
    }

    trackById(index: number, item: any): any {
        return item.id;
    }
    verify(value, variable) {
        if (/^ [a - zA - Z] + $ /.test(value)) {
            return true;
        } else {
            return false;
        }
    }
}
