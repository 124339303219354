import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { Submission } from "../class/submission";
import { SubmissionService } from "../services/submission.service";
import { VariablesPayeService } from "../services/variable-paye-service";
import { AuthenticationService } from '../services/authentication.service';
import { CustomerService } from '../services/customer.service';
import { Site, ISite } from '../class/site';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertService } from '../services/alert-service.service';
import { isEmpty } from 'rxjs/operators';
import { FormChangeService } from '../services/form-change.service';
@Component({
    selector: "app-modalSite",
    templateUrl: "modalSite.component.html",
    styleUrls: ["./modal.component.scss"]
})
export class ModalSiteComponent implements OnInit {
    @Input() title = ``;
    @Input() message = ``;
    @Input() site: any;
    @Input() customers;
    customerId;
    selectedCustomer: any;
    isEmpty: boolean;
    customerEmpty: boolean;

    objSubmission: Submission;
    siteName: any;
    model: any = {};
    constructor(
        public activeModal: NgbActiveModal,
        protected alertService: AlertService,
        private authenSer: AuthenticationService,
        private customerService: CustomerService,
        private formService: FormChangeService,
    ) { }
    ngOnInit() {

    }

    private createFromForm(): ISite {
        return {
          // ...new Site(),
          customerId: this.customerId ? this.customerId : this.selectedCustomer,
          name: this.siteName,
        };
      }

    validate() {
        this.site = this.createFromForm();
        //
        if (this.site.name == null) {
            this.isEmpty = true;
        } else {
            this.isEmpty = false;
        }
        if ((this.site.customerId == null && this.authenSer.isAdmin)) {
          this.customerEmpty = true;
        } else {
          this.customerEmpty = false;
        }
        if (!this.isEmpty && !this.customerEmpty) {
          this.customerService.createSiteForCustomer(this.site, this.authenSer.getUserLoggedIn())
          .subscribe(
            (result) => {

              this.alertService.success('Site Sauvegardé !', false);
              // this.formService.raisereloadSearchRequestEvent();
              this.activeModal.close(true);
            },
            (error) => {
              console.log(error);
              // this.alertService.error('Erreur dans la création du site !', false);
              this.activeModal.close(false);
            }
          );
        }
    }

}
