import { Component, OnInit, Inject, LOCALE_ID, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { FormChangeService } from '../services/form-change.service';
import { EmployeeService } from '../services/employee.service';
import { AlertService } from '../services/alert-service.service';
import { SubmissionService } from '../services/submission.service';
import { CustomerService } from '../services/customer.service';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { VariablesPayeService } from '../services/variable-paye-service';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-min-employees',
  templateUrl: './form-min-employees.component.html',
  styleUrls: ['./form-min-employees.component.scss']
})
export class FormMinEmployeesComponent implements OnInit, OnDestroy {
  sites: {};
  selectedCustomer: string;
  customers;
  selectedSite: any[];
  subscription : Subscription;
  selectAll: boolean;
  searchText: string;
  searchTextCust: string;
  newSearched: boolean = true;
  numeroMin: number;
  numeroMax: number;
  model: NgModel;
  countAnnonces;
  @Input()
  withPeriod: boolean;
  period: any;
  selectedPeriod: string;
  periodList: any[];
  constructor(
    private alertService: AlertService,
    private employeeService: EmployeeService,
    private customerService: CustomerService,
    private authenSer: AuthenticationService,
    private formService: FormChangeService,
    public variablePaieService: VariablesPayeService,
    public router: Router) {
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  ngOnInit() {
    this.initSelects();
    this.rechercher();
    this.subscribeReloadSearchRequest();
  }
  initSelects() {
    if (this.authenSer.isAdmin) {
      this.getCustomersFromBackEnd()
        .subscribe(
          data => {
            let tmp = data as Array<any>;
            this.customers = tmp;
          }
          , error => {

            let msg = error['error'] ? error['error']['error'] + ': ' : error;
            this.alertService.error(msg);
            this.formService.raiseCustomerListEvent({});

          });

    }
    this.getSiteFromBackend()
        .subscribe(
          dataSite => {
            let tmp = dataSite as Array<any>;
            this.sites = tmp;
          }
          , error => {
            console.log(error);
            this.formService.raiseSaisiesListEvent({});
          });
  }

  subscribeReloadSearchRequest() {
    this.subscription = this.formService.formEmployeeSearchRequest.subscribe(
      (param) => {
        this.rechercher();
      });
  }

  clearForm() {
    this.rechercher();
  }

  rechercher() {
    // let filter = this.getFilter();

    this.callEmployeeBackend()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          if (this.authenSer.isAdmin) {
            if (this.selectedCustomer) {
                tmp = tmp.filter(emp => {
                  return emp.customerId === this.selectedCustomer;
                });
            }

          }
          if (this.selectedSite && this.selectedSite.length > 0){

            tmp = tmp.filter(emp =>{
              return this.selectedSite.includes(emp.siteId);
            })
          }
          tmp = this.filterUsersByText(tmp);
          this.formService.raiseEmployeeListEvent(tmp);
        }
        , error => {

          console.log(error);
          this.formService.raiseEmployeeListEvent({});

        });
  }
  onChangeText(){
    this.rechercher();
  }
  private filterUsersByText(users) {
    let filter = this.getTextFilter();
    if (filter) {
      let text = filter.split(' ');
      return users.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = item['name'].toUpperCase().indexOf(el.toUpperCase()) > -1
            || item['firstname'].toUpperCase().indexOf(el.toUpperCase()) > -1
            //|| item['login'].toUpperCase().indexOf(el.toUpperCase()) > -1;
            return tmp;
        });
        return flt;
      });
    } else {
      return users;
    }
  }

  private getTextFilter() {
    return this.searchText;
  }
  // appel du backend pour retirer la liste des employées avec en paramêtre l'utilisateur connecté. Filtre sans objet.
  callEmployeeBackend() {
    return this.employeeService.getMyEmployeesByFilter(this.authenSer.getUserLoggedIn());
  }

  private findTextInCustomers(customers, text) {
    text = text?text.split(' '):'';
    return customers.filter(function (item) {

      return text.every(function (el) {
        return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1
          || item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }
  getSiteFromBackendCustomerId(customerId: string) {
    let currentUser = this.authenSer.getUserLoggedIn();
    return this.employeeService.getSiteCustomerId(customerId, currentUser);

  }

  getSiteFromBackend() {
    let currentUser = this.authenSer.getUserLoggedIn();
    return this.employeeService.getSites(currentUser);
  }

  onChangeSite() {
    this.rechercher();
  }
  onChangeCustomer() {

    if (this.selectedCustomer) {
      this.getSiteFromBackendCustomerId(this.selectedCustomer)
        .subscribe(
          dataSite => {
            let tmp = dataSite as Array<any>;

            if (this.selectedSite && this.selectedSite.length > 0) {
              tmp = tmp.filter(el => {
                return this.selectedSite.find(cus => cus.id === el.customerId);
              });
            }
            this.sites = tmp;
            this.rechercher();
          }
          , error => {

            let msg = error['error'] ? error['error']['error'] + ': ' : error;
            this.alertService.error(msg);
            this.formService.raiseVariablePaieListEvent({});

          });
    } else {
      this.rechercher();
    }
    }

  getCustomersFromBackEnd() {
    return this.customerService.getCustomers();
  }
}
