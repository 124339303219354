import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Role } from '../class/role';
import { User } from '../class/user';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// const SERVERLOCAL = 'http://dev.devarchi.fr:8445/';
// const SERVERPROD = 'http://paie.devarchi.fr:8445/';
const apiUrl = environment.serverUrl + 'api/';


@Injectable({
    providedIn: 'root'
})
export class DatapaieService {

    getDataPaieByEmployeePeriod(employeeId: any, period: any) {

        const url = apiUrl + 'user/dataPaie/employee/' + employeeId + '/period/' + period;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');

        return this.http.get(url, { headers, withCredentials: true });
    }




    constructor(private http: HttpClient) { }

}
