import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { FormChangeService } from 'src/app/services/form-change.service';
import { VariablesAbsenceService } from 'src/app/services/variable-absence-service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-absence-create-form',
  templateUrl: './absence-create-form.component.html',
  styleUrls: ['./absence-create-form.component.scss']
})
export class AbsenceCreateFormComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private authenSer: AuthenticationService,
    private absenceVarService: VariablesAbsenceService,
    private formService: FormChangeService) {

    }

  @Input() employee;
  @Input() lstVarAbsence: Array<any>;
  employeeAbsForm: FormGroup;
  items = new  Array<any>();
  myForm: FormGroup;
  formItems: {
    selectedVarId: '',
    startAbsence: '',
    startAbsenceMatin : '',
    startAbsenceAprem : '',
    endAbsence: '',
    endAbsenceMatin : '',
    endAbsenceAprem : '',
    nbDay: '',
    nbDayRemaining: '' };

  ngOnInit() {
    this.absenceVarService.getVariableAbsence(this.authenSer.getUserLoggedIn()).subscribe(
      data => {
        this.items = data as Array<any>;
      },
      error => {
        console.log(error);
      }
    );
    this.employeeAbsForm = this.fb.group({
      dataAbsences: this.fb.array([
        this.fb.group({
          selectedVarId: '',
          startAbsence: '',
          startAbsenceMatin : '',
          startAbsenceAprem : '',
          endAbsence: '',
          endAbsenceMatin : '',
          endAbsenceAprem : '',
          nbDay: '',
          nbDayRemaining: '' })
        ])
    })
  }
  onChange(param) {
    // raise le tableau createForm vers la modal
    let list = this.employeeAbsForm.get('dataAbsences') as FormArray;
    this.formService.raiseEmployeeAbsForm(list);
  }
  get dataAbsence() {
    return this.employeeAbsForm.get('dataAbsences') as FormArray;
  }
  addDataAbsence() {
    this.dataAbsence.push(this.fb.group({ selectedVarId: '', startAbsence: '', endAbsence: '', nbDay: '', startHalfDay: '', endHalfDay: '', nbDayRemaining: '' }));
  }

  deleteDataAbsence(index) {
    this.dataAbsence.removeAt(index);
  }

}
