import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { User, IUser } from 'src/app/class/user';
import { UserService } from 'src/app/services/user-service';
import { TenantService } from 'src/app/services/tenant.service';
import { Role } from 'src/app/class/role';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.scss']
})
export class UserUpdateComponent implements OnInit {

  userUpdateForm: FormGroup;
  submitted = false;
  searchText;
  customers: {};
  customerId;
  tenantId;
  selectedCivilite: string = null;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  userForm: any;
  selectedCustomer;
  selectedTenant;
  selectedRoles = new Array();
  roles = new Array();
  tenants: Object;
  user: any;
  constructor(protected customerService: CustomerService,
    protected userService: UserService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private tenantServ: TenantService,) { }

  ngOnInit(): void {
    Object.keys(Role).forEach(rl =>{
      this.roles.push(Role[rl]);
    });
    this.initForm();
    this.updateForm(this.user);
    this.rechercher();
  }

  initForm() {
    this.userUpdateForm = this.fb.group({
      id: [],
      customerId: [],
      tenantId: [],
      firstname: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      login: ['', [Validators.required]],
    });

  }
  rechercher() {
    let filter = this.getFilter();
    // if(this.authenSer.isAdmin){
    this.getCustomersFromBackEnd(filter)
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.customers = data;
          if (tmp) {
            if (filter) {
                tmp = this.findTextInCustomers(tmp, filter);
              }
          }
          this.selectedCustomer = tmp;
        }
        , error => {
          console.log(error);
          this.formService.raiseCustomerListEvent({});
        });

    // }
    if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
          if (tmp) {
            if (filter) {
                tmp = this.findTextInCustomers(tmp, filter);
              }
          }
          this.selectedTenant = tmp;
        }
        , error => {
          console.log(error);
        });
    }
  }
  getCustomersFromBackEnd(filter) {
    return this.customerService.getCustomers();
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  get f() { return this.userUpdateForm.controls; }



  private createFromForm(): any {
    return {
      customerId: this.userUpdateForm.get(['customerId']).value,
      id: this.userUpdateForm.get(['id']).value,
      tenantId: this.userUpdateForm.get(['tenantId']).value,
      login: this.userUpdateForm.get(['login'])!.value,
      email: this.userUpdateForm.get(['email'])!.value,
      firstname: this.userUpdateForm.get(['firstname'])!.value,
      name: this.userUpdateForm.get(['name'])!.value,
      roles:this.selectedRoles,
    };
  }
  // dans le cas de l'update on récupère les données pour les afficher
  updateForm(user: any): void {

    this.userUpdateForm.patchValue({
      id: user.id,
      customerId: user.customerId,
      tenantId: user.tenantId,
      firstname: user.firstname,
      name: user.name,
      email: user.email,
      login: user.login,
      roles: user.roles,
    });
    this.customerId = user.customerId;
    this.selectedTenant = user.tenantId;
    this.tenantId = user.tenantId;

    user.roles.forEach(userRol =>{
      this.selectedRoles.push(userRol);
    })

  }


  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.userUpdateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      let currentUser = this.authenSer.getUserLoggedIn();
      this.subscribeToSaveResponse(this.userService.putUser(this.userForm, currentUser));

    }

  }

  private findTextInCustomers(customers, text) {
    text = text?text.split(' '):'';
    return customers.filter(function (item) {

      return text.every(function (el) {
        return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1
          || item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }
  resetPwd() {

    let result = this.authenSer.resetPassword(this.user.login);
    result.subscribe(
      (result) => this.onResetSuccess(result),
      (result) => this.onResetError(result)
    )
  }
  protected onResetSuccess(result): void {
    this.alertService.success('Email de changement de mot de passe envoyé. Veuillez consulter votre boîte au lettre.', true);
    this.close();
    this.formService.raisereloadSearchRequestUserEvent();
  }

  protected onResetError(result): void {
    this.alertService.error('Erreur dans le formulaire !', true);
  }
  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Utilisateur sauvegardé !', false);
    this.close();
    this.formService.raisereloadSearchRequestUserEvent();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', false);
  }
  private getFilter() {

    let filter = this.searchText ? this.searchText : null;
    return filter;
  }
  // private findTextInCustomers(customers, text) {
  //   text = text?text.split(' '):'';
  //   return customers.filter(function (item) {

  //     return text.every(function (el) {
  //       return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1
  //         || item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
  //     });
  //   });
  // }
}


