import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Submission } from '../class/submission';
import { Observable } from 'rxjs';
import { Role } from '../class/role';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
const apiUrl = environment.serverUrl + 'api/';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(private http: HttpClient, private authenSer: AuthenticationService) { }


  getTenants() {
    let subUrl = this.authenSer.getUserApiTargetUrl();
    const url = apiUrl + subUrl + '/tenants';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { withCredentials: true });
  }

  deleteTenant(id: any) {
    let subUrl = this.authenSer.getUserApiTargetUrl();
    const url = apiUrl + subUrl + '/tenants/' + id;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(url, { withCredentials: true });

}

saveTenant(tenant): Observable<any> {
  let subUrl = this.authenSer.getUserApiTargetUrl();
  const url = apiUrl + subUrl + '/tenants';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(url, tenant, { withCredentials: true });
}

putTenant(tenant): Observable<any> {
  let subUrl = this.authenSer.getUserApiTargetUrl();
  const url = apiUrl + subUrl + '/tenants';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(url, tenant, { withCredentials: true });
}
getTenantById(tenantId) {
  let subUrl = this.authenSer.getUserApiTargetUrl();
  const url = apiUrl + subUrl + '/tenants/'+tenantId;
  let headers = new HttpHeaders();
  headers = headers.append('Content-Type', 'application/json');
  return this.http.get(url, { withCredentials: true });
}
}
