import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { TemplatePortal } from "@angular/cdk/portal";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { fromEvent, Subscription } from "rxjs";
import { take, filter } from "rxjs/operators";
import { Router } from "@angular/router";
import { FormChangeService } from "../services/form-change.service";
import { AlertService } from "../services/alert-service.service";
import { VariablesPayeService } from "../services/variable-paye-service";
import { AuthenticationService } from "../services/authentication.service";
import { formatDate } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SaisiesPeriodComponent } from "./saisies-period.component";
import { SubmissionService } from "../services/submission.service";
import { EmployeeService } from "../services/employee.service";
import { MomentPipe } from "../moment-pipe.pipe";
import { Modal2Component } from "../modal/modal2.component";
import { SubmissionAbsenceService } from "../services/submission-absence.service";
import { ModalRASComponent } from "../modal/modalRAS.component";
import { SaisiesUpdateComponent } from "./saisies-update.component";
import { AbsencesUpdateComponent } from "../absences/absences-update/absences-update.component";
import { AbsencesCreateComponent } from "../absences/absences-create/absences-create.component";
import { DataAbsenceService } from "../services/dataAbsence.service";
import { Civilitee } from "../class/unit.enum";
import { DownloadService } from "../services/download.service";
import * as moment from "moment";

@Component({
  selector: "app-saisies",
  templateUrl: "./saisies.component.html",
  styleUrls: ["./saisies.component.scss"],
})
export class SaisiesComponent implements OnInit {
  lstEmployees = [];
  lstSubmission = [];
  counterDataPaie = 0;
  counterAbsences = 0;
  filter: any;
  selectedPeriod: any;
  periodNew: any[];
  period: any;
  selectedDate: string;
  comment: string;
  displayedColumns = ["Nom/Prenom", "DataAbsence", "DataPaie"];
  civiliteeEnum = Civilitee;
  chkBoxAbsenceRas = new Array<any>();
  chkBoxDataPayeRas = new Array<any>();
  @ViewChild("employeeMenu", { static: false }) employeeMenu: TemplateRef<any>;

  overlayRef: OverlayRef | null;

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private alertService: AlertService,
    private formService: FormChangeService,
    public router: Router,
    private authenSer: AuthenticationService,
    public variablePaieService: VariablesPayeService,
    private submissionService: SubmissionService,
    private modalService: NgbModal,
    private submissionAbsenceService: SubmissionAbsenceService,
    private submissionDataPaieService: SubmissionService,
    private downloadServ: DownloadService,
    public momentPipe: MomentPipe,
  ) {}
  sub: Subscription;
  hasDataAbsence(employee: any) {
    let empHasDataPaie = false;
    //
    let dataabsence = employee.dataAbsences as Array<any>;
    if (dataabsence) {
      empHasDataPaie =
        dataabsence.filter((el) => {
          //
          let tmp =
            el.period == this.selectedPeriod ;
          return tmp;
        }).length > 0;
    }
    return empHasDataPaie;
  }
  hasDataAbsenceRAS(employee: any) {

    let empHasDataPaie = false;
    let dataabsence = employee.dataAbsences as Array<any>;
    if (dataabsence) {
      empHasDataPaie =
        dataabsence.filter((el) => {
          let tmp =
            el.period == this.selectedPeriod && el.noReport ;
          return tmp;
        }).length > 0;
    }
    return empHasDataPaie;
  }
  hasDataPaie(employee: any) {
    let empHasDataPaie = false;
    let datapaie = employee.dataPaies as Array<any>;
    if (datapaie) {
      empHasDataPaie =
        datapaie.filter((el) => {
          return el.period == this.selectedPeriod;
        }).length > 0;
    }
    return empHasDataPaie;
  }
  saisieRasAbsence(event, employee) {
    const modalRefere = this.modalService.open(ModalRASComponent, {
      backdrop: "static",
    });
    modalRefere.componentInstance.title =
      "Saisie des absences pour " + employee.firstname + "  " + employee.name;
    modalRefere.componentInstance.message = "Confirmez aucune absence ?";
    modalRefere.componentInstance.withCancel = true;
    modalRefere.result.then((result) => {
      if (result != false) {
        let emptyDataAbsence = {
          employeeId: employee.id,
          //period: this.selectedPeriod,
        };

        this.submissionDataPaieService
          .postNoReportAbsence(emptyDataAbsence)
          .subscribe(
            (data: any) => {
              this.formService.raiseReloadSaisiesRequestEvent();
            },
            (error) => {
              this.formService.raiseReloadSaisiesRequestEvent();
              console.log(error);
            }
          );
      } else {
        this.chkBoxAbsenceRas[employee.id] = false;
      }
    })
    .catch((error)=>{
      console.log(error);
      this.chkBoxDataPayeRas[employee.id] = false;

    }
    );
  }
  // méthode du chekbox RAS qui envoie un dataPaie vide
  saisieRasPaie(event, employee: any) {
    const modalRefere = this.modalService.open(ModalRASComponent, {
      backdrop: "static",
    });
    modalRefere.componentInstance.title =
      "Saisie des variables de paie pour " + employee.firstname + "  " + employee.name;
    modalRefere.componentInstance.message = "Confirmez le RAS ?";
    modalRefere.componentInstance.withCancel = true;
    modalRefere.result.then((result) => {
      debugger;
      if (result != false) {
        let emptyDataPaie = {
          employeeId: employee.id,
          //period: this.selectedPeriod,
        };

        this.submissionDataPaieService.postNoReport(emptyDataPaie).subscribe(
          (data: any) => {
            this.formService.raiseReloadSaisiesRequestEvent();
            this.chkBoxDataPayeRas[employee.id] = true;
          },
          (error) => {
            console.log(error);
            this.formService.raiseReloadSaisiesRequestEvent();
          }
        );
      } else {
        this.chkBoxDataPayeRas[employee.id] = false;
      }
    })
    .catch((error)=>{
      console.log(error);
      this.chkBoxDataPayeRas[employee.id] = false;

    }
    );
  }
  createDataAbsence(employee: any) {

    const modalRef = this.modalService.open(AbsencesCreateComponent, {
      backdrop: "static",
    });

    modalRef.componentInstance.title =
      "Saisie des absences pour " + employee.firstname + "  " + employee.name+'\n';
    modalRef.componentInstance.employee = employee;
    modalRef.componentInstance.period = this.selectedPeriod;
    modalRef.result.then((result) => {
      let lstDataAbsences = result as Array<any>;

      if (result && lstDataAbsences.length > 0) {
        this.submissionAbsenceService
          .postDataAbsence(lstDataAbsences)
          .subscribe(
            (data: any) => {
              this.formService.raiseReloadSaisiesRequestEvent();
              this.alertService.success("Absence Créé");
            },
            (error) => {
              console.log(error);
            }
          );
      }
    });
  }
  updateDataAbsence(employee: any) {
    const modalRef = this.modalService.open(AbsencesUpdateComponent, {
      backdrop: "static",
    });
    modalRef.componentInstance.title =
      "Modifications des absences pour " +
      employee.firstname +
      "  " +
      employee.name;
    modalRef.componentInstance.employee = employee;
    modalRef.componentInstance.period = this.selectedPeriod;
    modalRef.componentInstance.withAddBtn = true;
    modalRef.componentInstance.showDelete = true;
    debugger;
    modalRef.result.then((result) => {
      let lstDataAbsences = result as Array<any>;
      debugger;
      if (result != true) {
      if (result instanceof Array) {
        // lstDataAbsences.forEach((abs) => {
          this.submissionAbsenceService.putDataAbsences(result).subscribe(
            (data: any) => {
              this.formService.raiseReloadSaisiesRequestEvent();
            },
            (error) => {
              console.log(error);
            }
          );
        // });
        }
      } else {
        this.formService.raiseReloadSaisiesRequestEvent();
      }
    });
  }

  updateDataPaie(employee: any) {
    const modalRef = this.modalService.open(SaisiesUpdateComponent, {
      backdrop: "static",
    });
    modalRef.componentInstance.title =
      "Modifications des variables de paie pour " +
      employee.firstname +
      "  " +
      employee.name;
    modalRef.componentInstance.employee = employee;
    modalRef.componentInstance.period = this.selectedPeriod;
    modalRef.result.then((result) => {
      debugger;
      if (result != false) {
        this.submissionDataPaieService.putDataPaie(result).subscribe(
          (data: any) => {
            this.formService.raiseReloadSaisiesRequestEvent();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
  createDataPaie(employee: any) {
    const modalRef = this.modalService.open(Modal2Component, {
      backdrop: "static",
    });
    modalRef.componentInstance.title =
      "Saisie des variables de paie pour " +
      employee.firstname +
      "  " +
      employee.name;
    modalRef.componentInstance.employee = employee;
    modalRef.result.then((result) => {
      if (result) {
        debugger;
        this.submissionDataPaieService.postDataPaie(result).subscribe(
          (data: any) => {
            this.formService.raiseReloadSaisiesRequestEvent();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  ngOnInit() {
    this.getPeriod();
    this.subscribeFormEmployeeSearch();
  }
  subscribeFormEmployeeSearch() {
    this.formService.formSaisiesListChanged.subscribe((param) => {
      this.lstEmployees = param;
    });
  }

  subscribePeriodSelected() {
    this.formService.periodSelectChanged.subscribe((selectedPeriod) => {
      this.selectedPeriod = selectedPeriod;
    });
  }

  isAllOk(): boolean {
    let cansubmitPeriod = false;
    this.counterDataPaie = 0;
    this.counterAbsences = 0;
    if (this.selectedPeriod) {
      let laPeriod = this.selectedPeriod;//formatDate(this.selectedPeriod, "MMyy", "fr-FR");
      for (let i = 0; i < this.lstEmployees.length; i++) {
        for (let j = 0; j < this.lstEmployees[i].dataPaies.length; j++) {
          if (this.lstEmployees[i].dataPaies[j].period == laPeriod) {
            this.counterDataPaie += 1;
            break;
          }
        }
        for (let k = 0; k < this.lstEmployees[i].dataAbsences.length; k++) {
          if (this.lstEmployees[i].dataAbsences[k].period == laPeriod) {
            this.counterAbsences += 1;
            break;
          }
        }
      }
      if (
        this.lstEmployees.length > 0 &&
        this.lstEmployees.length == this.counterDataPaie &&
        this.lstEmployees.length == this.counterAbsences
      ) {
        cansubmitPeriod = true;
      }
    }

    return cansubmitPeriod;
  }

  validateSubmission() {
    if(!this.isAllOk()){
      const modalRef = this.modalService.open(ModalRASComponent, {
        backdrop: "static",
      });
      let msg ='Pour valider la période de paie, vous devez compléter les absences et les variables de paie pour tous vos salariés.';
      modalRef.componentInstance.message = msg;
      modalRef.componentInstance.title = 'Valider la période';
      modalRef.componentInstance.okBtnMsg ='Ok';
      modalRef.componentInstance.withCancel = false;
    } else {
    this.submissionService
      .postSubmission(this.comment ? this.comment : "", false)
      .subscribe(
        (data) => {
          let result = data as Array<any>;
          if (result.length > 0) {
            const modalRef = this.modalService.open(ModalRASComponent, {
              backdrop: "static",
            });
            let msg =
              "<p>Le système a détecté une incohérence. Le salarié suivant n'a pas présenté son badge :</p>";
            let lst = result as Array<any>;
            for (let el of result) {
              msg += "<p>" + el["firstname"] + " " + el["name"] + " :<br>";
              msg += "le : ";
              let lstDate = "";
              for (let dt of el["noPresentDates"]) {
                if (lstDate.length > 0) {
                  lstDate += ", ";
                }
                lstDate += moment(dt).format("DD/MM/YYYY");
              }
              msg += lstDate + "</p>";
            }
            msg +=
              "<p> <strong>Voulez-vous vraiment valider votre saisie ?</strong></p>";
            modalRef.componentInstance.title =
              '<i class="fa fa-2x fa-exclamation-triangle text-warning" aria-hidden="true"></i> Incohérence sur les absences';
            modalRef.componentInstance.message = msg;
            modalRef.componentInstance.okBtnMsg ='Oui';
            modalRef.componentInstance.cancelBtnMsg = 'Non';
            modalRef.componentInstance.withCancel = true;
            modalRef.result.then((confirmation) => {
              if (confirmation) {
                this.submissionService
                  .postSubmission(this.comment ? this.comment : "", true)
                  .subscribe(
                    (result) => {
                      this.alertService.success("La période "+ this.momentPipe.formatPeriodForDisplay(this.selectedPeriod) +" est validée")
                      this.getPeriod();
                      this.comment = "";
                      this.formService.raisereloadSearchRequestEvent();
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              }
            });
          } else {
            this.getPeriod();
            this.comment = "";
            this.formService.raisereloadSearchRequestEvent();
          }
        },
        (error) => {
          console.log(error);
        }
      );

    }
  }

  protected getPeriod() {
    this.submissionService
      .getPeriod(this.authenSer.getUserLoggedIn())
      .subscribe(
        (param) => {
          if (param) {
            this.selectedPeriod = param;
          } else {
            let dateNow = new Date();
            let dateAvant = new Date();
            dateAvant.setMonth(dateNow.getMonth() - 1);
            this.periodNew = [
              {
                id: "n",
                name: "Mois en cours : " + formatDate(dateNow, "MMMM y", "fr-FR"),
                date: dateNow,
              },
              {
                id: "a",
                name:
                  "Mois précédent : " + formatDate(dateAvant, "MMMM y", "fr-FR"),
                date: dateAvant,
              },
            ];
            const modalRef = this.modalService.open(SaisiesPeriodComponent, {
              backdrop: "static", keyboard: false,
            });
            modalRef.componentInstance.periodNew = this.periodNew;
            modalRef.result.then((result) => {
              this.periodChanged(result);
            });
          }
        },
        (error) => {
          // let msg = error["error"] ? error["error"]["error"] + ": " : error;
          // this.alertService.error(msg);
          console.log(error);
        }
      );
  }
  periodChanged(date) {
    this.formService.raiserePeriodSelectChangedEvent(date ? date : null);
  }

  employeeAbsences() {}
  employeeInfos() {}
}
