import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Role } from '../class/role';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// const SERVERLOCAL = 'http://dev.devarchi.fr:8445/';
// const SERVERPROD = 'http://paie.devarchi.fr:8445/';
const apiUrl = environment.serverUrl + 'api/';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  getEmployeeById(id: any) {
    throw new Error("Method not implemented.");
  }
  sendDoc(model: any, fileData: any) {
    const formData: FormData = new FormData();
    formData.append('file', fileData, fileData.name);
    formData.append('object', model.subject);
    formData.append('desc', model.description);

    let url = apiUrl + 'user/upload/uploadFile';
    let headers = new HttpHeaders();
    return this.http.post(url, formData, { headers, withCredentials: true });
  }
  sendImportEmployee(customerId: any, siteId: any, fileData: any) {
    const formData: FormData = new FormData();
    formData.append('file', fileData, fileData.name);
    formData.append('customerId', customerId);
    if (siteId) {
      formData.append('siteId', siteId);
    }
    let url = apiUrl + 'admin/employees/import';
    let headers = new HttpHeaders();
    return this.http.post(url, formData, { headers, withCredentials: true });
  }



  deleteEmployee(id: any) {
    let subUrl;
    if(this.authenSer.isAdmin){
      subUrl = 'admin';
    } else {
      subUrl = 'user';
    }
    const url = apiUrl + subUrl + '/employees/' + id;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(url, { withCredentials: true });

  }
  soldeEmployee(soldCompte: any,fileData: any) {
    let subUrl;
    if(this.authenSer.isAdmin){
      subUrl = 'admin';
    } else {
      subUrl = 'user';
    }
    const formData: FormData = new FormData();
    if(fileData){
      formData.append('file', fileData, fileData.name);
    }
    formData.append('soldeCompte', JSON.stringify(soldCompte));

    const url = apiUrl + subUrl + '/employees/soldeCompte';
    let headers = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/json');
    return this.http.post(url, formData, { withCredentials: true });
  }

  saveEmployee(employee, currentUser: any): Observable<any> {

    // let subUrl = Role.valueOf(currentUser.role).value;
    let subUrl;
    if(this.authenSer.isAdmin){
      subUrl = 'admin';
    } else {
      subUrl = 'user';
    }
    const url = apiUrl + subUrl + '/employees';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(url, employee, { withCredentials: true });
  }

  getMyEmployeesByFilter(currentUser: any) {

    // let subUrl = Role.valueOf(currentUser.role).value;
    let subUrl;
    if(this.authenSer.isAdmin){
      subUrl = 'admin';
    } else {
      subUrl = 'user';
    }
    if (subUrl == 'admin') {
      subUrl = subUrl + '/employees'
    } else {
      subUrl = subUrl+ '/employees/withDataPaie'
    }

    const url = apiUrl + subUrl;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { withCredentials: true });
  }
  getSites(currentUser: any) {
    // let subUrl = Role.valueOf(currentUser.role).value;
    let subUrl;
    if(this.authenSer.isAdmin){
      subUrl = 'admin';
    } else {
      subUrl = 'user';
    }
    const url = apiUrl + subUrl + '/sites';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { headers, withCredentials: true });
  }

  getSiteCustomerId(customerId: string, currentUser: any) {

    // let subUrl = Role.valueOf(currentUser.role).value;
    let subUrl;
    if(this.authenSer.isAdmin){
      subUrl = 'admin';
    } else {
      subUrl = 'user';
    }
    let endSuburl = '';
    if (customerId) {
      endSuburl += '/customer/' + customerId;
    }
    const url = apiUrl + subUrl + '/sites' + endSuburl;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { headers, withCredentials: true });
  }

  putEmployee(employee, currentUser): Observable<any> {
    // let subUrl = Role.valueOf(currentUser.role).value;
    let subUrl;
    if(this.authenSer.isAdmin){
      subUrl = 'admin';
    } else {
      subUrl = 'user';
    }
    const url = apiUrl + subUrl + '/employees';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(url, employee, { withCredentials: true });
  }

  constructor(private http: HttpClient, private authenSer: AuthenticationService) { }

}
