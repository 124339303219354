import { Component, OnInit, Input, Inject, LOCALE_ID, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TimeFormatter } from '../class/time-formatter';
import * as moment from 'moment';
import { Observable, timer } from 'rxjs';
import { NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { EmployeeService } from '../services/employee.service';
import { FormChangeService } from '../services/form-change.service';
import { AuthenticationService } from '../services/authentication.service';
import { EmployeesUpdateComponent } from '../employees/employees-update/employees-update.component';


@Component({
  selector: 'app-employee-min',
  templateUrl: './employee-min.component.html',
  styleUrls: ['./employee-min.component.scss']
})
export class EmployeeMinComponent implements OnInit {
  subscription: any;

  constructor(
    private authenSer: AuthenticationService,
    public router: Router, private modalService: NgbModal,
    private employeeService: EmployeeService) { }
  @Input() employee: any;

  ngOnInit() {
  }
  deleteEmployee(idEmployee) {
    this.employeeService.deleteEmployee(idEmployee).subscribe(
      (param) => {

      }
    )
  }
  editEmployee(employee) {
    // const modalRef = this.modalService.open(ModalComponent);
    const modalRef = this.modalService.open(EmployeesUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.employee = employee;
    // modalRef.componentInstance.idPlace = id;
    // let name = this.employee.name;
    // let firstName = this.employee.firstname;

    modalRef.result.then(result => {


    });
  }
}
