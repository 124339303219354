import { Component, OnInit, Inject, LOCALE_ID, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { FormChangeService } from '../services/form-change.service';
import { EmployeeService } from '../services/employee.service';
import { AlertService } from '../services/alert-service.service';
import { SubmissionService } from '../services/submission.service';
import { CustomerService } from '../services/customer.service';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { VariablesPayeService } from '../services/variable-paye-service';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-form-min-saisies',
  templateUrl: './form-min-saisies.component.html',
  styleUrls: ['./form-min-saisies.component.scss']
})
export class FormMinSaisiesComponent implements OnInit, OnDestroy {
  sites: {};
  customers;
  selectedSite: any[];
  subscription : Subscription;
  selectAll: boolean;
  searchText: string;
  searchTextCust: string;
  numeroMin: number;
  numeroMax: number;
  model: NgModel;
  countAnnonces;
  @Input()
  withPeriod: boolean;
  period: any;
  selectedPeriod: string;
  periodList: any[];
  constructor(
    private alertService: AlertService,
    private employeeService: EmployeeService,
    private customerService: CustomerService,
    private authenSer: AuthenticationService,
    private formService: FormChangeService,
    public variablePaieService: VariablesPayeService,
    public router: Router) {
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  ngOnInit() {
    this.getSiteFromBackend()
        .subscribe(
          dataSite => {
            let tmp = dataSite as Array<any>;
            this.sites = tmp;
          }
          , error => {
            console.log(error);
            this.formService.raiseSaisiesListEvent({});
          });
          this.rechercher();
          this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.subscription = this.formService.reloadSaisiesRequest.subscribe(
      (param) => {
        this.rechercher();
      });
  }

  clearForm() {
    this.rechercher();
  }

  rechercher() {
    let filter = this.getFilter();
    this.callEmployeeBackend()
    .subscribe(
      data => {
        let tmp = data as Array<any>;
        if (tmp && tmp.length > 0) {
          if (this.selectedSite && this.selectedSite.length > 0) {
            tmp = tmp.filter( emp =>{
              return this.selectedSite.includes(emp.siteId);
            })
          }
            if (filter) {
                tmp = this.findTextInPlaces(tmp, filter);
              }
          }
         this.formService.raiseSaisiesListEvent(tmp);

      }
      , error => {

        let msg = error['error'] ? error['error']['error'] + ': ' : error;
        this.alertService.error(msg);
        this.formService.raiseSaisiesListEvent({});

      });

  }


  // appel du backend pour retirer la liste des employées avec en paramêtre l'utilisateur connecté. Filtre sans objet.
  callEmployeeBackend() {
    return this.employeeService.getMyEmployeesByFilter(this.authenSer.getUserLoggedIn());
  }

  getSiteFromBackend() {
    let currentUser = this.authenSer.getUserLoggedIn();
    return this.employeeService.getSites(currentUser);
  }

  onChange() {
    this.rechercher();
  }

  private findTextInPlaces(employees, text) {
    text = text?text.split(' '):'';
    if(text){
      return employees.filter(function (item) {
        return text.every(function (el) {
          return item['name'].toUpperCase().indexOf(el.toUpperCase()) > -1
            || item['firstname'].toUpperCase().indexOf(el.toUpperCase()) > -1;
        });
      });
  }
  }
  private getFilter() {
    let filter = this.searchText ? this.searchText : null;
    return filter;
  }

}
