import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { FormChangeService } from '../services/form-change.service';
import { CustomerService } from '../services/customer.service';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { VariablesAbsenceService } from '../services/variable-absence-service';


@Component({
  selector: 'app-form-min-variable-absence',
  templateUrl: './form-min-variable-absence.component.html',
  styleUrls: ['./form-min-variable-absence.component.scss']
})
export class FormMinVariableAbsenceComponent implements OnInit {
  customerName;
  selectedCustomer: any[];
  customers;
  selectedSite;
  searchText: string;
  model: NgModel;

  constructor(
    private alertService: AlertService,
    private referencielService: CustomerService,
    private variablesAbsenceService: VariablesAbsenceService,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.rechercher();
    // this.subscribeReloadSearchRequest();
  }
  // subscribeReloadSearchRequest() {
  //   this.formService.reloadSearchRequest.subscribe(
  //     (param) => {
  //       this.rechercher();
  //     });
  // }
  clearForm() {
    this.rechercher();
  }

  // onChange() {
  //   this.rechercher();
  // }
  rechercher() {

    let filter = this.getFilter();

    // this.getCustomersFromBackEnd(filter)
    //   .subscribe(
    //     data => {

    //       let tmp = data as Array<any>;
    //       this.customers = data;
    //       if (tmp) {
    //         if (filter) {
    //           if (filter['searchText']) {

    //             tmp = this.findTextInCustomers(tmp, filter['searchText']);
    //           }
    //         }
    //       }
    //       this.selectedCustomer = tmp;

    //       //pour afficher le nom du customer en haut de la liste.
    //       if (this.selectedCustomer && this.selectedCustomer.length == 1) {

    //         this.customerName = this.selectedCustomer[0]['companyName'];
    //       }

    //     }
    //     , error => {

    //       let msg = error['error'] ? error['error']['error'] + ': ' : error;
    //       this.alertService.error(msg);
    //       this.formService.raiseCustomerListEvent({});

    //     });
    this.getVarAbsenceFromBackend()
      .subscribe(
        data => {

          let tmp = data as Array<any>;
          // if (this.selectedCustomer && this.selectedCustomer.length == 1) {
          //   if (this.selectedCustomer && this.selectedCustomer.length > 0) {
          //     tmp = tmp.filter(el => {
          //       return this.selectedCustomer.find(cus => cus.id === el.customerId);
          //     });

          //   }
          this.formService.raiseVariablePaieListEvent(tmp);
          // }
        }
        , error => {

          console.log(error);
          this.formService.raiseVariablePaieListEvent({});

        });
  }
  // getCustomersFromBackEnd(filter) {
  //   return this.referencielService.getCustomer(filter);
  // }
  getVarAbsenceFromBackend() {
    return this.variablesAbsenceService.getVariableAbsence(this.authenSer.getUserLoggedIn());
  }
  // private findTextInCustomers(customers, text) {
  //   text = text?text.split(' '):'';
  //   return customers.filter(function (item) {

  //     return text.every(function (el) {
  //       return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1
  //         || item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
  //     });
  //   });
  // }

  private getFilter() {

    let filter = this.searchText ? this.searchText : null;
    return filter;
  }

}

