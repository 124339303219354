import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { Submission } from "../class/submission";
import { SubmissionService } from "../services/submission.service";
import { VariablesPayeService } from "../services/variable-paye-service";
import { AuthenticationService } from '../services/authentication.service';
@Component({
    selector: "app-modalRAS",
    templateUrl: "modalRAS.component.html",
    styleUrls: ["./modal.component.scss"]
})
export class ModalRASComponent implements OnInit {
    @Input() title = ``;
    @Input() message = ``;
    @Input() okBtnMsg;
    @Input() cancelBtnMsg;
    @Input() employee: any;
    objSubmission: Submission;


    model: any = {};
    listVars: Array<any>;
  withCancel = false;
    constructor(
        public activeModal: NgbActiveModal,
        private authenSer: AuthenticationService,
        private paieVarService: VariablesPayeService
    ) { }

    validate() {

        this.activeModal.close(true);
    }
    ngOnInit() {
    }
}
