import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FormChangeService } from '../../services/form-change.service';
import { AlertService } from '../../services/alert-service.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../../services/customer.service';
import { Router } from '@angular/router';
import { TenantService } from 'src/app/services/tenant.service';
import { VariablesPayeService } from 'src/app/services/variable-paye-service';
@Component({
  selector: 'app-customers-create',
  templateUrl: './customers-create.component.html',
  styleUrls: ['./customers-create.component.scss']
})
export class CustomersCreateComponent implements OnInit {


  customerCreateForm: FormGroup;
  submitted = false;
  sites: {};
  customers: {};
  selectedSite: string = null;
  selectedCustomer: string = null;
  selectedCivilite: string = null;
  customerToSave: any;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  customerForm: any;
  connectionTestOk;
  testOngoing = false;
  testStated = false;
  testBtnDisable= false;
  calculeModes = [
    { id: 1, code: "OUVRE", name: "Jours ouvrés" },
    { id: 2, code: "OUVRABLE", name: "Jours ouvrables" }
  ];
  adminsTenant;
  connectionRdrTestOk;
  testRdrDisable = false;
  testRdrStated = false;
  testRdrOngoing = false;
  variablesPaie;
  constructor(protected customerService: CustomerService,
    private fb: FormBuilder,
    private route: Router,
    protected alertService: AlertService,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private tenantServ: TenantService,
    private variableService: VariablesPayeService,
    ) { }

  ngOnInit(): void {
    this.initForm();
    if(this.authenSer.isAdmin){
      this.tenantServ.getTenants().subscribe(
        (data)=>{
          this.adminsTenant = data;
        },
        (error)=>{
          console.log(error);
        }
      )
      this.variableService.getVariablePaye().subscribe(
        (data) =>{
          this.variablesPaie = data;
          debugger;
        },
        (error)=>{
          console.log(error);
        }
      );
    }
    this.onBddNameChange();
    this.onReaderIdChange();
  }
  checkbddConnection(){
    this.testStated = true;
    this.testOngoing = true;

    let bddName= this.customerCreateForm.get(["systemPaieBddName"])!.value;
    this.customerService.checkdbbConnection(bddName).subscribe(
      data =>{
        if(data){
          this.connectionTestOk = true;
        } else {
          this.connectionTestOk = false;
        }
        this.testOngoing = false;
      },
      error =>{
        this.connectionTestOk = false;
        this.testOngoing = false;
      }
    );
  }
  onBddNameChange(){

    let val =this.customerCreateForm.get(["systemPaieBddName"])!.value;

    if(val != null){
      this.testBtnDisable = false;
    } else {
      this.testBtnDisable = true;
    }
    this.testStated = false;
    this.testOngoing = false;

  }
  onReaderIdChange(){

    let val =this.customerCreateForm.get(["readerId"])!.value;

    if(val != null){
      this.testRdrDisable = false;
    } else {
      this.testRdrDisable = true;
    }
    this.testRdrStated = false;
    this.testRdrOngoing = false;

  }
  checkRdConnection(){//TODO: endpoint à adapter
    this.testRdrStated = true;
    this.testRdrOngoing = true;
    let bddName= this.customerCreateForm.get(["systemPaieBddName"])!.value;
    this.customerService.checkdbbConnection(bddName).subscribe(
      data =>{
        if(data){
          this.connectionRdrTestOk = true;
        } else {
          this.connectionRdrTestOk = false;
        }

        this.testOngoing = false;
      },
      error =>{
        this.connectionRdrTestOk = false;
        this.testOngoing = false;
      }
    );
  }
  istestRdrDisable(){
    return this.testRdrDisable;
  }
  initForm() {
    this.customerCreateForm = this.fb.group({
      activity: [],
      adress: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      companyName: new FormControl("", [Validators.required]),
      workPublicHoliday: [],
      calculModeHoliday: [],
      cp: new FormControl("", [Validators.required, Validators.maxLength(5)]),
      email: new FormControl("", [Validators.required,Validators.email]),
      siret: new FormControl("", [Validators.required, Validators.maxLength(14)]),
      systemPaieBddName: [],
      tel1: [],
      tel2: [],
      contactName: [],
      contactPosition: [],
      readerId: [],
      absenceControl: [],
      tenantId: [],
      variablePaies: [],
    })
      ;
  }
  // convenience getter for easy access to form fields
  get f() { return this.customerCreateForm.controls; }



  private createFromForm(): any {
    let tmp = {
      activity: this.customerCreateForm.get(['activity'])!.value,
      adress: this.customerCreateForm.get(['adress'])!.value,
      city: this.customerCreateForm.get(['city'])!.value,
      companyName: this.customerCreateForm.get(['companyName'])!.value,
      workPublicHoliday: this.customerCreateForm.get(["workPublicHoliday"])!
        .value,
      calculModeHoliday: this.customerCreateForm.get(["calculModeHoliday"])!
        .value,
      cp: this.customerCreateForm.get(['cp'])!.value,
      email: this.customerCreateForm.get(['email'])!.value,
      siret: this.customerCreateForm.get(['siret'])!.value,
      systemPaieBddName: this.customerCreateForm.get(['systemPaieBddName'])!.value,
      tel1: this.customerCreateForm.get(['tel1'])!.value,
      tel2: this.customerCreateForm.get(['tel2'])!.value,
      contactName: this.customerCreateForm.get(['contactName'])!.value,
      contactPosition: this.customerCreateForm.get(['contactPosition'])!.value,
      readerId: this.customerCreateForm.get(['readerId'])!.value,
      absenceControl: this.customerCreateForm.get(['absenceControl'])!.value,
      tenantId: this.getTenantId(),

    };

    return tmp;
  }

  getTenantId(){
    if(this.authenSer.isAdmin){
      return this.adminsTenant.id;
    }
  }
  save(): void {
      this.customerForm = this.createFromForm();
      let variablePaies = this.customerCreateForm.get(['variablePaies'])!.value;

        this.customerService.saveCustomer(this.customerForm,variablePaies)
        .subscribe(
          (data) => {
            this.alertService.success("Dossier Sauvegardé !", true);
            this.route.navigateByUrl("/customers");
          },
          (error) => {
            this.alertService.error("Erreur dans le formulaire !", true);
          }
        );

    }
}
