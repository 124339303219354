import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerService } from './customer.service';
import { Role } from '../class/role';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

type EntityResponseType = HttpResponse<any>;
type EntityArrayResponseType = HttpResponse<any>;

const apiUrl = environment.serverUrl + 'api/';
@Injectable({
    providedIn: 'root'
})

export class VariablesPayeService {

    delete(variablePaye): Observable<any> {
        const url = apiUrl + 'admin/variablePaie';

        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.delete(url + '/' + variablePaye, { withCredentials: true });
        // url + '?variablePaieId=' + variablePaye,
    }

    getVariablePaye() {
        let subUrl;
        if(this.authenSer.isAdmin){
          subUrl = 'admin';
        } else {
          subUrl = 'user';
        }
        const url = apiUrl + subUrl + '/variablePaie';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }
    getVariablePayeAsaisir() {
        const url = apiUrl + 'user/dataPaie';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }
    postVariablePayeCreate(variable, customerId: any): Observable<any> {
      let subUrl='';
      if(customerId && customerId.length >0){
           subUrl = '?customerIds=' + customerId[0];

        for (let i = 1; i < customerId.length; i++) {
            subUrl += '&customerIds=' + customerId[i];
        }
      }

        const url = apiUrl + 'admin/variablePaie' + subUrl;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(url, variable, { withCredentials: true });
    }
    putVariablePayeCreate(variable,customerList): Observable<any> {
      let subUrl='';
      if(customerList && customerList.length >0){
       subUrl = '?customerIds=' + customerList[0];
        for (let i = 1; i < customerList.length; i++) {
            subUrl += '&customerIds=' + customerList[i];
        }
      }
        const url = apiUrl + 'admin/variablePaie'+ subUrl;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        debugger;
        return this.http.put(url, variable, { withCredentials: true });
    }

    constructor(private http: HttpClient,
        private customerService: CustomerService, private authenSer: AuthenticationService) { }

}

