import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sepa-form',
  templateUrl: './sepa-form.component.html',
  styleUrls: ['./sepa-form.component.scss']
})
export class SepaFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
