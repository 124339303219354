import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { TemplatePortal } from "@angular/cdk/portal";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { fromEvent, Subscription } from "rxjs";
import { take, filter } from "rxjs/operators";
import { Router } from "@angular/router";
import { FormChangeService } from "../services/form-change.service";
import { AlertService } from "../services/alert-service.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "../services/customer.service";
import { AuthenticationService } from "../services/authentication.service";
import { ModalSiteComponent } from "../modal/modalSite.component";
import { SitesUpdateComponent } from "./sites-update/sites-update.component";
import { ModalRASComponent } from "../modal/modalRAS.component";

@Component({
  selector: "app-sites",
  templateUrl: "./sites.component.html",
  styleUrls: ["./sites.component.scss"],
})
export class SitesComponent implements OnInit {
  lstSites = [];
  selectedCustomer: string;
  customers;
  filter: any;
  customerId: any;
  columnsHeaderToDisplay = ["Nom du site"];
  overlayRef: OverlayRef | null;

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private alertService: AlertService,
    private customerService: CustomerService,
    public authenSer: AuthenticationService,
    private formService: FormChangeService,
    public router: Router,
    protected modalService: NgbModal
  ) {

  }
  sub: Subscription;

  ngOnInit() {
    if (this.authenSer.isAdmin){
      this.columnsHeaderToDisplay.push("Nom de l'entreprise");
    }
    this.columnsHeaderToDisplay.push('Actions');
      this.selectedCustomer = '';
      this.findSites();
  }

  subscribeformChange() {
    this.formService.formCustomerSearchChanged.subscribe((param) => {
      this.customers = param;
    });
  }

  getCustomersFromBackEnd() {
    return this.customerService.getCustomers();
  }

  findSites() {
    if (this.authenSer.isAdmin) {
        this.findSiteAdmin()
          .subscribe(
            (data) => {
              let tmp = data as Array<any>;
              this.lstSites = tmp;
              this.getCustomersFromBackEnd().subscribe((data) => {
                let tmp = data as Array<any>;
                this.customers = tmp;
                if(this.lstSites){
                  this.lstSites.forEach((el) => {
                  this.customers
                    .filter((cus) => {
                      return el.customerId == cus.id;
                    })
                    .map((cus) => {
                      el.companyName = cus.companyName;
                    });
                });
              }
              });
            },
            (error) => {
              console.log(error);
            }
          );
    } else if (this.authenSer.isUser) {
      this.customerService.getSitesByCustomerIdUser().subscribe(
        (data) => {
          let tmp = data as Array<any>;
          this.lstSites = tmp;
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  findSiteAdmin(){
    if (!this.selectedCustomer || this.selectedCustomer.length == 0){
      return this.customerService.getAllSites();
    } else {
      return  this.customerService.getSitesByCustomerIdAdmin(this.selectedCustomer);
    }
  }
  openDeleteConfirm(id) {
    const modalRef = this.modalService.open(ModalRASComponent, {
      backdrop: "static",
    });
    modalRef.componentInstance.title = "Suppression d'un site";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then((result) => {
      if (result == true) {
        this.deleteSite(id);
      }
    });
  }
  deleteSite(sitId) {
    this.customerService.deleteSite(sitId,this.authenSer.getUserLoggedIn()).subscribe(
      data =>{

        this.alertService.success('Site supprimé', false);
        this.findSites();
      },
      error =>{
        this.alertService.error('Erreur lors de la suppression du site', false);
      }
    );
  }
  selectChange() {
    this.findSites();
  }

  createSite() {
    const modalRef = this.modalService.open(ModalSiteComponent, {
      backdrop: "static",
    });
    modalRef.componentInstance.title = "Créer un site";
    modalRef.componentInstance.message = "";

    if (this.authenSer.isUser) {
      modalRef.componentInstance.customerId = this.customerId;

    } else {
      modalRef.componentInstance.customers = this.customers;
    }
    modalRef.result.then((result) => {
      if (result) {
          this.findSites();
      }
    });
  }

  updateSite(site: any) {
    const modalRef = this.modalService.open(SitesUpdateComponent, {
      backdrop: "static",
    });
    modalRef.componentInstance.title = "Mettre à jour un site";
    modalRef.componentInstance.siteName = site.name;
    modalRef.componentInstance.customerId = site.customerId;
    modalRef.componentInstance.id = site.id;

    modalRef.result.then((result) => {
      if (result) {
          this.findSites();
      }
    });
  }
}
