import { unitType } from './unit.enum';

export interface IVariableAbsence {
    customerId?: string;
    codeAbsence?: string;
    name?: string
    dateCreation?: string;
    dateModification?: string;
    id?: string;
    unit?: unitType;
    type?: any;
    value?: string;
    startAbsence?: string;
    endAbsence?: string;
    period?: string;
}
export class VariableAbsence {
    customerId?: string;
    codeAbsence?: string;
    name?: string;
    dateCreation?: string;
    dateModification?: string;
    id?: string;
    unit?: unitType;
    type?: any;
    value?: string;
    startAbsence?: string;
    endAbsence?: string;
    period?: string;
}
