import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmployeesComponent } from './employees/employees.component';
import { EmployeesCreateComponent } from './employees/employees-create/employees-create.component';
import { EmployeesUpdateComponent } from './employees/employees-update/employees-update.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { MaterialModule } from './material/material.module';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NotifierModule } from 'angular-notifier';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { EmployeeMinComponent } from './employee-min/employee-min.component';
import { FormMinEmployeesComponent } from './form-min-employees/form-min-employees.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import 'moment/locale/fr';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { ModalComponent } from './modal/modal.component';
import { Modal2Component } from './modal/modal2.component';
import { ModalRASComponent } from './modal/modalRAS.component';
import { AuthGuard } from './Guard/AuthGuard';
import { AlertService } from './services/alert-service.service';
import { AuthenticationService } from './services/authentication.service';
import { AlertComponent } from './alert/alert.component';
import { ShContextMenuModule } from 'ng2-right-click-menu'
import { AbsencesComponent } from './absences/absences.component';
import { FormMinAbsencesComponent } from './form-min-absences/form-min-absences.component';
import { AbsenceMinComponent } from './absence-min/absence-min.component';
import { SaisiesComponent } from './saisies/saisies.component';
import { SaisiesUpdateComponent } from './saisies/saisies-update.component';
import { VariablePayeComponent } from './variable-paye/variable-paye.component';
import { VariablePayeUpdateComponent } from './variable-paye/variable-paye-update.component';
import { VariablePayeCreateComponent } from './variable-paye/variable-paye-create.component';
import { SendDocumentComponent } from './send-document/send-document.component';
import { FormMinVariablePaieComponent } from './form-min-variable-paie/form-min-variable-paie.component';
import { VariableMinComponent } from './variable-min/variable-min.component';
import { SaisieMessageComponent } from './saisie-message/saisie-message.component';
import { MomentPipe } from './moment-pipe.pipe';
import { SaisiesPeriodComponent } from './saisies/saisies-period.component';
import { CustomersComponent } from './customer/customers.component';
import { CustomersMinComponent } from './customer-min/customers-min.component';
import { FormMinCustomersComponent } from './form-min-customer/form-min-customers.component';
import { CustomersUpdateComponent } from './customer/customers-update.component';
import { SitesComponent } from './sites/sites.component';
import { ModalSiteComponent } from './modal/modalSite.component';
import { EmployeesImportComponent } from './employees/employees-import/employees-import.component';
import { AbsencesCreateComponent } from './absences/absences-create/absences-create.component';
import { UserComponent } from './user/user.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { UserUpdateComponent } from './user/user-update/user-update.component';
import { VariableAbsenceComponent } from './variable-absence/variable-absence.component';
import { FormMinVariableAbsenceComponent } from './form-min-variable-absence/form-min-variable-absence.component';
import { VariableAbsenceCreateComponent } from './variable-absence/variable-absence-create/variable-absence-create.component';
import { VariableAbsenceUpdateComponent } from './variable-absence/variable-absence-update/variable-absence-update.component';
import { CustomersCreateComponent } from './customer/customers-create/customers-create.component';
import { SitesUpdateComponent } from './sites/sites-update/sites-update.component';
import { AbsencesUpdateComponent } from './absences/absences-update/absences-update.component';
import { FormMinUserComponent } from './form-min-user/form-min-user.component';
import { AbsenceCreateFormComponent } from './absences/absences-create/absence-create-form/absence-create-form.component';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { RessetLoginComponent } from './resset-login/resset-login.component';
import { FormMinSaisiesComponent } from './form-min-saisies/form-min-saisies.component';
import { PrettyprintPipe } from './prettyprint.pipe';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { ContactComponent } from './contact/contact.component';
import { AddDataPaiesComponent } from './add-data-paies/add-data-paies.component';
import { FormMinAddDataPaiesComponent } from './form-min-add-data-paies/form-min-add-data-paies.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { ProspectCreateComponent } from './prospects/prospect-create/prospect-create.component';
import { ProspectUpdateComponent } from './prospects/prospect-update/prospect-update.component';
import { FormMinProspectComponent } from './prospects/form-min-prospect/form-min-prospect.component';
import { MonProfileComponent } from './mon-profile/mon-profile.component';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { AddCardModalComponent } from './credit-card-form/add-card-modal/add-card-modal.component';
import { ConsultationEmployeeComponent } from './employees/consultation-employee/consultation-employee.component';
import { CreateTenantComponent } from './tenants/create-tenant/create-tenant.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ParametersComponent } from './services/parameters/parameters.component';
import { TenantsComponent } from './tenants/tenants.component';
import { UpdateTentantComponent } from './tenants/update-tentant/update-tentant.component';
import { FormMinTenantComponent } from './tenants/form-min-tenant/form-min-tenant.component';
import { ConditionGeneralVenteComponent } from './tenants/condition-general-vente/condition-general-vente.component';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { RangesFooter } from './prospects/form-min-prospect/ranges-footer/ranges-footer.component';
import { EmployeeDeletionComponent } from './employees/employee-deletion/employee-deletion.component';
import { CustomersImportComponent } from './customer/customers-import/customers-import.component';
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { SepaFormComponent } from './sepa-form/sepa-form.component';
import { AddSepaModalComponent } from './credit-card-form/add-sepa-modal/add-sepa-modal.component';
@NgModule({
  declarations: [
    AppComponent,
    EmployeesComponent,
    NavBarComponent,
    LoginComponent,
    HomeComponent,
    EmployeeMinComponent,
    FormMinEmployeesComponent,
    FooterComponent,
    ModalComponent,
    Modal2Component,
    ModalRASComponent,
    ModalSiteComponent,
    AlertComponent,
    AbsencesComponent,
    EmployeesCreateComponent,
    EmployeesUpdateComponent,
    EmployeesImportComponent,
    FormMinAbsencesComponent,
    AbsenceMinComponent,
    SaisiesComponent,
    SaisiesUpdateComponent,
    SaisiesPeriodComponent,
    VariablePayeComponent,
    VariablePayeUpdateComponent,
    VariablePayeCreateComponent,
    SendDocumentComponent,
    FormMinVariablePaieComponent,
    VariableMinComponent,
    SaisieMessageComponent,
    MomentPipe,
    CustomersComponent,
    CustomersMinComponent,
    FormMinCustomersComponent,
    CustomersCreateComponent,
    CustomersUpdateComponent,
    SitesComponent,
    SitesUpdateComponent,
    EmployeesImportComponent,
    AbsencesCreateComponent,
    UserComponent,
    UserCreateComponent,
    UserUpdateComponent,
    VariableAbsenceComponent,
    FormMinVariableAbsenceComponent,
    VariableAbsenceCreateComponent,
    VariableAbsenceUpdateComponent,
    AbsencesUpdateComponent,
    FormMinUserComponent,
    AbsenceCreateFormComponent,
    RessetLoginComponent,
    FormMinSaisiesComponent,
    PrettyprintPipe,
    ChangePassComponent,
    ContactComponent,
    AddDataPaiesComponent,
    FormMinAddDataPaiesComponent,
    ProspectsComponent,
    ProspectCreateComponent,
    ProspectUpdateComponent,
    FormMinProspectComponent,
    MonProfileComponent,
    CreditCardFormComponent,
    AddCardModalComponent,
    ConsultationEmployeeComponent,
    CreateTenantComponent,
    CreateUserComponent,
    ParametersComponent,
    TenantsComponent,
    UpdateTentantComponent,
    FormMinTenantComponent,
    ConditionGeneralVenteComponent,
    RangesFooter,
    EmployeeDeletionComponent,
    CustomersImportComponent,
    SepaFormComponent,
    AddSepaModalComponent,
  ],
  imports: [
    MaterialModule,
    AppRoutingModule,
    BrowserModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NotifierModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    NgbModule,
    ShContextMenuModule,
    ChartsModule,
    CommonModule,
    SatDatepickerModule, SatNativeDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [
    NgbActiveModal,
    AuthGuard,
    AlertService,
    AuthenticationService,
    MomentPipe, PrettyprintPipe,SatDatepickerModule, SatNativeDateModule,
    {
      provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptorService,
        multi: true
    },
    [{ provide: LOCALE_ID, useValue: 'fr-FR' },
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'}],
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalComponent,
    Modal2Component,
    ModalRASComponent,EmployeeDeletionComponent,
    RangesFooter,
    AddSepaModalComponent
  ]
})
export class AppModule { }
