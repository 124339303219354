import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Submission } from '../class/submission';
import { Observable } from 'rxjs';
import { Role } from '../class/role';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

// const SERVERLOCAL = 'http://dev.devarchi.fr:8445/';
// const SERVERPROD = 'http://paie.devarchi.fr:8445/';
const SERVER = environment.serverUrl + 'api/';

@Injectable({
    providedIn: 'root'
})


export class SubmissionService {


    postSubmission(commentGlobal, bypassBadgeCheck) {
        let url;
            let subUrl = '?comment=' + commentGlobal+'&bypassAbsenceControl='+bypassBadgeCheck;
            url = SERVER + 'user/submissions' + subUrl;

        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(url, null, { headers, withCredentials: true });
    }

    getMySubmissionByFilter(filter: { searchText: string; }) {

        const url = SERVER + 'user/submissions';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }

    postNoReport(data) {

        const url = SERVER + 'user/dataPaie/noReport?employeeId=' + data.employeeId //+ '&period=' + data.period;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(url, null, { headers, withCredentials: true });
    }



    postDataPaie(data: any) {

        const url = SERVER + 'user/dataPaie';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');

        return this.http.post(url, data, { headers, withCredentials: true });
    }
    postMultiDataPaie(dataArray: any) {

      const url = SERVER + 'user/dataPaie/bulk';
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');

      return this.http.post(url, dataArray, { headers, withCredentials: true });
  }
    putDataPaie(data: any) {

        const url = SERVER + 'user/dataPaie';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(url, data, { headers, withCredentials: true });
    }

    postNoReportAbsence(data) {

        const url = SERVER + 'user/dataAbsence/noReport?employeeId=' + data.employeeId //+ '&period=' + data.period;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(url, null, { headers, withCredentials: true });
    }


    postDataAbsence(data: any) {

        const url = SERVER + 'user/dataAbsence';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');

        return this.http.post(url, data, { headers, withCredentials: true });
    }
    putDataAbsence(data: any) {

        const url = SERVER + 'user/dataAbsence';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(url, data, { headers, withCredentials: true });
    }





    getMySubmissionVariable(customerId) {
        const url = SERVER + 'user/submissions/customer/' + customerId;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }

    getPeriod(currentUser: any): Observable<any> {
        // FIXME: Backend endPoint Manquant  pour récuperer la period sur la quelle on va saisir!
        // let subUrl = Role.valueOf(currentUser.role).value;
        let subUrl;
        if(this.authenSer.isAdmin){
          subUrl = 'admin';
        } else {
          subUrl = 'user';
        }
        const url = SERVER + subUrl + '/periods';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'text/plain');
        return this.http.get(url, {headers,responseType:'text', withCredentials: true });
        //     return new Observable<null>();
    }

    postPeriod(period) {
        const url = SERVER + 'user/periods?period=' + period;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(url, null, { withCredentials: true });
    }

    constructor(private http: HttpClient, private authenSer: AuthenticationService) { }



}

