import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Submission } from '../class/submission';
import { Observable } from 'rxjs';
import { Role } from '../class/role';
import { environment } from 'src/environments/environment';

// const SERVERLOCAL = 'http://dev.devarchi.fr:8445/';
// const SERVERPROD = 'http://paie.devarchi.fr:8445/';
const SERVER = environment.serverUrl + 'api/';

@Injectable({
    providedIn: 'root'
})


export class SubmissionAbsenceService {


    postDataAbsence(data: any) {
        const url = SERVER + 'user/dataAbsence';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(url, data, { headers, withCredentials: true });
    }

    putDataAbsenceUnit(data: any) {
        const url = SERVER + 'user/dataAbsence/unit';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(url, data, { headers, withCredentials: true });
    }

    putDataAbsences(data: Array<any>) {
      const url = SERVER + 'user/dataAbsence';
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.put(url, data, { headers, withCredentials: true });
    }


    constructor(private http: HttpClient) { }


}
