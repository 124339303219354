import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal, NgbTimeStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Submission } from "src/app/class/submission";
import { SubmissionService } from "src/app/services/submission.service";
import { VariablesPayeService } from "src/app/services/variable-paye-service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { formatDate } from "@angular/common";
import { DatapaieService } from "src/app/services/datapaie.service";
import { VariablesAbsenceService } from "src/app/services/variable-absence-service";
import * as moment from "moment";
import { FormChangeService } from "src/app/services/form-change.service";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
  ValidatorFn,
} from "@angular/forms";
import { ModalRASComponent } from 'src/app/modal/modalRAS.component';
import { AlertService } from 'src/app/services/alert-service.service';

@Component({
  selector: "app-absences-update",
  templateUrl: "./absences-update.component.html",
  styleUrls: ["./absences-update.component.scss"],
})
export class AbsencesUpdateComponent implements OnInit {
  JSON: JSON;
  @Input() title;
  @Input() employee: any;
  @Input() period: any;
  @Input() soldCompte: boolean;
  @Input() checkRas: boolean;
  @Input() withAddBtn: boolean;
  @Input() showDelete = false;
  @Input() resultDay: any = {};
  listVars: Array<any>;

  items = new Array<any>();
  myForm: FormGroup;
  nbDay = new Array<any>();
  absence: any;

  dataAbsenceForm: FormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private authenSer: AuthenticationService,
    private submissionService: SubmissionService,
    protected modalService: NgbModal,
    private absenceVarService: VariablesAbsenceService,
    private formService: FormChangeService,
    private alertServ: AlertService,
  ) {}

  checkendAbsence(event, index){
    let form = this.dataAbsence.value;
    let end = form[index]?form[index].endAbsence: null;
    let start = form[index]?form[index].startAbsence: null;
    let startHalfDay = form[index].startAbsenceAprem != undefined ? form[index].startAbsenceAprem : false;
    let endHalfDay =  form[index].endAbsenceMatin != undefined ? form[index].endAbsenceMatin : false;
    debugger;
    if(event && event['target'] && event['target'].checked == true){
      endHalfDay = true;
    }
    if(end){
      if(start){

      this.absenceVarService.getNbrJour(start, startHalfDay,end, endHalfDay).subscribe(
        data =>{

          this.nbDay[index]= data;
          (this.dataAbsence.at(index) as FormGroup)
          .controls['nbDay'].setValue( data as number);
        },
        error =>{
          console.log(error);
        }
      );
    } else {
      this.nbDay[index]= null;
      (this.dataAbsence.at(index) as FormGroup)
          .controls['nbDay'].setValue(null);
    }
  }
  }
  onKeyUpNbrJourEvent(event: KeyboardEvent, absenceIndex): void {
    let nbrJour = (this.dataAbsence.at(absenceIndex) as FormGroup)
          .controls['nbDay'].value;
    let form = this.dataAbsence.value;
    let start = form[absenceIndex]? form[absenceIndex].startAbsence: null;
    let startHalfDay = form[absenceIndex].startAbsenceAprem != undefined ? form[absenceIndex].startAbsenceAprem : false;
    let endHalfDay =  form[absenceIndex].endAbsenceMatin != undefined ? form[absenceIndex].endAbsenceMatin : false;

    if(start && nbrJour){
        this.absenceVarService.getEndDate(start,startHalfDay, endHalfDay, this.nbDay).subscribe(
          (data) =>{
            ((this.dataAbsenceForm.get("dataAbsences") as FormArray)
            .at(absenceIndex) as FormGroup)
            .controls['endAbsence']
            .setValue(moment(data).format('YYYY-MM-DD'));

          },
          (error)=>{
          }
          );

      }
 }
 deleteDataAbsence() {
  this.dataAbsence.controls.pop();
  this.dataAbsence.updateValueAndValidity();
  if (this.dataAbsence.length > 1){
    this.showDelete = true;
  } else {
    this.showDelete = false;
  }
}
isValid(f){
  return f.valid;
}
  checkstartAbsence(event, index){
    let form = this.dataAbsence.value;
    let start = form[index]?form[index].startAbsence:null;
    let end = form[index]?form[index].endAbsence:null;
    let startHalfDay =   form[index].startAbsenceAprem != undefined ? form[index].startAbsenceAprem : false;
    let endHalfDay =   form[index].endAbsenceMatin != undefined ? form[index].endAbsenceMatin : false;
    debugger;
    if(event && event['target'] && event['target'].checked == true){
      startHalfDay = true
    }
    if(start){
      if(end){
      this.absenceVarService.getNbrJour(start, startHalfDay, end, endHalfDay).subscribe(
        data =>{
      (this.dataAbsence.at(index) as FormGroup)
          .controls['nbDay'].setValue( data as number);
        },
        error =>{
          console.log(error);
        }
      );
    } else {
      (this.dataAbsence.at(index) as FormGroup)
          .controls['nbDay'].setValue( null);
    }
  }
  }

  get dataAbsence() {
    return this.dataAbsenceForm.get("dataAbsences") as FormArray;
  }
  addDataAbsence() {
    this.dataAbsence.push(
      this.fb.group({
        variableId: new FormControl("", [Validators.required]),
        startAbsence: new FormControl("", [Validators.required]),
        startAbsenceAprem:false,
        endAbsence: new FormControl("", [Validators.required]),
        endAbsenceMatin: false,
        nbDay: new FormControl(""),
        nbDayRemaining: new FormControl(""),
      })
    );
    if (this.dataAbsence.length > 1) {
      this.showDelete = true;
    }
  }

  ngOnInit() {

    this.absenceVarService
      .getVariableAbsence(this.authenSer.getUserLoggedIn())
      .subscribe(
        (data) => {
          this.items = data as Array<any>;
        },
        (error) => {
          console.log(error);
        }
      );
    this.updateForm(this.absence);
  }

  updateForm(absence: any) {
    if (absence){
      if (absence.length == 1) {//cas d'update unitaire
      let endHalfDay = absence.endHalfDay;
        absence['endHalfDay'] = endHalfDay;
        this.dataAbsenceForm = this.fb.group({
          dataAbsences: this.fb.array([
            this.fb.group({
              id: absence[0].id,
              variableId: new FormControl(absence[0]?absence[0].variableId:'', [Validators.required]),
              startAbsence: new FormControl(absence[0].startAbsence ? moment(absence[0].startAbsence).format('YYYY-MM-DD') : '', [Validators.required]),
              startAbsenceAprem: new FormControl(absence[0].startHalfDay),
              endAbsence: new FormControl(absence[0].endAbsence ? moment(absence[0].endAbsence).format('YYYY-MM-DD') : '', [Validators.required]),
              endAbsenceMatin: new FormControl(absence[0].endHalfDay),
              nbDay: new FormControl(absence[0].nbDay),
              nbDayRemaining: new FormControl(absence[0].nbDayRemaining),
            }),
          ]),
        });
      } else {

          absence = this.absence.filter(
            abs =>{
              //dans ce cas on garde pas l'absence RAS
              if(//abs.variableId  &&
                !abs.noReport){
                return true;
              } else {
                return false;
              }
            }
          );
        absence.forEach((abs,index) =>{
          this.dataAbsence.push(
            this.fb.group({
              variableId: new FormControl(absence?absence.variableId:'', [Validators.required]),
              startAbsence: new FormControl(absence.startAbsence ? moment(absence.startAbsence).format('YYYY-MM-DD') : '', [Validators.required]),
              startAbsenceAprem: new FormControl(absence.startHalfDay),
              endAbsence: new FormControl(absence.endAbsence ? moment(absence.endAbsence).format('YYYY-MM-DD') : '', [Validators.required]),
              endAbsenceMatin: new FormControl(absence.endHalfDay),
              nbDay: new FormControl(absence.nbDay),
              nbDayRemaining: new FormControl(absence.nbDayRemaining),
            })
          );
        });
    }
    } else {

      if (this.employee.dataAbsences){
        this.dataAbsenceForm = this.fb.group({
          dataAbsences: this.fb.array([])
        });
        //cas d'update multiple (on vient de la page saisie)
        let absencesEmp = this.employee.dataAbsences.filter(
          abs =>{
            //dans ce cas on garde pas l'absence RAS
            if(//abs.variableId  &&
              !abs.noReport){
              return true;
            } else {
              return false;
            }
          }
        );
        absencesEmp.forEach(
          (abs, index) =>{
            this.dataAbsence.push(
              this.fb.group({
                id: abs?abs.id:'',
                variableId: abs ? abs.variableId : "",
                startAbsence: abs.startAbsence
                  ? moment(abs.startAbsence).format("YYYY-MM-DD")
                  : "",
                  startAbsenceAprem: abs.startHalfDay,
                endAbsence: abs.endAbsence
                  ? moment(abs.endAbsence).format("YYYY-MM-DD")
                  : "",
                endAbsenceMatin: abs.endHalfDay,
                nbDay: abs.nbDay,
                nbDayRemaining: abs.nbDayRemaining,
              })
            );
          }
        )
      }
    }
  }

  saisieRasPaie(event) {
    if(this.checkRas){
    const modalRefere = this.modalService.open(ModalRASComponent, {
      backdrop: "static", keyboard:false
    });
    modalRefere.componentInstance.title =
      "Saisie des variables de paie pour " + this.employee.firstname + "  " + this.employee.name;
    modalRefere.componentInstance.message = "Confirmez le RAS ?";
    modalRefere.componentInstance.withCancel = true;
    modalRefere.result.then((result) => {
      if (result != false) {
        let emptyDataPaie = {
          employeeId: this.employee.id,
        };
        this.submissionService.postNoReportAbsence(emptyDataPaie).subscribe(
          (data: any) => {
            this.checkRas = true;
          },
          (error) => {
            console.log(error);
            this.checkRas = false;
            this.alertServ.error("Erreur lors de la Saisie RAS")
          }
        );
      } else {
        this.checkRas = false;
      }
    });
  }
  }

  update() {
    debugger;
    if(this.checkRas &&this.soldCompte){
      this.close('RAS');
    } else {
    let formData = this.dataAbsenceForm.value.dataAbsences as Array<any>;
    let data = new Array<any>();
    if (formData) {

      formData.forEach((newDataAbsence) => {
        let absence = {};
        absence["id"] = newDataAbsence.id;
        absence["employeeId"] = this.employee.id;
        absence["period"] = this.period;
        absence["startAbsence"] = newDataAbsence.startAbsence;
        absence["startHalfDay"] = newDataAbsence.startAbsenceAprem;
        absence["endAbsence"] = newDataAbsence.endAbsence;
        absence["endHalfDay"] = newDataAbsence.endAbsenceMatin;
        absence["variableId"] = newDataAbsence.variableId;
        data.push(absence);
      });
    }
    this.close(data);
  }
  }
  close(data) {
    this.activeModal.close(data);
  }
}
