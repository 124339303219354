import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbTimeStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Submission } from '../class/submission';
import { SubmissionService } from '../services/submission.service';
import { VariablesPayeService } from '../services/variable-paye-service';
import { AuthenticationService } from '../services/authentication.service';
import { formatDate } from '@angular/common';
import { DatapaieService } from '../services/datapaie.service';
import { DataAbsenceService } from '../services/dataAbsence.service';
import { debug } from 'util';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { FormChangeService } from '../services/form-change.service';
import { AlertService } from '../services/alert-service.service';
@Component({
    selector: 'app-saisies-update',
    templateUrl: './saisies-update.component.html',
    styleUrls: ['./saisies.component.scss']
})
export class SaisiesUpdateComponent implements OnInit {
    @Input() title;
    @Input() employee: any;
    objSubmission: Submission;
    @Input() period;
    @Input() soldCompte: boolean;
    @Input() checkRas;

    message: any = '';
    model: any = {};
    model2: any = {};
    dataPaieId: any = {};

    listVars: Array<any>;
    constructor(
        public activeModal: NgbActiveModal,
        private authenSer: AuthenticationService,
        private paieVarService: VariablesPayeService,
        private dataAbsService: DataAbsenceService,
        protected modalService: NgbModal,
        private formService: FormChangeService,
        private submissionService: SubmissionService,
        private datapaieService: DatapaieService,
        private alertServ: AlertService,
    ) {
    }

    validate() {
      if(this.soldCompte && this.checkRas){
        this.activeModal.close('RAS');
      } else {
        const employeedataPaie = new Array<any>();
        let data = {};
        let errors;
        data['dataPaies'] = new Array<any>();

        for(let key of Object.keys(this.model)){
            let dataPaie = {};
            dataPaie['variableId'] = key;
            let val =this.model[key];
            let varcontrol = this.model2[key];
            if(varcontrol && varcontrol.mandatory && !val){
              let tmp = varcontrol;
              tmp['mandatoryError'] = true;
              this.model2[key] = tmp;
              errors = true;
            } else {
              dataPaie['value'] = val;
              dataPaie['employeeId'] = this.employee.id;
              dataPaie['id'] = this.dataPaieId[key];
              data['dataPaies'].push(dataPaie);
              data['comment'] = this.message;
            }
        };
        if(errors){
          return;
        }
        data['employeeId'] = this.employee.id;
        this.activeModal.close(data);
      }
    }

    saisieRasPaie() {
      const modalRefere = this.modalService.open(ModalRASComponent, {
        backdrop: "static",
      });
      modalRefere.componentInstance.title =
        "Saisie des variables de paie pour " + this.employee.firstname + "  " + this.employee.name;
      modalRefere.componentInstance.message = "Confirmez le RAS ?";
      modalRefere.componentInstance.withCancel = true;
      modalRefere.result.then((result) => {
        if (result != false) {
          let emptyDataPaie = {
            employeeId: this.employee.id,
            //period: this.period,
          };
          this.submissionService.postNoReport(emptyDataPaie).subscribe(
            (data: any) => {
              // this.formService.raiseReloadSaisiesRequestEvent();
              // this.chkBoxDataPayeRas[employee.id] = true;
              // this.activeModal.close('RAS');
              this.checkRas = true;
            },
            (error) => {
              console.log(error);
              // this.formService.raiseReloadSaisiesRequestEvent();
              this.checkRas = false;
              this.alertServ.error("Erreur lors de la saisie RAS");
              // this.activeModal.close(null);
            }
          );
        } else {
          // this.activeModal.close(null);
          // this.chkBoxDataPayeRas[employee.id] = false;
        }
      });
    }

    ngOnInit() {

        this.paieVarService.getVariablePaye().subscribe(
            data => {//erreur ? exemple hector lumière : listVars.lengh = 2, mais employeeDataPaieByPeriod = 4 avec des value null !!
                this.listVars = data as Array<any>;
                let employeeDataPaieByPeriod: any;
                this.datapaieService.getDataPaieByEmployeePeriod(this.employee.id, this.period)
                .subscribe(
                    res => {
                      employeeDataPaieByPeriod = res as Array<any>;
                      this.message = employeeDataPaieByPeriod.comment;
                      employeeDataPaieByPeriod.dataPaies.forEach(empVar => {
                            this.listVars.filter(elem => {
                                return elem.id === empVar.variableId;
                            }).map(el => {
                              let tmp= {};
                              debugger;
                              tmp['mandatory'] = el.mandatory;
                              if(tmp['noReport'] != true){
                                this.model[el.id] = empVar.value;
                              }
                              this.model2[el.id] = tmp;
                              this.dataPaieId[el.id] = empVar.id;
                            });
                        });
                    }

                );
            },
            error => {
                console.log(error);
            }
        );
    }
}
