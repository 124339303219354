import { Component, OnInit, Input } from '@angular/core';
import { Submission } from 'src/app/class/submission';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { VariablesPayeService } from 'src/app/services/variable-paye-service';
import { SoldeCompteReason, LicenciementType, FaultType } from 'src/app/class/unit.enum';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { EmployeeService } from 'src/app/services/employee.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { AlertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-employee-deletion',
  templateUrl: './employee-deletion.component.html',
  styleUrls: ['./employee-deletion.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class EmployeeDeletionComponent implements OnInit {

  soldeCompteReasonEnum = SoldeCompteReason;
  licencimentTypeEnum = LicenciementType;
  @Input() employee: any;
  fileToUpload: File;
  currentTab = 0; // Current tab is set to be the first tab (0)
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  model: any = {};
  fautTypeEnum = FaultType;
  keys = Object.keys;
  activeStep = 0;
  stepsNumber = 3;
  constructor(
      private _formBuilder: FormBuilder,
      public activeModal: NgbActiveModal,
      private employeeSer: EmployeeService,
      private formServ: FormChangeService,
      private alertService: AlertService
  ) { }
  vali(f){
    debugger;
    return f.form.valid;
  }
  showDateNofi() {
    const modeValue = this.firstFormGroup.get('mode').value;
    const fautTypeValue = this.firstFormGroup.get('faultType').value;
    if ( modeValue
        && SoldeCompteReason[modeValue] == SoldeCompteReason.DEMISSION
        || SoldeCompteReason[modeValue] == SoldeCompteReason.DEPART_RETRAITE
        || SoldeCompteReason[modeValue] == SoldeCompteReason.MISE_RETRAITE
        || (SoldeCompteReason[modeValue] == SoldeCompteReason.LICENCIEMENT
          && fautTypeValue
            && (FaultType[fautTypeValue] == FaultType.GRAVE
              || FaultType[fautTypeValue] == FaultType.LOURDE)
          )
      ) {
      return true;
    }
    return false;
  }
  showLicenciementReason() {
    const modeValue = this.firstFormGroup.get('mode').value;
    return modeValue && SoldeCompteReason[modeValue] == SoldeCompteReason.LICENCIEMENT;
  }
  showFile(){
    const modeValue = this.firstFormGroup.get('mode').value;
    return modeValue && SoldeCompteReason[modeValue] == SoldeCompteReason.RUPTURE_CONV;
  }
  showDateFin() {
    const modeValue = this.firstFormGroup.get('mode').value;
    return modeValue && SoldeCompteReason[modeValue] == SoldeCompteReason.FIN;
  }
  showPreavisEffectue() {
    let modeValue = this.firstFormGroup.get('mode').value;
    let  licenciementReasonValue = this.firstFormGroup.get('licenciementReason').value;
    if ( modeValue
        && (SoldeCompteReason[modeValue] == SoldeCompteReason.LICENCIEMENT
        && licenciementReasonValue
            && (
              // LicenciementType[licenciementReasonValue] == LicenciementType.CAUSE_REEL_SERIEUSE ||
               LicenciementType[licenciementReasonValue] == LicenciementType.ECO)
          )
      ) {
      return true;
      }
    return false;
  }
  showDatePresention() {
    const modeValue = this.firstFormGroup.get('mode').value;
    const licenciementReasonValue = this.firstFormGroup.get('licenciementReason').value;
    if ( modeValue
        && (SoldeCompteReason[modeValue] == SoldeCompteReason.LICENCIEMENT
        && licenciementReasonValue
            && (
              // LicenciementType[licenciementReasonValue] == LicenciementType.CAUSE_REEL_SERIEUSE||
               LicenciementType[licenciementReasonValue] == LicenciementType.ECO
              || LicenciementType[licenciementReasonValue] == LicenciementType.INAPTITUDE_NON_PRO
              || LicenciementType[licenciementReasonValue] == LicenciementType.INAPTITUDE_PRO
              )
        )
      ) {
      return true;
      }
    return false;
  }
  showFautType() {
    const modeValue = this.firstFormGroup.get('mode').value;
    const licenciementReasonValue = this.firstFormGroup.get('licenciementReason').value;
    if ( modeValue
        && SoldeCompteReason[modeValue] == SoldeCompteReason.LICENCIEMENT
        && licenciementReasonValue
              &&
               LicenciementType[licenciementReasonValue] == LicenciementType.PERSO
      ) {
      return true;
      }
    return false;
  }

  showDateDebutPreavis() {
    const modeValue = this.firstFormGroup.get('mode').value;
    if ( modeValue
        && SoldeCompteReason[modeValue] == SoldeCompteReason.DEMISSION
        || SoldeCompteReason[modeValue] == SoldeCompteReason.DEPART_RETRAITE
        || SoldeCompteReason[modeValue] == SoldeCompteReason.MISE_RETRAITE
      ) {
      return true;
      }
    return false;
  }
  showDateFinPreavis() {
    const modeValue = this.firstFormGroup.get('mode').value;
    const licenciementReasonValue = this.firstFormGroup.get('licenciementReason').value;
    if ( modeValue
        && SoldeCompteReason[modeValue] == SoldeCompteReason.DEMISSION
        || SoldeCompteReason[modeValue] == SoldeCompteReason.DEPART_RETRAITE
        || SoldeCompteReason[modeValue] == SoldeCompteReason.MISE_RETRAITE
        || (SoldeCompteReason[modeValue] == SoldeCompteReason.LICENCIEMENT
          && licenciementReasonValue &&
            (
              // LicenciementType[licenciementReasonValue] == LicenciementType.CAUSE_REEL_SERIEUSE ||
              LicenciementType[licenciementReasonValue] == LicenciementType.ECO)
          )
      ) {
      return true;
      }
    return false;
  }
  showDateDeces() {
    const modeValue = this.firstFormGroup.get('mode').value;
    return  modeValue
        && SoldeCompteReason[modeValue] == SoldeCompteReason.DECES;
  }
  createSoldComp(): any {
      let tmp = {
      mode: this.firstFormGroup.get(["mode"]).value? this.firstFormGroup.get(["mode"]).value : null,
      dateConvocation	: this.firstFormGroup.get(["dateConvocation"]).value? this.firstFormGroup.get(["dateConvocation"]).value : null,
      dateDeces	: this.firstFormGroup.get(["dateDeces"]).value? this.firstFormGroup.get(["dateDeces"]).value : null,
      dateEntretien	: this.firstFormGroup.get(["dateEntretien"]).value? this.firstFormGroup.get(["dateEntretien"]).value : null,
      dateEnvoiNotification	: this.firstFormGroup.get(["dateEnvoiNotification"]).value? this.firstFormGroup.get(["dateEnvoiNotification"]).value : null,
      dateFin	: this.firstFormGroup.get(["dateFin"]).value? this.firstFormGroup.get(["dateFin"]).value : null,
      dateFinPreavis	: this.firstFormGroup.get(["dateFinPreavis"]).value? this.firstFormGroup.get(["dateFinPreavis"]).value : null,
      dateDebPreavis: this.firstFormGroup.get(["dateDebPreavis"]).value? this.firstFormGroup.get(["dateDebPreavis"]).value : null,
      dateNotification : this.firstFormGroup.get(["dateNotification"]).value? this.firstFormGroup.get(["dateNotification"]).value : null,
      datePremierePresentation : this.firstFormGroup.get(["datePremierePresentation"]).value? this.firstFormGroup.get(["datePremierePresentation"]).value : null,
      employeeId	: this.employee.id,
      faultType	: this.firstFormGroup.get(["faultType"]).value? this.firstFormGroup.get(["faultType"]).value : null,
      licenciementReason: this.firstFormGroup.get(["licenciementReason"]).value? this.firstFormGroup.get(["licenciementReason"]).value : null,
      preavisEffectue	: this.firstFormGroup.get(["preavisEffectue"]).value? this.firstFormGroup.get(["preavisEffectue"]).value : null,
      };
      debugger;
      return tmp;
    }
  save() {
    let soldCompte = this.createSoldComp();
    this.employeeSer.soldeEmployee(soldCompte, this.fileToUpload).subscribe(
        (data) => {
          this.formServ.raiseFormEmployeeSearchRequest();
          this.alertService.success("Solde de Compte envoyé: ",true);
          this.close();
        },
        (error) => {
          this.alertService.error("Erreur lors de l'envoie Solde de Compte",true)
          console.log(error);
        }
      );
  }

  close(): void {
    this.activeModal.close();
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      mode: ['', Validators.required], // SoldeCompteReason
      dateConvocation	: '',
      dateDeces	: '',
      dateEntretien	: '',
      dateEnvoiNotification	: '',
      dateFin	: '',
      dateFinPreavis	: '',
      dateDebPreavis: '',
      dateNotification : '',
      datePremierePresentation : '',
      employeeId	: '',
      faultType	: '',
      licenciementReason: '',
      preavisEffectue	: '',
    });
    this.employee;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  goBack() {
    if (this.activeStep) {
    this.activeStep--;
    }
  }
  goNext() {
    if (this.activeStep < this.stepsNumber) {
      this.activeStep++;
    } else {
      this.save();
    }
  }
  validateForm() {

  }
}
