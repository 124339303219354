import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { Submission } from "../../class/submission";
import { SubmissionService } from "../../services/submission.service";
import { VariablesPayeService } from "../../services/variable-paye-service";
import { AuthenticationService } from '../../services/authentication.service';
import { CustomerService } from '../../services/customer.service';
import { Site, ISite } from '../../class/site';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertService } from '../../services/alert-service.service';
import { isEmpty } from 'rxjs/operators';
@Component({
  selector: "app-siteUpdate",
  templateUrl: "./sites-update.component.html",
  styleUrls: ["./sites-update.component.scss"]
})
export class SitesUpdateComponent implements OnInit {
  @Input() title = ``;
  @Input() message = ``;
  @Input() site: any;
  @Input() customerId: any;

  // isEmpty: boolean;

  objSubmission: Submission;
  siteName: any;
  id: any;
  model: any = {};
  constructor(
    public activeModal: NgbActiveModal,
    protected alertService: AlertService,
    private authenSer: AuthenticationService,
    private customerService: CustomerService,
    private paieVarService: VariablesPayeService,
  ) { }

  ngOnInit() {
  }

  private createFromForm(): any {
    return {
      customerId: this.customerId,
      name: this.siteName,
      id: this.id
    };
  }

  validate() {
    this.site = this.createFromForm();

        this.customerService.updateSite(this.site, this.authenSer.getUserLoggedIn())
        .subscribe(
          (result) => {
            this.alertService.success('Site mis à jour !', false);
            this.activeModal.close(true);
          },
          (result) => {
            this.alertService.error('Erreur dans la mise à jour du site !', false);
            this.activeModal.close(false);
          }
        );
    }
  // }

}
