import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { AlertService } from '../services/alert-service.service';
import { FormChangeService } from '../services/form-change.service';
import { FormBuilder, FormGroup, NgModel, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../services/customer.service';
import { MessagesService } from '../services/messages.service';
import { FindValueOperator } from 'rxjs/internal/operators/find';


@Component({
  selector: 'app-saisie-message',
  templateUrl: './saisie-message.component.html',
  styleUrls: ['./saisie-message.component.scss']
})
export class SaisieMessageComponent implements OnInit {
  selectedCustomer: any[];
  customs: any[];
  message: any;
  model: NgModel;
  searchText: string;
  messageForm ;

  constructor(protected activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private formService: FormChangeService,
    protected alertService: AlertService,
    protected router: Router,
    protected referencielService: CustomerService,
    public messageService: MessagesService) {}

  ngOnInit() {
    this.initForm();
    this.subscribeFormChange();
  }
  initForm() {
    let filter = ''; // this.getFilter();
    this.messageForm = this.fb.group({
      message: new FormControl("", [Validators.required]),
      customers: new FormControl("", [Validators.required]),
    });
    this.customerCallBackEnd(filter)
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.customs = tmp;

        }
        , error => {

          console.log(error);
          this.formService.raiseCustomerListEvent({});

        });
  }
  customerCallBackEnd(filter) {
    return this.referencielService.getCustomers();
  }
  previousState(): void {
    window.history.back();
  }
  subscribeFormChange() {
    this.formService.formCustomerSearchChanged.subscribe(
      (param) => {

        this.customs = param;
      }
    )
  }
  private findTextInPlaces(customers, text) {
    text = text?text.split(' '):'';
    return customers.filter(function (item) {

      return text.every(function (el) {
        return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1
          || item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }

  save() {
    let map = {};
    map['customerIds'] = this.selectedCustomer;
    map['message'] = this.message;

    this.messageService.saveAdminMessages(map).subscribe(
      (param) => {
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['message']);
        });
        let self = this;
        setTimeout(function () {
          self.alertService.success('Message sauvegardé!', true);
        }, 500);


      }
    );
  }

}
