import { Component, OnInit } from "@angular/core";
import { TenantService } from "../tenant.service";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { AccessMode, PayeSystem } from "src/app/class/unit.enum";
import { EmployeeService } from "../employee.service";
import { AlertService } from "../alert-service.service";
import { AuthenticationService } from "../authentication.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormChangeService } from "../form-change.service";
import { CustomerService } from "../customer.service";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ModalRASComponent } from 'src/app/modal/modalRAS.component';

@Component({
  selector: "app-parameters",
  templateUrl: "./parameters.component.html",
  styleUrls: ["./parameters.component.scss"],
})
export class ParametersComponent implements OnInit {
  mytenantForm: FormGroup;
  connectionTestOk;
  testOngoing = false;
  testStated = false;
  testBtnDisable = false;
  PayeSystemEnum = PayeSystem;
  keys = Object.keys;
  accessModeEnum = AccessMode;
  myTenant;
  tenantArg;
  currentTenant;
  constructor(
    protected employeeService: EmployeeService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    protected formService: FormChangeService,
    private customerService: CustomerService,
    private route: Router,
    private tenantServ: TenantService,
    protected modalService: NgbModal,
  ) {
    this.tenantArg = this.route.getCurrentNavigation().extras.state
      ? this.route.getCurrentNavigation().extras.state.tenant
      : null;
  }

  ngOnInit(): void {
    this.initForm();
    if (this.tenantArg) {
      this.currentTenant = this.tenantArg;
      this.updateForm(this.tenantArg);
    } else {
      this.myTenant = this.tenantServ.getTenants().subscribe((data) => {
        this.myTenant = data;
        this.currentTenant = data;
        this.updateForm(data);
      });
    }
  }

  initForm() {
    this.mytenantForm = this.fb.group({
      id: new FormControl(""),
      remindMailActive: new FormControl(""),
      remindMailDay: new FormControl(""),
      remindSmsActive: new FormControl(""),
      remindSmsDay: new FormControl(""),
      systemPaie: new FormControl(""),
      systemPaieBddHost: new FormControl(""),
      systemPaieBddPwd: new FormControl(""),
      systemPaieBddUser: new FormControl(""),
      systemPaieVersion: new FormControl(""),
      systemSync: new FormControl(""),

      activity: new FormControl(""),
      adress: new FormControl(""),
      city: new FormControl(""),
      companyName: new FormControl(""),
      contactEmail: new FormControl(""),
      contactName: new FormControl(""),
      contactPosition: new FormControl(""),
      cp: new FormControl(""),
      methodPaymentId: new FormControl(""),
      siret: new FormControl(""),
      stripeCustomerId: new FormControl(""),
      tel1: new FormControl(""),
      tel2: new FormControl(""),
    });
  }

  updateForm(tenant): void {
    this.mytenantForm.patchValue({
      id: this.tenantArg ? this.tenantArg.id : this.myTenant.id,
      remindMailActive: tenant.remindMailActive,
      remindMailDay: tenant.remindMailDay,
      remindSmsActive: tenant.remindSmsActive,
      remindSmsDay: tenant.remindSmsDay,
      systemPaie: tenant.systemPaie,
      systemPaieBddHost: tenant.systemPaieBddHost,
      systemPaieBddPwd: tenant.systemPaieBddPwd,
      systemPaieBddUser: tenant.systemPaieBddUser,
      systemPaieVersion: tenant.systemPaieVersion,
      systemSync: tenant.systemSync,

      activity: tenant.activity,
      adress: tenant.adress,
      city: tenant.city,
      companyName: tenant.companyName,
      contactEmail: tenant.contactEmail,
      contactName: tenant.contactName,
      contactPosition: tenant.contactPosition,
      cp: tenant.cp,
      methodPaymentId: tenant.methodPaymentId,
      siret: tenant.siret,
      stripeCustomerId: tenant.stripeCustomerId,
      tel1: tenant.tel1,
      tel2: tenant.tel2,
    });
  }

  private createFromForm(): any {
    let tmp = {
      id: this.tenantArg ? this.tenantArg.id : this.myTenant.id,
      remindMailActive: this.mytenantForm.get(["remindMailActive"])!.value,
      remindSmsActive: this.mytenantForm.get(["remindSmsActive"])!.value,
      systemPaie: this.mytenantForm.get(["systemPaie"]).value? this.mytenantForm.get(["systemPaie"]).value: null,
      // this.mytenantForm.get(["systemPaie"])!.value,
      systemPaieBddHost: this.mytenantForm.get(["systemPaieBddHost"])!.value,
      systemPaieBddPwd: this.mytenantForm.get(["systemPaieBddPwd"])!.value,
      systemPaieBddUser: this.mytenantForm.get(["systemPaieBddUser"])!.value,
      systemPaieVersion: this.mytenantForm.get(["systemPaieVersion"])!.value,
      systemSync: this.mytenantForm.get(["systemSync"])!.value,

      activity: this.tenantArg
        ? this.tenantArg.activity
        : this.myTenant.activity,
      adress: this.tenantArg ? this.tenantArg.adress : this.myTenant.adress,
      city: this.tenantArg ? this.tenantArg.city : this.myTenant.city,
      companyName: this.tenantArg
        ? this.tenantArg.companyName
        : this.myTenant.companyName,
      contactEmail: this.tenantArg
        ? this.tenantArg.contactEmail
        : this.myTenant.contactEmail,
      contactName: this.tenantArg
        ? this.tenantArg.contactName
        : this.myTenant.contactName,
      contactPosition: this.tenantArg
        ? this.tenantArg.contactPosition
        : this.myTenant.contactPosition,
      cp: this.tenantArg ? this.tenantArg.cp : this.myTenant.cp,
      methodPaymentId: this.tenantArg
        ? this.tenantArg.methodPaymentId
        : this.myTenant.methodPaymentId,
      siret: this.tenantArg ? this.tenantArg.siret : this.myTenant.siret,
      stripeCustomerId: this.tenantArg
        ? this.tenantArg.stripeCustomerId
        : this.myTenant.stripeCustomerId,
      tel1: this.tenantArg ? this.tenantArg.tel1 : this.myTenant.tel1,
      tel2: this.tenantArg ? this.tenantArg.tel2 : this.myTenant.tel2,
    };
    let remindSmsActif = this.mytenantForm.get(["remindSmsActive"])!.value;
    if (remindSmsActif) {
      tmp["remindSmsDay"] = this.mytenantForm.get(["remindSmsDay"])!.value;
    }
    let remindMailActif = this.mytenantForm.get(["remindMailActive"])!.value;
    if (remindMailActif) {
      tmp["remindMailDay"] = this.mytenantForm.get(["remindMailDay"])!.value;
    }
    return tmp;
  }
  checksRemaindMail() {

    if (this.remidEmailActif()) {
      this.mytenantForm.addControl("remindMailDay",new FormControl('', [Validators.required]));
    } else {
      this.mytenantForm.removeControl("remindMailDay");
    }
  }
  checksRemaindSMS() {

    if (this.remidSmsActif()) {
      this.mytenantForm.addControl("remindSmsDay",new FormControl('', [Validators.required]));
    } else {
      this.mytenantForm.removeControl("remindSmsDay");
    }
  }

  openConfirmRemaindSMS(event) {

    if (this.remidSmsActif()){
      const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
      modalRef.componentInstance.title = 'Activer le Rappel SMS';
      modalRef.componentInstance.message = 'Cette option engendre des frais à hauteur de 0,50€ / sms. \n\nVoulez-vous activer cette option ?';
      modalRef.componentInstance.withCancel = true;
      modalRef.result.then(result => {

        if (!result){
          this.mytenantForm.get(["remindSmsActive"]).setValue('');
          this.mytenantForm.removeControl("remindSmsDay");
        }

        this.checksRemaindSMS();
      });
  }
  }

  checksSystemSync() {
    if (this.systemSyncActif()) {
      this.mytenantForm.controls["systemPaieBddHost"].setValidators(
        Validators.required
      );
      this.mytenantForm.controls["systemPaieBddPwd"].setValidators(
        Validators.required
      );
      this.mytenantForm.controls["systemPaieBddUser"].setValidators(
        Validators.required
      );
    } else {
      this.mytenantForm.controls["systemPaieBddHost"].setErrors(null);
      this.mytenantForm.controls["systemPaieBddPwd"].setErrors(null);
      this.mytenantForm.controls["systemPaieBddUser"].setErrors(null);
      this.mytenantForm.controls["systemPaieBddHost"].setValidators([]);
      this.mytenantForm.controls["systemPaieBddPwd"].setValidators([]);
      this.mytenantForm.controls["systemPaieBddUser"].setValidators([]);
    }
  }
  update() {
    let tenant = this.createFromForm();
    this.tenantServ.putTenant(tenant).subscribe(
      (data) => {
        this.alertService.success("Modification effectuÃ©e.", true);
        if (this.authenSer.isSupperAdmin) {
          // this.route.navigateByUrl('/salaries');
        }
      },
      (error) => {
        this.alertService.warn("Erreur de modification.", true);
      }
    );
  }
  isValid(f) {

  }
  remidEmailActif() {
    return (
      this.mytenantForm.get(["remindMailActive"]) &&
      this.mytenantForm.get(["remindMailActive"])!.value == true
    );
  }
  remidSmsActif() {
    return (
      this.mytenantForm.get(["remindSmsActive"]) &&
      this.mytenantForm.get(["remindSmsActive"])!.value == true
    );
  }
  systemSyncActif() {
    return (
      this.mytenantForm.get(["systemSync"]) &&
      this.mytenantForm.get(["systemSync"])!.value == true
    );
  }
  checkbddConnection() {
    this.testStated = true;
    this.testOngoing = true;
    let bddName = this.mytenantForm.get(["systemPaieBddName"])!.value;
    this.customerService.checkdbbConnection(bddName).subscribe(
      (data) => {
        if (data) {
          this.connectionTestOk = true;
        } else {
          this.connectionTestOk = false;
        }

        this.testOngoing = false;
      },
      (error) => {
        this.connectionTestOk = false;
        this.testOngoing = false;
        console.log(error);
      }
    );
  }
  onBddNameChange() {
    let val = this.mytenantForm.get(["systemPaieBddName"])!.value;

    if (val != null) {
      this.testBtnDisable = false;
    } else {
      this.testBtnDisable = true;
    }
    this.testStated = false;
    this.testOngoing = false;
  }
  istestBtnDisable() {
    return this.testBtnDisable;
  }
}
