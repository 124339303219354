import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmployeesComponent as EmployeesComponent } from './employees/employees.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { EmployeesCreateComponent } from './employees/employees-create/employees-create.component';
import { AuthGuard } from './Guard/AuthGuard';
import { AbsencesComponent } from './absences/absences.component';
import { SaisiesComponent } from './saisies/saisies.component';
import { VariablePayeComponent } from './variable-paye/variable-paye.component';
import { VariablePayeUpdateComponent } from './variable-paye/variable-paye-update.component';
import { VariablePayeCreateComponent } from './variable-paye/variable-paye-create.component';
import { SendDocumentComponent } from './send-document/send-document.component';
import { SaisieMessageComponent } from './saisie-message/saisie-message.component';
import { Role } from './class/role';
import { SaisiesUpdateComponent } from './saisies/saisies-update.component';
import { SaisiesPeriodComponent } from './saisies/saisies-period.component';
import { EmployeesUpdateComponent } from './employees/employees-update/employees-update.component';
import { CustomersComponent } from './customer/customers.component';
import { CustomersUpdateComponent } from './customer/customers-update.component';
import { SitesComponent } from './sites/sites.component';
import { ModalSiteComponent } from './modal/modalSite.component';
import { EmployeesImportComponent } from './employees/employees-import/employees-import.component';
import { UserComponent } from './user/user.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { UserUpdateComponent } from './user/user-update/user-update.component';
import { VariableAbsenceComponent } from './variable-absence/variable-absence.component';
import { VariableAbsenceCreateComponent } from './variable-absence/variable-absence-create/variable-absence-create.component';
import { AbsencesCreateComponent } from './absences/absences-create/absences-create.component';
import { VariableAbsenceUpdateComponent } from './variable-absence/variable-absence-update/variable-absence-update.component';
import { CustomersCreateComponent } from './customer/customers-create/customers-create.component';
import { SitesUpdateComponent } from './sites/sites-update/sites-update.component';
import { AbsencesUpdateComponent } from './absences/absences-update/absences-update.component';
import { AbsenceCreateFormComponent } from './absences/absences-create/absence-create-form/absence-create-form.component';
import { RessetLoginComponent } from './resset-login/resset-login.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { ContactComponent } from './contact/contact.component';
import { AddDataPaiesComponent } from './add-data-paies/add-data-paies.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { ProspectCreateComponent } from './prospects/prospect-create/prospect-create.component';
import { ProspectUpdateComponent } from './prospects/prospect-update/prospect-update.component';
import { MonProfileComponent } from './mon-profile/mon-profile.component';
import { AddCardModalComponent } from './credit-card-form/add-card-modal/add-card-modal.component';
import { ConsultationEmployeeComponent } from './employees/consultation-employee/consultation-employee.component';
import { ParametersComponent } from './services/parameters/parameters.component';
import { TenantsComponent } from './tenants/tenants.component';
import { CreateTenantComponent } from './tenants/create-tenant/create-tenant.component';
import { UpdateTentantComponent } from './tenants/update-tentant/update-tentant.component';
import { ConditionGeneralVenteComponent } from './tenants/condition-general-vente/condition-general-vente.component';
import { CgvGuardService } from './Guard/cgv-guard.service';
import { CustomersImportComponent } from './customer/customers-import/customers-import.component';


const routes: Routes = [
  {
    path: 'home', component: HomeComponent,
    canActivate: [AuthGuard]
  },
  { path: 'login', component: LoginComponent },
  {
    path: 'salaries', component: EmployeesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'app-employee', component: EmployeesCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contact', component: ContactComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'update-employees', component: EmployeesUpdateComponent,
    data : {employee : null},
    canActivate: [AuthGuard],
  },
  {
    path: 'detail-employees', component: ConsultationEmployeeComponent,
    data : {employee : null},
    canActivate: [AuthGuard],
  },
  {
    path: 'import-employees', component: EmployeesImportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin.toString()] }
  },
  {
    path: 'absences', component: AbsencesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dataPaies', component: AddDataPaiesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'absences-create', component: AbsencesCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-card', component: AddCardModalComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'absences-create-form', component: AbsenceCreateFormComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'absences-create', component: AbsencesUpdateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profil', component: MonProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'params', component: ParametersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'saisies', component: SaisiesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'saisies-update', component: SaisiesUpdateComponent,

    canActivate: [AuthGuard]
  },
  {
    path: 'saisies-period', component: SaisiesPeriodComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'message', component: SaisieMessageComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin.toString()] }
  },
  {
    path: 'variables', component: VariablePayeComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin.toString()] }
  },
  {
    path: 'variables-update', component: VariablePayeUpdateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'variables-create', component: VariablePayeCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'variables-absence', component: VariableAbsenceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'variables-absence-create', component: VariableAbsenceCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'variables-absence-update', component: VariableAbsenceUpdateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sendDoc', component: SendDocumentComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User.toString()] }
  },
  {
    path: 'create-employees', component: EmployeesCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'customers', component: CustomersComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin.toString()] }
  },
  {
    path: 'import-customers', component: CustomersImportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin.toString()] }
  },
  {
    path: 'create-customers', component: CustomersCreateComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin.toString()] }
  },
  {
    path: 'update-customers', component: CustomersUpdateComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin.toString()] }
  },
  {
    path: 'tenants', component: TenantsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SupperAdmin.toString()] }
  },
  {
    path: 'create-tenant', component: CreateTenantComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SupperAdmin.toString()] }
  },
  {
    path: 'update-tenant', component: UpdateTentantComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SupperAdmin.toString()] }
  },

  {
    path: 'create-site', component: ModalSiteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sites', component: SitesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-sites', component: SitesUpdateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users', component: UserComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin.toString(),Role.SupperAdmin.toString()] }
  },
  {
    path: 'prospects', component: ProspectsComponent,
    canActivate: [AuthGuard],
    data: {roles: [Role.Sale.toString()]}
  },
  {
    path: 'create-prospect', component: ProspectCreateComponent,
    canActivate: [AuthGuard],
    data: {roles: [Role.Sale.toString()]}
  },
  {
    path: 'update-prospect', component: ProspectUpdateComponent,
    canActivate: [AuthGuard],
    data: {roles: [Role.Sale.toString()],prospect : null}
  },
  {
    path: 'create-users', component: UserCreateComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin.toString()] }
  },
  {
    path: 'update-users', component: UserUpdateComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin.toString()] }
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'loginResset', component: RessetLoginComponent
  },
  {
    path: 'loginChange', component: ChangePassComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'conditions', component: ConditionGeneralVenteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'conditions/:validation', component: ConditionGeneralVenteComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CgvGuardService]
  },

  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
