import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Site } from '../class/site';
import { environment } from 'src/environments/environment';
import { Role } from '../class/role';
import { AuthenticationService } from './authentication.service';

type EntityResponseType = HttpResponse<any>;
type EntityArrayResponseType = HttpResponse<any>;
// const apiUrlLOCAL = 'http://apiUrl.devarchi.fr:8445/';
// const apiUrlPROD = 'http://paie.devarchi.fr:8445/';
const apiUrl = environment.serverUrl + 'api/';

@Injectable({
    providedIn: 'root'
})

export class CustomerService {
  sendImportCustomers(fileData: File,siteId) {
    const formData: FormData = new FormData();
    formData.append('file', fileData, fileData.name);
    // formData.append('customerId', customerId);
    if (siteId) {
      formData.append('siteId', siteId);
    }
    let url = apiUrl + 'admin/customers/import';
    let headers = new HttpHeaders();
    return this.http.post(url, formData, { headers, withCredentials: true });
  }

    deleteSite(id: any,currentUser) {
      let subUrl;
      if(this.authenSer.isAdmin){
        subUrl = 'admin';
      } else {
        subUrl = 'user';
      }
      const url = apiUrl + subUrl + '/sites/' + id;
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.delete(url, { withCredentials: true });
    }
  checkdbbConnection(bddName) {
    // let subUrl = Role.valueOf(currentUser.role).value;
    const url = apiUrl + '/admin/systems?systemPaidBddName=' + bddName;
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { withCredentials: true });
  }

    getCustomerById(id) {
      let subUrl;
      if(this.authenSer.isAdmin){
        subUrl = 'admin';
      } else {
        subUrl = 'user';
      }
      const url = apiUrl + subUrl + '/customers/' + id;
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.get(url, { withCredentials: true });
    }

    saveCustomer(customer, varPaieList): Observable<any> {
      let subUrl = '';
      if(varPaieList && varPaieList.length > 0){
         subUrl = '?variablePaieIds=' + varPaieList[0];

      for (let i = 1; i < varPaieList.length; i++) {
          subUrl += '&variablePaieIds=' + varPaieList[i];
      }
    }
        const url = apiUrl + 'admin/customers'+ subUrl;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');

        return this.http.post(url, customer, { withCredentials: true });
    }

    updateCustomer(customer, varPaies): Observable<any> {
      let subUrl = '';
        if(varPaies && varPaies.length > 0){
           subUrl = '?variablePaieIds=' + varPaies[0];
          for (let i = 1; i < varPaies.length; i++) {
              subUrl += '&variablePaieIds=' + varPaies[i];
          }
        }
        const url = apiUrl + 'admin/customers'+ subUrl;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(url, customer, { withCredentials: true });
    }

    deleteCustomer(id): Observable<any> {
        const url = apiUrl + 'admin/customers/' + id;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.delete(url, { withCredentials: true });
    }
    invalidateCustomerLastPeriod(idcustomer) {
      const url = apiUrl + 'admin/submissions/invalidate/last?customerId=' + idcustomer;
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.post(url, null, { withCredentials: true });
    }
    getCustomers() {
        const url = apiUrl + this.authenSer.getUserApiTargetUrl()+'/customers';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }
    getUserTenent(){
      const url = apiUrl + 'user/tenants';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }
    getAdminTenent(){
      const url = apiUrl + 'admin/tenants';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }
    getSitesByCustomerIdAdmin(id) {
        const url = apiUrl + 'admin/sites/customer/' + id;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }

    getAllSites() {
        const url = apiUrl + 'admin/sites';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }

    updateSite(site: Site, currentUser): Observable<any> {

      let subUrl;
      if(this.authenSer.isAdmin){
        subUrl = 'admin';
      } else {
        subUrl = 'user';
      }
        const url = apiUrl + subUrl + '/sites';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(url, site, { withCredentials: true });
    }

    getSiteById(siteId){
      let subUrl;
      if(this.authenSer.isAdmin){
        subUrl = 'admin';
      } else {
        subUrl = 'user';
      }
        const url = apiUrl + subUrl + '/sites/'+siteId;
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.get(url, { withCredentials: true });
    }

    getSitesByCustomerIdUser() {
        const url = apiUrl + 'user/sites';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, { withCredentials: true });
    }

    createSiteForCustomer(site: Site, currentUser): Observable<any> {
      // let subUrl = Role.valueOf(currentUser.role).value;
      let subUrl;
      if(this.authenSer.isAdmin){
        subUrl = 'admin';
      } else {
        subUrl = 'user';
      }
        const url = apiUrl + subUrl + '/sites';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(url, site, { withCredentials: true });
    }

    constructor(private http: HttpClient, private authenSer: AuthenticationService) { }
}
