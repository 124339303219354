import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { NgModel, FormControl } from "@angular/forms";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";

import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import * as moment from "moment";
import { VariablesPayeService } from "../services/variable-paye-service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertService } from "../services/alert-service.service";
import { FormChangeService } from "../services/form-change.service";
import { CustomerService } from "../services/customer.service";
import { AuthenticationService } from '../services/authentication.service';
import { TenantService } from '../services/tenant.service';
import { PayeSystem } from '../class/unit.enum';

@Component({
  selector: "app-variable-paye-create",
  templateUrl: "./variable-paye-create.component.html",
  styleUrls: ["./variable-paye.component.scss"],
})
export class VariablePayeCreateComponent implements OnInit {
  // [x: string]: any;
  // selectedCustomer;
  searchText;
  customers;
  customer = null;
  selectUnit: any;
  // variableFile;
  variable: any;
  isSaving = false;
  varPayeEditForm : FormGroup;
  // mandatory = false;
  model: NgModel;
  submitted = false;
  unit = [
    { id: 1, name: "heure" },
    { id: 2, name: "km" },
    { id: 3, name: "€" },
    { id: 4, name: "nb" },
    { id: 5, name: "jour" },
  ];
  selectedUnit;
  payeSystemEnum = PayeSystem;
  typeElement = [
    { id: "000", name: "0 : Nombre d'une rubrique" },
    { id: "001", name: "1 : base d'une rubrique" },
    { id: "002", name: "2 : Taux salarial d'une rubrique" },
    { id: "003", name: "3 : Montant salarial d'une rubrique" },
    { id: "004", name: "4 : Taux patronal d'une rubrique" },
    { id: "005", name: "5 : Montant patronal d'une rubrique" },
    { id: "006", name: "6 : Plafond d'une rubrique" },
    { id: "007", name: "7 : Plancher d'une rubrique" },
    { id: "008", name: "8 : Rapport B d'une rubrique" },
    { id: "009", name: "9: Rapport C d'une rubrique" },
    { id: "011", name: "11: Assiette de calcul d'une rubrique" },
    { id: "255", name: "255: Constante" },
  ];
  tenantPayeSystem: any;

  constructor(
    protected variablePayeService: VariablesPayeService,
    protected activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    protected alertService: AlertService,
    protected router: Router,
    protected formService: FormChangeService,
    protected referencielService: CustomerService,
    private tenantServ: TenantService,
    private authenSrv: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.tenantServ.getTenants().subscribe(
      (data)=>{
        this.tenantPayeSystem = data['systemPaie'];
        debugger;
        if(PayeSystem.SAGE.toString() == PayeSystem[this.tenantPayeSystem] ){
          this.varPayeEditForm.addControl("typeElementSage",new FormControl('', [Validators.required]));
        }
        this.rechercher();
      },
      (error)=>{
        console.log(error);
      });
  }
  initForm() {
    this.varPayeEditForm = this.fb.group({
      // selectedCustomer: [],
      id: [],
      // codePaie: ["", Validators.required],
      name: [],
      unit: ["", Validators.required],
      info: [],
      systemVariable: [false],
      mandatory: [false],
      date_creation: [],
      date_modification: [],
      customers: [],
      // typeElementSage: ["", Validators.required],//TODO:si sage
    });
  }
  get f() {
    return this.varPayeEditForm.controls;
  }

  rechercher() {
    let filter = this.getFilter();

    this.getCustomersFromBackEnd(filter).subscribe(
      (data) => {
        let tmp = data as Array<any>;
        this.customers = data;
        if (tmp) {
          if (filter) {
            tmp = this.findTextInCustomers(tmp, filter);
          }
        }
        this.customers = tmp;
      },
      (error) => {
        let msg = error["error"] ? error["error"]["error"] + ": " : error;
        this.alertService.error(msg);
        this.formService.raiseCustomerListEvent({});
      }
    );
  }

  getCustomersFromBackEnd(filter) {
    return this.referencielService.getCustomers();
  }
  close(): void {
    this.activeModal.close();
  }

  save(bool: boolean): void {

    // this.submitted = true;
    // if (!this.varPayeEditForm.invalid && !this.mandatory) {
    let formVarPaie = this.createFromForm();
    let custList = this.varPayeEditForm.get(["customers"])!.value;
    debugger;
    this.variablePayeService
      .postVariablePayeCreate(formVarPaie, custList)
      .subscribe(
        (data) => {
          this.alertService.success("Variable Sauvegardé !", false);
          this.formService.reloadVariablePaieSearchRequestEvent();
          this.close();
        },
        (error) => {
          this.alertService.error("Erreur dans le formulaire !", false);
        }
      );
  }

  private createFromForm() {
    // const typeElem = this.varPayeEditForm.get(["typeElementSage"])!.value;
    let tmp = {
      id: this.varPayeEditForm.get(["id"])!.value,
      // codePaie: this.varPayeEditForm.get(["codePaie"])!.value,
      name: this.varPayeEditForm.get(["name"])!.value,
      unit: this.varPayeEditForm.get(["unit"])!.value,
      info: this.varPayeEditForm.get(["info"])!.value,
      mandatory: this.varPayeEditForm.get(["mandatory"])!.value,
      systemVariable: this.varPayeEditForm.get(["systemVariable"]).value,
      // typeElementSage: typeElem.id,//TODO:si sage
    };
    let obligValue = this.varPayeEditForm.get(["mandatory"])!.value;
    if (obligValue) {
      tmp["defaultValue"] = this.varPayeEditForm.get(["defaultValue"])!.value;
    }
    let systemVariableValue = this.varPayeEditForm.get(["systemVariable"])!.value;
    if (systemVariableValue) {
      tmp["codePaie"] = this.varPayeEditForm.get(["codePaie"])!.value;
    }
    debugger;
    if(PayeSystem.SAGE.toString() == PayeSystem[this.tenantPayeSystem] ){
      const typeElem = this.varPayeEditForm.get(["typeElementSage"])!.value;
      tmp['typeElementSage']= typeElem.id;
    }
    return tmp;
  }
  isValid(f){
    return f.form.valid;
  }
  obligChanged() {
    let obligValue = this.varPayeEditForm.get(["mandatory"])!.value;
    if (obligValue) {
      this.varPayeEditForm.addControl("defaultValue",new FormControl('', [Validators.required]));

    } else {
      this.varPayeEditForm.removeControl("defaultValue");
    }
  }
  systemVarChanged() {
    let value = this.varPayeEditForm.get(["systemVariable"])!.value;
    if (value) {
      this.varPayeEditForm.addControl("codePaie",new FormControl('', [Validators.required]));

    } else {
      this.varPayeEditForm.removeControl("codePaie");
    }
  }
  private findTextInCustomers(customers, text) {
    text = text ? text.split(" ") : "";
    return customers.filter(function (item) {
      return text.every(function (el) {
        return (
          item["companyName"].toUpperCase().indexOf(el.toUpperCase()) > -1 ||
          item["siret"].toUpperCase().indexOf(el.toUpperCase()) > -1
        );
      });
    });
  }

  trackById(index: number, item: any): any {
    return item.id;
  }
  private getFilter() {
    let filter = this.searchText ? this.searchText : null;
    return filter;
  }
}
