import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl
} from "@angular/forms";
import { EmployeeService } from "../services/employee.service";
import { HttpResponse } from "@angular/common/http";
import { FormChangeService } from "../services/form-change.service";
import { AlertService } from "../services/alert-service.service";
import * as moment from "moment";
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "../services/customer.service";
import { Router } from '@angular/router';
import { PrettyprintPipe } from '../prettyprint.pipe';
import { VariablesPayeService } from '../services/variable-paye-service';

@Component({
  selector: "app-customer",
  templateUrl: "./customers-update.component.html"
  //  styleUrls: ['./employees-update.component.scss']
})
export class CustomersUpdateComponent implements OnInit {
  customerUpdateForm: FormGroup;
  customer;
  customerUpdate: any;
  emailInvalid: boolean;
  valeurAcorriger = [];
  submitted = false;
  connectionTestOk;
  testOngoing = false;
  prettyprint: PrettyprintPipe;
  testStated = false;
  testBtnDisable= false;
  calculeModes = [
    { id: 1, code: "OUVRE", name: "Jours ouvrés" },
    { id: 2, code: "OUVRABLE", name: "Jours ouvrables" }
  ];
  connectionRdrTestOk;
  testRdrDisable = false;
  testRdrStated = false;
  testRdrOngoing = false;
  variablesPaie;
  constructor(
    protected customerService: CustomerService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    protected formService: FormChangeService,
    private variableService: VariablesPayeService,
    private route: Router) {
      this.customer = this.route.getCurrentNavigation().extras.state?this.route.getCurrentNavigation().extras.state.customer:null;
  }
  public preselectedVariables() {
    const selected = this.customer.variablePaies.map(item => item.id);
    this.customerUpdateForm.get('variablePaies').patchValue(selected);
  }
  ngOnInit(): void {

    this.initForm();
    this.updateForm(this.customer);
    this.variableService.getVariablePaye().subscribe(
      (data)=>{
        this.variablesPaie = data;
        this.preselectedVariables();
      },
      (error)=>{
        console.log(error);
      }
    );
    this.onBddNameChange();
    this.onReaderIdChange();
  }

  initForm() {
    this.customerUpdateForm = this.fb.group({
      activity: [],
      adress: ["", Validators.required],
      city: ["", Validators.required],
      companyName: ["", Validators.required],
      workPublicHoliday: [],
      calculModeHoliday: ["", Validators.required],
      cp: ["", Validators.required],
      email: [
        "",
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")
      ],
      siret: ["", Validators.required],
      systemPaieBddName: [],
      tel1: [],
      tel2: [],
      contactName: [],
      contactPosition: [],
      readerId: [],
      absenceControl: [],
      variablePaies: [],
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.customerUpdateForm.controls;
  }

  updateForm(customer: any): void {

    this.customerUpdateForm.patchValue({
      activity: customer.activity,
      adress: customer.adress,
      city: customer.city,
      companyName: customer.companyName,
      workPublicHoliday: customer.workPublicHoliday,
      calculModeHoliday: customer.calculModeHoliday,
      cp: customer.cp,
      email: customer.email,
      siret: customer.siret,
      systemPaieBddName: customer.systemPaieBddName,
      tel1: customer.tel1,
      tel2: customer.tel2,
      contactName: customer.contactName,
      contactPosition: customer.contactPosition,
      readerId: customer.readerId,
      absenceControl: customer.absenceControl,
      variablePaies: customer.variablePaies,
    });
    debugger;
    this.testBtnDisable = customer.systemPaieBddName == null;
  }

  private createFromForm(): any {
    return {
      activity: this.customerUpdateForm.get(["activity"])!.value,
      adress: this.customerUpdateForm.get(["adress"])!.value,
      city: this.customerUpdateForm.get(["city"])!.value,
      companyName: this.customerUpdateForm.get(["companyName"])!.value,
      workPublicHoliday: this.customerUpdateForm.get(["workPublicHoliday"])!
        .value,
      calculModeHoliday: this.customerUpdateForm.get(["calculModeHoliday"])!
        .value,
      cp: this.customerUpdateForm.get(["cp"])!.value,
      email: this.customerUpdateForm.get(["email"])!.value,
      siret: this.customerUpdateForm.get(["siret"])!.value,
      systemPaieBddName: this.customerUpdateForm.get(["systemPaieBddName"])!.value,
      tel1: this.customerUpdateForm.get(["tel1"])!.value,
      tel2: this.customerUpdateForm.get(["tel2"])!.value,
      contactName: this.customerUpdateForm.get(['contactName'])!.value,
      contactPosition: this.customerUpdateForm.get(['contactPosition'])!.value,
      id: this.customer.id,
      readerId: this.customerUpdateForm.get(['readerId'])!.value,
      absenceControl: this.customerUpdateForm.get(['absenceControl'])!.value,
    };
  }
checkbddConnection(){
  this.testStated = true;
  this.testOngoing = true;
  let bddName= this.customerUpdateForm.get(["systemPaieBddName"])!.value;
  this.customerService.checkdbbConnection(bddName).subscribe(
    data =>{
      if(data){
        this.connectionTestOk = true;
      } else {
        this.connectionTestOk = false;
      }

      this.testOngoing = false;
    },
    error =>{
      this.connectionTestOk = false;
      this.testOngoing = false;
    }
  );
}

checksCoherence(){

}
  update() {
      this.customerUpdate = this.createFromForm();
      let variablePaies = this.customerUpdateForm.get(['variablePaies'])!.value;
      this.customerService.updateCustomer(this.customerUpdate, variablePaies).subscribe(
        data => {
          this.alertService.success("Modification effectuée.", true);
          this.route.navigateByUrl('/customers');

        },
        () => {
          this.alertService.warn("Erreur de modification.", true);
        }
      );
    }

  onBddNameChange(){
    let val =this.customerUpdateForm.get(["systemPaieBddName"])!.value;

    if(val != null){
      this.testBtnDisable = false;
    } else {
      this.testBtnDisable = true;
    }
    this.testStated = false;
    this.testOngoing = false;

  }
  istestBtnDisable(){
    return this.testBtnDisable;
  }
  onReaderIdChange(){

    let val =this.customerUpdateForm.get(["readerId"])!.value;

    if(val != null){
      this.testRdrDisable = false;
    } else {
      this.testRdrDisable = true;
    }
    this.testRdrStated = false;
    this.testRdrOngoing = false;

  }
  checkRdConnection(){//TODO: endpoint à adapter
    this.testRdrStated = true;
    this.testRdrOngoing = true;
    let bddName= this.customerUpdateForm.get(["systemPaieBddName"])!.value;
    this.customerService.checkdbbConnection(bddName).subscribe(
      data =>{
        if(data){
          this.connectionRdrTestOk = true;
        } else {
          this.connectionRdrTestOk = false;
        }

        this.testOngoing = false;
      },
      error =>{
        this.connectionRdrTestOk = false;
        this.testOngoing = false;
      }
    );
  }
  istestRdrDisable(){
    return this.testRdrDisable;
  }
}
