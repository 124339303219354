import { Component, OnInit, Inject, LOCALE_ID, Output, EventEmitter, Input } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { FormChangeService } from '../services/form-change.service';
import { EmployeeService } from '../services/employee.service';
import { AlertService } from '../services/alert-service.service';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../services/authentication.service';
import { VariablesAbsenceService } from '../services/variable-absence-service';

@Component({
  selector: 'app-form-min-absences',
  templateUrl: './form-min-absences.component.html',
  styleUrls: ['./form-min-absences.component.scss']
})
export class FormMinAbsencesComponent implements OnInit {
  lstTypeAbsence = [
    { id: 1, name: 'Congés Payés' },
    { id: 2, name: 'Arrêt maladie' },
    { id: 3, name: 'RTT' },
    { id: 4, name: 'Congé sans Solde' },
    { id: 5, name: 'Autre' }];
  selectedType;
  selectedSite;
  selectAll: boolean;
  searchText: string;
  model: NgModel;
  // sites = [{ id: 1, name: 'Congés Payés' },
  // { id: 2, name: 'Arrêt maladie' }];
  types: {};
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  constructor(
    private alertService: AlertService,
    private employeeService: EmployeeService,
    private variableAbsenceService: VariablesAbsenceService,
    private authenSer: AuthenticationService,
    private formService: FormChangeService,
    public router: Router,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }
  ngOnInit() {

    this.rechercher();
    this.subscribeSearchRequestEvent();
  }

  onChange() {
    // this.rechercher();
  }
  clearForm() {
    this.rechercher();
  }


  rechercher() {
    let filter = this.getFilter();

    this.callBackend()
      .subscribe(
        data => {

          let tmp = data as Array<any>;
          if (tmp) {
            if (filter) {

                tmp = this.findTextInPlaces(tmp, filter);
            }
          }
          if (this.selectedSite && this.selectedSite.length > 0) {
            tmp = tmp.filter(el => {
              console.log(this.selectedSite);
              return this.selectedSite.find(siteId => siteId === el.siteId);
            });
          }
          this.formService.raiseAbsencesListEvent(tmp);
        }
        , error => {
          console.log(error);
          this.formService.raiseAbsencesListEvent({});

        });



  }


  subscribeSearchRequestEvent() {
    this.formService.reloadAbsencesEvent.subscribe(
      param => {

        this.rechercher();
      }
    )
  }
  callBackend() {

    return this.employeeService.getMyEmployeesByFilter(this.authenSer.getUserLoggedIn());
  }
  getTypeFromBackEnd() {
    let currentUser = this.authenSer.getUserLoggedIn();
    return this.variableAbsenceService.getVariableType(currentUser);
  }
  getSiteFromBackend() {
    let currentUser = this.authenSer.getUserLoggedIn();
    return this.employeeService.getSites(currentUser);
  }

  private findTextInPlaces(employees, text) {
    let texts = (text != null ? text.split(' ') :'');
    return employees.filter(function (item) {

      return texts.every(function (el) {
        return item['name'].toUpperCase().indexOf(el.toUpperCase()) > -1
          || item['firstname'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }
  private getFilter() {

    let filter = this.searchText ? this.searchText : null;
    return filter;
  }

}
