import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VariablePayeUpdateComponent } from '../variable-paye/variable-paye-update.component';
import { VariablePayeCreateComponent } from '../variable-paye/variable-paye-create.component';
import { AlertService } from '../services/alert-service.service';
import { VariablesPayeService } from '../services/variable-paye-service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-variable-min',
  templateUrl: './variable-min.component.html',
  styleUrls: ['./variable-min.component.scss']
})
export class VariableMinComponent implements OnInit {


  constructor(protected modalService: NgbModal, protected alertService: AlertService,
    protected variablesPayeService: VariablesPayeService, ) { }

  @Input() variablesPayes: any;

  ngOnInit() {
  }

}
