
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

// const SERVERLOCAL = 'http://dev.devarchi.fr:8445/';
// const SERVERPROD = 'http://paie.devarchi.fr:8445/';
const apiUrl = environment.serverUrl + 'api/';

@Injectable({
  providedIn: "root"
})



export class MessagesService {
  constructor(private http: HttpClient) {
    this.checkAdminMessages = new EventEmitter<Array<any>>();
  }
  checkAdminMessages: EventEmitter<Array<any>>;

  raiseAdminMessagesEvent(): void {
  }
  saveAdminMessages(map) {
    let subUrl = "?customerIds=" + map['customerIds'][0];
    for (let i = 1; i < map['customerIds'].length; i++) {
      subUrl += '&customerIds=' + map['customerIds'][i];
    }
    subUrl += '&message=' + map['message'];
    const url = apiUrl + "admin/messages" + subUrl;
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post(url, null, { headers, withCredentials: true });
  };

  // getAdminMessagesEvent() {

  //   return this.checkAdminMessages;
  // }

  getAdminMessages() {
    const url = apiUrl + "user/messages";
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get(url, {
      withCredentials: true
    });

  }

  acknowledgeMessage(messageId) {
    const url = apiUrl + "user/messages/acknowledge?messageId=" + messageId;
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post(url, null, { headers, withCredentials: true });
  }
}
