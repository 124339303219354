import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ProspectService } from 'src/app/services/prospect.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from 'src/app/services/form-change.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MomentPipe } from 'src/app/moment-pipe.pipe';
import { soncas } from 'src/app/class/unit.enum';
import { prospectStatus } from 'src/app/class/unit.enum';

@Component({
  selector: 'app-prospect-update',
  templateUrl: './prospect-update.component.html',
  styleUrls: ['./prospect-update.component.scss']
})
export class ProspectUpdateComponent implements OnInit, OnDestroy {
  timerKeepAlive:any;
  employeesCreateForm: FormGroup;
  soncasEnum = soncas;
  statusEnum = prospectStatus;
  keys = Object.keys;
  selectedSite: string = null;
  selectedCustomer: string = null;
  selectedCivilite: string = null;
  prospectToSave: any;
  emailInvalid: boolean;
  firstContact;
  lastContact;
  typeContrat = [
    { id: 1, code: "TEL", name: "Tel" },
    { id: 2, code: "MAIL", name: "Email" },
  ];
  prospect : any;
  constructor(
    protected employeeService: ProspectService,
    protected customerService: CustomerService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private formService: FormChangeService,
    private route: Router,
    private momentPipe: MomentPipe
  ) {
    this.prospect = this.route.getCurrentNavigation().extras.state?this.route.getCurrentNavigation().extras.state.prospect:null;
  }
  ngOnDestroy(): void {
    clearInterval(this.timerKeepAlive);
  }

  ngOnInit(): void {
    this.initForm();
    if(this.prospect){
      this.updateForm(this.prospect);
    }
    this.keepSessionAlive();
  }
  keepSessionAlive() {
    this.timerKeepAlive = setInterval(()=>{this.authenSer.keepAlive().subscribe(
      (data) =>{
        console.log('session keepAlive');
      },
      (error) => {
        console.log(error);
      }
    );
    }
    , 120000);
    }
  updateForm(variable: any): void {
    this.employeesCreateForm.patchValue({
      id : variable.id,
      adress: variable.adress,
      companyName: variable.companyName,
      firstContact: moment(variable.firstContact).format('YYYY-MM-DD'),
      modeFirstContact: variable.modeFirstContact,
      lastContact: moment(variable.lastContact).format('YYYY-MM-DD'),
      nextContact: moment(variable.nextContact).format('YYYY-MM-DD'),
      modeLastContact: variable.modeLastContact,
      contactMail: variable.contactMail,
      contactTel: variable.contactTel,
      note: variable.note,
      soncas: variable.soncas,
      status: variable.status,
      contactName: variable.contactName,
    });

  }
  initForm() {
    this.employeesCreateForm = this.fb.group({
      id: new FormControl(""),
      adress: new FormControl(""),
      companyName: new FormControl(""),
      firstContact: new FormControl(""),
      modeFirstContact: new FormControl(""),
      lastContact: new FormControl(""),
      nextContact: new FormControl(""),
      soncas: new FormControl(""),
      status: new FormControl(""),
      modeLastContact: new FormControl(""),
      contactMail: new FormControl(""),
      contactTel: new FormControl(""),
      note: [],
      contactName: new FormControl(""),
    });

  }
  firstContactChange(){

    let firstContact = this.employeesCreateForm.get(["firstContact"])!.value;
    if(firstContact){
      this.employeesCreateForm['modeFirstContact'] = new FormControl("");
    } else {
      this.employeesCreateForm['modeFirstContact'] = [''];
    }
  }

  lastContactChange(){
    let firstContact = this.employeesCreateForm.get(["lastContact"])!.value;
    if(firstContact){
      this.employeesCreateForm['modeLastContact'] = new FormControl("");
    } else {
      this.employeesCreateForm['modeLastContact'] = [''];
    }
  }

  private createFromForm(): any {
    let lastContactDate = this.momentPipe.transform(this.employeesCreateForm.get(["lastContact"])!.value,'YYYY-MM-DD');
    let firstContactDate = this.momentPipe.transform(this.employeesCreateForm.get(["firstContact"])!.value,'YYYY-MM-DD');
    let nextContactDate = this.momentPipe.transform(this.employeesCreateForm.get(["nextContact"])!.value,'YYYY-MM-DD');
    let tmp = {
      id: this.prospect.id,
      adress: this.employeesCreateForm.get(["adress"])!.value,
      firstContact: firstContactDate ? firstContactDate : null,
      modeFirstContact:this.employeesCreateForm.get(["modeFirstContact"]).value?this.employeesCreateForm.get(["modeFirstContact"]).value:null,
      lastContact: lastContactDate ? lastContactDate : null,
      nextContact: nextContactDate ? nextContactDate: null,
      soncas: this.employeesCreateForm.get(["soncas"]).value? this.employeesCreateForm.get(["soncas"]).value: null,
      status: this.employeesCreateForm.get(["status"]).value ? this.employeesCreateForm.get(["status"]).value: null,
      modeLastContact: this.employeesCreateForm.get(["modeLastContact"]).value?this.employeesCreateForm.get(["modeLastContact"]).value:null,
      companyName: this.employeesCreateForm.get(["companyName"])!.value,
      contactMail: this.employeesCreateForm.get(["contactMail"])!.value,
      contactTel: this.employeesCreateForm.get(["contactTel"])!.value,
      note: this.employeesCreateForm.get(["note"])!.value,
      contactName: this.employeesCreateForm.get(["contactName"])!.value,
    };

    return tmp;
  }
  onChangeCivilite(value): void {
    this.selectedCivilite = value;
  }
  save(): void {
    this.prospectToSave = this.createFromForm();
    debugger;
    this.employeeService
      .putProspects(this.prospectToSave)
      .subscribe(
        (data) => {
          this.alertService.success("Prospect Sauvegardé !", true);
          this.formService.raiseReloadProspectSearchRequestEvent();
          this.route.navigateByUrl("/prospects");
        },
        (error) => {
          this.alertService.error("Erreur dans le formulaire !", true);
        }
      );
  }


}
