import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { TemplatePortal } from "@angular/cdk/portal";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { fromEvent, Subscription } from "rxjs";
import { take, filter } from "rxjs/operators";
import { Router } from "@angular/router";
import { FormChangeService } from "../services/form-change.service";
import { AlertService } from "../services/alert-service.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EmployeesCreateComponent } from "./employees-create/employees-create.component";
import { EmployeesUpdateComponent } from "./employees-update/employees-update.component";
import { EmployeeService } from "../services/employee.service";
import { AuthenticationService } from "../services/authentication.service";
import { EmployeesImportComponent } from "./employees-import/employees-import.component";
import { ModalRASComponent } from "../modal/modalRAS.component";
import {
  trigger,
  transition,
  state,
  style,
  animate,
} from "@angular/animations";
import * as moment from "moment";
import { AccessMode, Civilitee } from "../class/unit.enum";
import { EmployeeDeletionComponent } from "./employee-deletion/employee-deletion.component";
import { Modal2Component } from "../modal/modal2.component";
import { SubmissionService } from "../services/submission.service";
import { SubmissionAbsenceService } from "../services/submission-absence.service";
import { AbsencesCreateComponent } from "../absences/absences-create/absences-create.component";
import { AbsencesUpdateComponent } from "../absences/absences-update/absences-update.component";
import { SaisiesUpdateComponent } from "../saisies/saisies-update.component";

@Component({
  selector: "app-administration",
  templateUrl: "./employees.component.html",
  styleUrls: ["./employees.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0px" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class EmployeesComponent implements OnInit {
  lstEmployees = [];
  filter: any;
  moment = moment;
  civiliteeEnum = Civilitee;
  sub: Subscription;
  displayedColumns ;
  innerDisplayedColumns = [
    "CP acquis",
    "CP restants",
    "Salaire_Brut",
    "tel2",
    "numSs",
    "adress",
  ];
  expandedElement: {} | null;

  @ViewChild("employeeMenu", { static: false }) employeeMenu: TemplateRef<any>;

  overlayRef: OverlayRef | null;

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private alertService: AlertService,
    private formService: FormChangeService,
    private employeeService: EmployeeService,
    private authenSer: AuthenticationService,
    public router: Router,
    private submissionDataPaieService: SubmissionService,
    private submissionAbsenceService: SubmissionAbsenceService,
    protected modalService: NgbModal,
    private submissionService: SubmissionService,
    public activeModal: NgbActiveModal
  ) {}

  open({ x, y }: MouseEvent, user) {
    this.close();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top",
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(
      new TemplatePortal(this.employeeMenu, this.viewContainerRef, {
        $implicit: user,
      })
    );

    this.sub = fromEvent<MouseEvent>(document, "click")
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this.overlayRef &&
            !this.overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
  saisie(user) {
    this.close();
    this.router.navigateByUrl("PayeVar");
  }
  ngOnInit() {
    this.displayedColumns = [
      "expandIcon",
      "Nom/Prenom",

    ];
    if(this.authenSer.isAdmin){
      this.displayedColumns.push('Client')
    }
    this.displayedColumns.push("Matricule",
    "Contrat",
    "Date d'entrée",
    "tel1",
    "Actions");
    this.subscribeformChange();
  }
  subscribeformChange() {

    this.formService.formEmployeeSearchChanged.subscribe((param) => {
      this.lstEmployees = param;
      debugger;
    });
  }

  create(): void {
    this.router.navigateByUrl("/app-employee");
  }
  import(customer) {
    const modalRef = this.modalService.open(EmployeesImportComponent, {
      backdrop: "static",
    });
    modalRef.componentInstance.customers = customer;

    modalRef.result.then((result) => {});
  }

  hasDataAbsence(employee: any, selectedPeriod) {
    let empHasDataPaie = false;
    let dataabsence = employee.dataAbsences as Array<any>;
    if (dataabsence) {
      empHasDataPaie =
        dataabsence.filter((el) => {
          let tmp =
            el.period == selectedPeriod ;
          return tmp;
        }).length > 0;
    }
    return empHasDataPaie;
  }
  hasDataAbsenceRAS(employee: any,selectedPeriod) {
    let empHasDataPaie = false;
    let dataabsence = employee.dataAbsences as Array<any>;
    if (dataabsence) {
      empHasDataPaie =
        dataabsence.filter((el) => {
          let tmp =
            el.period == selectedPeriod && el.noReport ;
          return tmp;
        }).length > 0;
    }
    return empHasDataPaie;
  }
  hasDataPaie(employee: any, selectedPeriod) {
    let empHasDataPaie = false;
    let datapaie = employee.dataPaies as Array<any>;
    if (datapaie) {
      empHasDataPaie =
        datapaie.filter((el) => {
          return el.period == selectedPeriod ;
        }).length > 0;
    }
    return empHasDataPaie;
  }
  hasDataPaieRAS(employee: any, selectedPeriod) {
      let empHasDataPaie = false;
      let datapaie = employee.dataPaies as Array<any>;
      if (datapaie) {
        empHasDataPaie =
          datapaie.filter((el) => {
            return el.period == selectedPeriod && el.noReport;
          }).length > 0;
      }
      return empHasDataPaie;
  }

  openDeleteConfirm(employee) {
    this.submissionService
      .getPeriod(this.authenSer.getUserLoggedIn())
      .subscribe((param) => {
        if (param) {
          const selectedPeriod = param;
          const hasDataPaie = this.hasDataPaie(employee, selectedPeriod);
          const hasDataPaieRas = this.hasDataPaieRAS(employee, selectedPeriod);
          const hasDataAbsence = this.hasDataAbsence(employee, selectedPeriod);
          const hasDataAbsenceRas = this.hasDataAbsenceRAS(employee, selectedPeriod);

          if (hasDataAbsence && !hasDataAbsenceRas ) {
            //updateDataAbsence
            const modalAbsences = this.modalService.open(
              AbsencesUpdateComponent,
              {
                backdrop: "static", keyboard:false
              }
            );

            modalAbsences.componentInstance.title =
            "<h6 class=\"modal-title-orange\"><p>Solde de tout compte : 1/3</p></h6>" +
            "<p>Saisie des absences pour " +
            employee.firstname +
            "  " +
            employee.name + '</p>';
            modalAbsences.componentInstance.employee = employee;
            modalAbsences.componentInstance.period = selectedPeriod;
            modalAbsences.componentInstance.soldCompte = true;
            modalAbsences.componentInstance.withAddBtn = true;
            modalAbsences.componentInstance.showDelete = true;
            modalAbsences.componentInstance.checkRas = hasDataAbsenceRas;
            debugger;
            modalAbsences.result.then((result) => {
              let lstDataAbsences = result;
              debugger;
              if(result){
              if (result != 'RAS') {
                if( lstDataAbsences instanceof Array){
                // lstDataAbsences.forEach((abs) => {
                  debugger;
                  this.submissionAbsenceService.putDataAbsences(lstDataAbsences).subscribe(
                    (data: any) => {
                      this.formService.raiseReloadSaisiesRequestEvent();
                      this.soldFlowStep2(employee,hasDataPaie,hasDataPaieRas,selectedPeriod);
                    },
                    (error) => {
                      this.alertService.error("Erreur lors de la validation de DataAbsence");
                      console.log(error);
                    }
                  );
                // });
              }
            } else {//RAS choisi => dans ce cas le post est deja fait
              // => Saisie DataPaie
              this.formService.raiseReloadSaisiesRequestEvent();
              this.soldFlowStep2(employee,hasDataPaie, hasDataPaieRas,selectedPeriod);
          }
        // }
      } else {
        //canceled
      }
    });
          } else {
            ////createDataAbsence
            const modalCreateAbsence = this.modalService.open(AbsencesCreateComponent, {
              backdrop: "static", keyboard:false
            });

            modalCreateAbsence.componentInstance.title =
            "<p>Solde de tout compte : 1/3</p>" +
            "<p>Saisie des absences pour " +
            employee.firstname +
            "  " +
            employee.name + '</p>';
            modalCreateAbsence.componentInstance.employee = employee;
            modalCreateAbsence.componentInstance.period = selectedPeriod;
            modalCreateAbsence.componentInstance.soldCompte = true;
            modalCreateAbsence.componentInstance.checkRas = hasDataAbsenceRas;
            modalCreateAbsence.result.then((result) => {
              let lstDataAbsences = result as Array<any>;
              debugger;
              if(result){
              if (result != 'RAS') {
                if(lstDataAbsences instanceof Array){
                this.submissionAbsenceService
                  .postDataAbsence(lstDataAbsences)
                  .subscribe(
                    (data: any) => {
                      this.formService.raiseReloadSaisiesRequestEvent();
                      // => Saisie DataPaie
                      this.soldFlowStep2(employee,hasDataPaie,hasDataPaieRas,selectedPeriod);
                    },
                    (error) => {
                      console.log(error);
                      this.alertService.error("Erreur lors de la validation de DataAbsence");

                    }
                  );
                }
              } else {//RAS choisi => dans ce cas le post est deja fait
                this.formService.raiseReloadSaisiesRequestEvent();
                // => Saisie DataPaie
                this.soldFlowStep2(employee,hasDataPaie,hasDataPaieRas,selectedPeriod);
              }
           } else {
              //canceled
            }
            });
          }
        }
      });
  }
soldFlowStep2(employee,hasDataPaie, hasDataPaieRAS,selectedPeriod){
  //Saisie dataPaie
  if (hasDataPaie && !hasDataPaieRAS) {
    //updateDataPaie
    const modalDataPaie = this.modalService.open(
      SaisiesUpdateComponent,
      {
        backdrop: "static", keyboard: false
      }
    );
    modalDataPaie.componentInstance.title =
      "<p>Solde de tout compte : 2/3 </p>" +
      "<p>Saisie des variables de paie pour " +
      employee.firstname +
      "  " +
      employee.name + "</p>";
    modalDataPaie.componentInstance.employee = employee;
    modalDataPaie.componentInstance.period = selectedPeriod;
    modalDataPaie.componentInstance.soldCompte = true;
    modalDataPaie.result.then((result) => {
      debugger;
      if (result) {
        let data = result ;//as Object;
        if(data != 'RAS'){
        this.submissionDataPaieService
          .putDataPaie(result)
          .subscribe(
            (data: any) => {
              // => Solde de Compte
              this.soldCompte(employee);
            },
            (error) => {
              console.log(error);
              this.alertService.error("Erreur lors de la validation de DataPaie");
            }
          );
        } else{//RAS choisi => dans ce cas le post est deja fait
          // => Solde de Compte
          this.soldCompte(employee);
        }
      } else  {
        //canceled
      }
    });
  } else {
    //createDataPaie
    const modalCreateDataPaie = this.modalService.open(
      Modal2Component,
      {
        backdrop: "static", keyboard: false,
      }
    );
    modalCreateDataPaie.componentInstance.title =
      "<p>Solde de tout compte : 2/3 </p>" +
      "<p>Saisie des variables de paie pour " +
      employee.firstname +
      "  " +
      employee.name + "</p>";
    modalCreateDataPaie.componentInstance.employee = employee;
    modalCreateDataPaie.componentInstance.soldCompte = true;
    modalCreateDataPaie.componentInstance.checkRas = hasDataPaieRAS;
    modalCreateDataPaie.componentInstance.selectedPeriod = selectedPeriod;
    modalCreateDataPaie.result.then((result) => {
      let data = result ;//as Object;
      debugger;
      if (result) {
        if(data != 'RAS'){
        this.submissionDataPaieService
          .postDataPaie(result)
          .subscribe(
            (data: any) => {
              // => Solde de Compte
              this.soldCompte(employee);
            },
            (error) => {
              console.log(error);
              this.alertService.error("Erreur lors de la validation du solde de tout compte");
            }
          );
      } else{//RAS choisi => dans ce cas le post est deja fait
        // => Solde de Compte
        this.soldCompte(employee);

      }
    } else {
      //canceled
    }
    });
  }
}
  soldCompte(employee) {
    const modalRef = this.modalService.open(EmployeeDeletionComponent, {
      backdrop: "static", keyboard: false
    });
    modalRef.componentInstance.employee = employee;
    modalRef.result.then((result) => {
      if (result == true) {

        this.alertService.success(
          employee.firstname +
            "  " +
            employee.name +
            ": solde de tout compte effectué !"
        );
      }
    });
  }
  openTechnocalDeleteConfirm(employee){
    const modalRefere = this.modalService.open(ModalRASComponent, {
      backdrop: "static", keyboard: false
    });
    modalRefere.componentInstance.title =
      "Suppression de collaborateur " + employee.firstname + "  " + employee.name;
    modalRefere.componentInstance.message = "Confirmez la suppression ?";
    modalRefere.componentInstance.withCancel = true;
    modalRefere.result.then((result) => {
      debugger;
      if (result != false) {
        this.deleteEmployee(employee.id);
        };
    })
    .catch((error)=>{
      console.log(error);
    }
    );
  }

  deleteEmployee(idEmployee) {
    this.employeeService
      .deleteEmployee(idEmployee)
      .subscribe(
        (param) => {
          this.alertService.success("Collaborateur supprimé !");
          this.formService.raiseFormEmployeeSearchRequest();
        },
        (error) => {
          this.alertService.error("erreur lors de la  suppression !");
        }
      );
  }
  editEmployee(employee) {
    this.router.navigateByUrl("/update-employees", {
      state: { employee: employee },
    });
  }
  detailEmployee(employee) {
    this.router.navigateByUrl("/detail-employees", {
      state: { employee: employee },
    });
  }
}
