import { Component, OnInit, OnDestroy } from "@angular/core";
import { AlertService } from "../services/alert-service.service";
import { FormChangeService } from "../services/form-change.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";
import { VariablesPayeService } from "../services/variable-paye-service";
import { SubmissionService } from "../services/submission.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SubmissionAbsenceService } from "../services/submission-absence.service";
import { SaisiesPeriodComponent } from "../saisies/saisies-period.component";
import { formatDate } from "@angular/common";
import { DatapaieService } from "../services/datapaie.service";
import { debug } from "util";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
  CheckboxRequiredValidator,
} from "@angular/forms";
import { Civilitee } from "../class/unit.enum";
import * as moment from 'moment';
import { MomentPipe } from '../moment-pipe.pipe';

@Component({
  selector: "app-add-data-paies",
  templateUrl: "./add-data-paies.component.html",
  styleUrls: ["./add-data-paies.component.scss"],
})
export class AddDataPaiesComponent implements OnInit, OnDestroy {
  timerKeepAlive:any;
  lstEmployees = [];
  listVars = [];
  lstSubmission = [];
  counter = 0;
  filter: any;
  selectedPeriod: any;
  // selectedPeriodasText;
  periodNew: any[];
  period: any;
  selectedDate: string;
  comment: string;
  model = {};
  employeeAbsForm: FormGroup;
  displayedColumns = ["Nom/Prenom", "AllDataRas"];
  chkBoxAbsenceRas = new Array<any>();
  chkBoxDataPayeRas = new Array<any>();
  civiliteeEnum = Civilitee;
  constructor(
    private alertService: AlertService,
    private formService: FormChangeService,
    private fb: FormBuilder,
    public router: Router,
    private authenSer: AuthenticationService,
    public variablePaieService: VariablesPayeService,
    private submissionService: SubmissionService,
    private modalService: NgbModal,
    private paieVarService: VariablesPayeService,
    public momentPipe: MomentPipe

  ) {}
  ngOnDestroy(): void {
    clearInterval(this.timerKeepAlive);
  }

  ngOnInit() {
    this.getPeriod();
    this.subscribeFormEmployeeSearch();
    this.keepSessionAlive();
  }
  keepSessionAlive() {
    this.timerKeepAlive = setTimeout(()=>{this.authenSer.keepAlive().subscribe(
      (data) =>{
        console.log('session keepAlive');
      },
      (error) => {
        console.log(error);
      }
    );
    }
    , 120000);
    }

  subscribeFormEmployeeSearch() {
    this.formService.formAddDataPaieListChanged.subscribe((param) => {
      let employees = param;
      this.model = {};
      this.paieVarService
        .getVariablePaye()
        .subscribe(
          (data) => {
            this.listVars = data as Array<any>;
            this.listVars.forEach((variable) => {
              if (!this.displayedColumns.includes(variable.id)) {
                this.displayedColumns.push(variable.id);
              }
            });
            if (!this.displayedColumns.includes("comment")) {
              this.displayedColumns.push("comment");
            }
            // this.model["AllDataRas"] = false;
            // let employeeDataPaieByPeriod: any;
            let counterRasEmployee = 0;
            employees.forEach((emp) => {
              let datapaie = emp.dataPaies as Array<any>;
              //initialiser les dataPaie employe si rien est saisie pour lui
              if (!emp.dataPaies) {
                datapaie = new Array<any>();
              }
              //ajouter la dataPaie pour l'employee dans le model si elle y pas encore
              if (!this.model[emp.id]) {
                this.model[emp.id] = { dataPaies: {}, comment: {}, ras: false };
              }
              let empDataPaie;
              if (datapaie) {
                empDataPaie = datapaie.filter((el) => {
                  return el.period == this.selectedPeriod;
                });
              }

              let counterRas = 0;
              this.listVars.forEach((variable) => {
                let hasVar =
                  empDataPaie.filter((empData) => {
                    let dataPaieExists = empData.variableId === variable.id;
                    if (dataPaieExists) {
                      // empData["defaultValue"] = variable.defaultValue;
                      // empData["mandatory"] = variable.mandatory;
                      // empData["unit"] = variable.unit;
                      // empData["period"] = this.selectedPeriod;
                      // empData["value"] = empData.value;
                      // if (empData.noReport == true) {
                      //   counterRas++;
                      // }
                    }

                    return dataPaieExists;
                  }).length > 0;
                //
                if (!hasVar) {
                  let tmp = {
                    variableId: variable.id,
                    value: "",
                    defaultValue: variable.defaultValue,
                    mandatory: variable.mandatory,
                    unit: variable.unit,
                    // name: variable.name,
                    employeeId: emp.id,
                    period: this.selectedPeriod,
                  };
                  empDataPaie.push(tmp);
                }
              });
              let fakeDataPaie = empDataPaie.filter((empData) => {
                  return empData.noReport == true;
                }
              );
              if(fakeDataPaie){
                counterRas = fakeDataPaie.length;
              }
              this.model[emp.id]["dataPaieRas"] = counterRas > 0;
              if (this.model[emp.id]["dataPaieRas"]) {
                counterRasEmployee++;
              }
              empDataPaie.forEach((empData) => {
                let val = empData.value ? empData.value : empData.defaultValue;
                let dataform = {};
                dataform[empData.variableId] = val;
                let dataPaies = this.model[emp.id].dataPaies
                  ? this.model[emp.id].dataPaies
                  : new Object();

                dataPaies[empData.variableId] = empData;

                this.model[emp.id].dataPaies = dataPaies;
                // this.model[emp.id]["dataPaieRas"] = false;//this.counterRas == this.listVars.length);
              });
              emp.dataPaies = empDataPaie;
              // emp['dataPaieRas'] = false;
            });
            this.lstEmployees = employees;

            this.model["AllDataRas"] =
              counterRasEmployee == this.lstEmployees.length;
          },
          (error) => {
            console.log(error);
          }
        );
    });
  }
  protected getPeriod() {
    this.submissionService
      .getPeriod(this.authenSer.getUserLoggedIn())
      .subscribe(
        (param) => {
          if (param) {
            this.selectedPeriod = param;
          } else {
            let dateNow = new Date();
            let dateAvant = new Date();
            dateAvant.setMonth(dateNow.getMonth() - 1);
            let periodNew = [
              {
                id: "n",
                name: "Mois en cours : " + formatDate(dateNow, "MMMM y", "fr-FR"),
                date: dateNow,
              },
              {
                id: "a",
                name:
                  "Mois précédent : " + formatDate(dateAvant, "MMMM y", "fr-FR"),
                date: dateAvant,
              },
            ];
            const modalRef = this.modalService.open(SaisiesPeriodComponent, {
              backdrop: "static", keyboard :false
            });
            modalRef.componentInstance.periodNew = periodNew;
            modalRef.result.then((result) => {
              this.periodChanged(result);
            });
          }
          //ici
        },
        (error) => {
          let msg = error["error"] ? error["error"]["error"] + ": " : error;
          if(msg){
            this.alertService.error(msg);
          }
          console.log(error);
        }
      );
  }
  allRas(e) {
    Object.keys(this.model).forEach((property) => {
      if (this.model[property].dataPaieRas != undefined) {
        this.model[property].dataPaieRas = this.model["AllDataRas"];
      }
    });
  }
  empRasChange(empId, event) {
    this.model[empId].dataPaieRas = event.checked;

    if (!event.checked) {
      Object.keys(this.model[empId].dataPaies).forEach((property) => {
        this.model[empId].dataPaies[property].value = null;
      });
      this.model["AllDataRas"] = false;
    }
  }
  validate(f) {
    let formValid = true;
    ;
    let allRas = f.form.controls.AllDataRas.value;
    if (!allRas) {
      let ligneNonRas = new Array();
      Object.keys(f.form.controls).forEach((ctl) => {
        if (ctl != "AllDataRas") {
          let ctlNameParts = ctl.split("||'_'||");
          if (ctlNameParts.length > 0 && f.form.controls[ctl]['touched']) {
            let empDataPaieRas = this.model[ctlNameParts[0]]["dataPaieRas"];
            if (!empDataPaieRas) {
              if (!ligneNonRas.includes(ctlNameParts[0])) {
                ligneNonRas.push(ctlNameParts[0]);
              }
            }
          }
        }
      });
      ligneNonRas.forEach((ligne) => {
        let empDataPaie = this.model[ligne];
        let employeeToCheckRequired = Object.keys(empDataPaie.dataPaies)
          .filter((datap) => {
            return empDataPaie.dataPaies[datap]["mandatory"] === true;
          })
          .map((datap) => {
            return empDataPaie.dataPaies[datap].employeeId;
          });

        employeeToCheckRequired.forEach((empId) => {
          Object.keys(f.form.controls).forEach((ctl) => {
            let ctlNameParts = ctl.split("||'_'||");
            if (ctlNameParts.length == 2) {
              if (
                ctlNameParts[0] == empId &&
                ctlNameParts[1] != "ras" &&
                ctlNameParts[1] != "comment"
              ) {
                f.form.controls[ctl];
                let mandatory = this.model[empId].dataPaies[ctlNameParts[1]]
                  .mandatory;
                let value = this.model[empId].dataPaies[ctlNameParts[1]].value;

                if (mandatory && !value) {
                  this.model[empId].dataPaies[
                    ctlNameParts[1]
                  ].displayRequired = true;
                  formValid = false;
                }
              }
            }
          });
        });
      });
    }else {
      formValid = true;
    }
    return formValid;
  }
  save(form) {
    let bulkData = new Array<any>();
    if (this.model["AllDataRas"] != true) {
      for (let property of Object.keys(this.model)) {
        if("AllDataRas" != property){
        if (this.employeeLigneTouched(form, property)) {
          // Ras saisie pour employee
          if (this.model[property]["dataPaieRas"] == true) {
            let empData = {
              dataPaies: [],
              ras: true,
              employeeId: property,
            };
            bulkData.push(empData);
          } else {
            // data saisie pour employee
            let empData = {
              dataPaies: new Array(),
              ras: false,
              employeeId: property,
            };
            Object.keys(this.model[property]["dataPaies"]).forEach((varId) => {
              let tmp = this.model[property]["dataPaies"][varId];
              if(tmp.id ||tmp.value){
                tmp["noReport"] = false;
                empData.dataPaies.push(tmp);
                bulkData.push(empData);
              }

            });

          }
        }
      }
      }
    } else {
      for (let property of Object.keys(this.model)) {
        if("AllDataRas" != property){
        let empData = {
          dataPaies: [],
          ras: true,
          employeeId: property,
        };
        bulkData.push(empData);
      }
    }
    }
if(bulkData.length >0){
    this.submissionService.postMultiDataPaie(bulkData).subscribe(
      (data) => {
        this.alertService.success("saisies Validées");
      },
      (error) => {
        console.log(error);
        // this.alertService.error(error);
      }
    );
  }
}
  employeeLigneTouched(form: any, employeeId: string) {
    let tmp = form.controls[employeeId];
    for (let cltName of Object.keys(form.controls)) {
      if (cltName.startsWith(employeeId) && form.controls[cltName]["touched"]) {
        return true;
      }
    }
    return false;
  }
  periodChanged(date) {
    this.formService.raiserePeriodSelectChangedEvent(date ? date : null);
  }
  get dataAbsence() {
    return this.employeeAbsForm.get("dataAbsences") as FormArray;
  }
}
