import { Component, OnInit, OnDestroy } from '@angular/core';

import { HttpResponse } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { VariablesAbsenceService } from '../services/variable-absence-service';
// import { VariableAbsenceUpdateComponent } from './variable-paye-update.component';
// import { VariableAbsenceCreateComponent } from './variable-paye-create.component';
import { AlertService } from '../services/alert-service.service';
import { FormChangeService } from '../services/form-change.service';
import { CustomerService } from '../services/customer.service';
import { filter } from 'rxjs/operators';
import { Role } from '../class/role';
import { AuthenticationService } from '../services/authentication.service';
import { VariableAbsenceCreateComponent } from './variable-absence-create/variable-absence-create.component';
import { VariableAbsenceUpdateComponent } from './variable-absence-update/variable-absence-update.component';
import { VariableAbsence } from '../class/variableAbsence';
import { ModalRASComponent } from '../modal/modalRAS.component';

@Component({
  selector: 'app-variable-absence',
  templateUrl: './variable-absence.component.html',
  styleUrls: ['./variable-absence.component.scss']
})
export class VariableAbsenceComponent implements OnInit, OnDestroy {
  subscriptions = new Array<Subscription>();
  variablesAbs: any[] = [];
  selectedVariables: any;
  customerName: any;
  columnsHeaderToDisplay = ['Code variable','Libellé','Unité','Type','Date de création','Date de modification','Actions'
  ];
  constructor(protected variablesAbsenceService: VariablesAbsenceService,
    protected modalService: NgbModal,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    protected alertService: AlertService,
    protected customerService: CustomerService) { }

  ngOnInit(): void {
    this.rechercher();
    // this.subscribeformChange();
    this.subscribeReloadSearchRequest();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub =>{
      sub.unsubscribe();
    });
  }
  subscribeReloadSearchRequest() {
    this.subscriptions.push(
    this.formService.reloadAbsencesEvent.subscribe(
      () => {
        this.rechercher();
      }));
  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppression d'une variable";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }
  rechercher() {
    this.subscriptions.push(
    this.variablesAbsenceService.getVariableAbsence(this.authenSer.getUserLoggedIn()).subscribe(
      (param: any) => {
        // this.formService.raiseVariablePaieListEvent(param);
        this.variablesAbs = param;
      },
      error => {
        console.log(error);
      }
    ));

  }
  trackId(index: number, item: any): number {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    return item.id!;
  }
  delete(variableAbsence: any): void {
    this.subscriptions.push(
      this.variablesAbsenceService.delete(variableAbsence).subscribe(
        (data) => {
          this.alertService.success("Variable Effacée !");
          this.rechercher();
        },
        () => {
          this.alertService.warn('Erreur de suppression! Votre variable n\'a pas été supprimée.');
        }
      ));

  }

  edit(variableAbs: VariableAbsence) {
    const modalRef = this.modalService.open(VariableAbsenceUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.variableAbs = variableAbs;
  }
  create(): void {
    const modalRef = this.modalService.open(VariableAbsenceCreateComponent, { backdrop: 'static' });
  }

}

