import { Component, OnInit, Inject, LOCALE_ID, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { FormChangeService } from '../services/form-change.service';
import { EmployeeService } from '../services/employee.service';
import { AlertService } from '../services/alert-service.service';
import { SubmissionService } from '../services/submission.service';
import { CustomerService } from '../services/customer.service';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { VariablesPayeService } from '../services/variable-paye-service';
import { formatDate } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersCreateComponent } from '../customer/customers-create/customers-create.component';
import { Subscription } from 'rxjs';
import { EmployeesImportComponent } from '../employees/employees-import/employees-import.component';
import { CustomersImportComponent } from '../customer/customers-import/customers-import.component';

@Component({
  selector: 'app-form-min-customers',
  templateUrl: './form-min-customers.component.html',
})
export class FormMinCustomersComponent implements OnInit, OnDestroy {
  subscriptions = new Array<Subscription>();
  sites: {};
  selectedCustomer: string;
  customers;
  selectedSite: any[];
  configurations = [];
  selectedCConfiguration;
  selectAll: boolean;
  searchText: string;
  searchTextCust: string;
  numeroMin: number;
  numeroMax: number;
  model: NgModel;
  countAnnonces;
  @Input()
  withPeriod: boolean;
  period: any;
  selectedPeriod: string;
  periodList: any[];
  constructor(
    private alertService: AlertService,
    private customerService: CustomerService,
    private authenSer: AuthenticationService,
    private formService: FormChangeService,
    public variablePaieService: VariablesPayeService,
    protected modalService: NgbModal,
    public router: Router) { }
    ngOnDestroy(): void {
      this.subscriptions.forEach(sub =>{
        sub.unsubscribe();
      });
    }

  ngOnInit() {
    if (this.authenSer.isAdmin) {
      this.rechercher();
      this.subscribeReloadSearchRequest();
    }
  }
  import(customer) {
    const modalRef = this.modalService.open(CustomersImportComponent, { backdrop: 'static' });
    // modalRef.componentInstance.customers = customer;
    modalRef.result.then(result => {
    });
  }
  subscribeReloadSearchRequest() {
    this.subscriptions.push(
    this.formService.reloadCustomerEvent.subscribe(
      () => {
        this.rechercher();
      }));
  }
  // efface le form de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }

  onChange() {
    this.rechercher();
  }
  createCustomer(): void {
    this.router.navigateByUrl('/create-customers', { queryParams: { returnUrl: '/customers' } });

  }
  rechercher() {
    let filter = this.getFilter();

    this.subscriptions.push(
      this.getCustomersFromBackEnd(filter)
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          if (tmp) {
            if (filter) {
              // if (filter['searchText']) {
                tmp = this.findTextInCustomers(tmp, filter);
              }
            // }
          }
          this.formService.raiseCustomerListEvent(tmp);
        }, error => {
          console.log(error);

        }));
  }

  private findTextInCustomers(customers, text) {
    text = text?text.split(' '):'';
    return customers.filter(function (item) {

      return text.every(function (el) {
        return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1 ||
          item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }

  getCustomersFromBackEnd(filter) {
    return this.customerService.getCustomers();
  }

  private getFilter() {
    let filter = this.searchText ? this.searchText : null;
    return filter;
  }

}
