import { Injectable } from '@angular/core';
import { ConditionGeneralVenteComponent } from '../tenants/condition-general-vente/condition-general-vente.component';
import { CanDeactivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CgvGuardService implements CanDeactivate<ConditionGeneralVenteComponent>{

  constructor(private modalService: NgbModal,public router: Router,private authServ: AuthenticationService) { }
  canDeactivate(component: ConditionGeneralVenteComponent,route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
    let ask = component.askAcceptence;


    if(this.authServ.isUserLoggedIn() &&  ask){
      if(!component.accepted) {
      const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
        modalRef.componentInstance.title = 'Condition générales';
        modalRef.componentInstance.message = 'Avant de continuer nous avons besoin de votre accord';
        modalRef.componentInstance.withCancel = false;
      return false;
    }
  }
  return true;
}
}
