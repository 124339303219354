import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { Role } from '../class/role';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
//  currentUser: any;

  constructor(public authenService: AuthenticationService,public router: Router) {
    this.authenService.getUserLoggedIn();//currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
  }
  prefNoLogo(){
    return this.authenService.getPrefHideLogo();
  }
  userLoggedIn() {
    return this.authenService.isUserLoggedIn();
  }
  logout() {

    this.authenService.logout();
  }
    get currentUser() {
      let tmp = this.authenService.getUserLoggedIn();
      return tmp;
  }
  changePass(){
     this.router.navigate(['/loginChange']);
  }

  profil(){
     this.router.navigate(['/profil']);
  }

  params(){
     this.router.navigate(['/params']);
  }
}
