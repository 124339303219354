import { Component, OnInit, Input } from '@angular/core';
import { EmployeeService } from '../services/employee.service';
import { FormChangeService } from '../services/form-change.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AbsencesCreateComponent } from '../absences/absences-create/absences-create.component';
import { SubmissionAbsenceService } from '../services/submission-absence.service';
import { AbsencesUpdateComponent } from '../absences/absences-update/absences-update.component';
import { DataAbsenceService } from '../services/dataAbsence.service';
import { SubmissionService } from '../services/submission.service';
import { AuthenticationService } from '../services/authentication.service';
import { formatDate } from '@angular/common';
import { VariablesAbsenceService } from '../services/variable-absence-service';
import { AlertService } from '../services/alert-service.service';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { Civilitee } from '../class/unit.enum';

@Component({
  selector: 'app-absence-min',
  templateUrl: './absence-min.component.html',
  styleUrls: ['./absence-min.component.scss']
})
export class AbsenceMinComponent implements OnInit {

  subscription: any;
  moment = moment;
  @Input() employee: any;
  dataAbsenceEmployeePeriod: any[];
  period: any;
  varAbs: any;
  columnsHeaderToDisplay = ["Type d'absence",'Debut','Fin','nbDay','Actions'
];
civiliteeEnum= Civilitee;

  constructor(private dataAbsenceService: DataAbsenceService,
    private submissionAbsenceService: SubmissionAbsenceService,
    private formService: FormChangeService,
    public router: Router, private modalService: NgbModal,
    private submissionService: SubmissionService,
    private authenSer: AuthenticationService,
    private alertService: AlertService,
    private variablesAbsenceService: VariablesAbsenceService,
    private absenceVarService: VariablesAbsenceService,
  ) { }
  @Input() absence: {};

  ngOnInit() {

    this.getDataAbsence();
  }



  getDataAbsence() {

    this.variablesAbsenceService.getVariableAbsence(this.authenSer.getUserLoggedIn()).subscribe(
      (varAbs: any) => {
        this.varAbs = varAbs as Array<any>;
        this.submissionService.getPeriod(this.authenSer.getUserLoggedIn()).subscribe(
          (param) => {
            if (param) {
              this.period = param;
              this.dataAbsenceService.getDataAbsenceByEmployeePeriod(this.employee.id, this.period).subscribe(
                (dataAbsEmplPeriod) => {
                  this.dataAbsenceEmployeePeriod = dataAbsEmplPeriod as Array<any>;
                  //rework absences
                  if(this.dataAbsenceEmployeePeriod.length > 1){
                    this.dataAbsenceEmployeePeriod = this.dataAbsenceEmployeePeriod.filter(
                      abs =>{
                        //dans ce cas on garde pas l'absence RAS
                        if(abs.variableId  && !abs.noReport){
                          return true;
                        } else {
                          return false;
                        }
                      }
                    )
                  }
                  if(this.dataAbsenceEmployeePeriod){
                  this.dataAbsenceEmployeePeriod.forEach(empVar => {
                    this.varAbs.filter(elem => {
                      return elem.id === empVar.variableId;

                    }).map(el => {
                      empVar['name'] = el.name;
                      this.compputeNbrDays(empVar);
                    })}
                  )}
                },
                error => {
                  console.log(error);
                }
              );
            }
          },
          error => {
            console.log(error);
          }
        );

      },
      error => {
        console.log(error);
      }
    );
  }
  compputeNbrDays(el: any) {
    let end = el.endAbsence;
    let start = el.startAbsence;
    let startHalfDay = el.startHalfDay != undefined ? el.startHalfDay : false;
    let endHalfDay = el.endHalfDay != undefined ? el.endHalfDay : false;

    if(end){
      if(start){
      this.absenceVarService.getNbrJour(start, startHalfDay,end, endHalfDay).subscribe(
        data =>{

          el['nbDay']= data;
        },
        error =>{
          console.log(error);
        }
      );
      }
    }
    }




  createDataAbsence(employee): void {
    let modalRef ;

    if(this.dataAbsenceEmployeePeriod.length> 0){
      modalRef = this.modalService.open(AbsencesUpdateComponent, { backdrop: 'static' })
      modalRef.componentInstance.title =
      "Modifications des absences pour " +
      employee.firstname +
      "  " +
      employee.name;
    modalRef.componentInstance.employee = employee;
    // modalRef.componentInstance.period = this.selectedPeriod;
    modalRef.componentInstance.withAddBtn = true;
    modalRef.componentInstance.showDelete = true;
    }else{
      modalRef = this.modalService.open(AbsencesCreateComponent, { backdrop: 'static' })
    }
    // const modalRef = this.modalService.open(AbsencesCreateComponent, { backdrop: 'static' })
    modalRef.componentInstance.employee = employee;
    modalRef.componentInstance.period = this.period;
    modalRef.result.then(result => {
      let lstDataAbsences = result as Array<any>;
      if (result && lstDataAbsences.length > 0) {
        // lstDataAbsences = result['dataAbsences'];
        //
        this.submissionAbsenceService.postDataAbsence(lstDataAbsences).subscribe(
          (data: any) => {
            this.formService.raisereReloadAbsencesEvent();
            this.alertService.success('Absence Créé');
          },
          error => {
            console.log(error);
          }
        );
      }
    });
  }
  openDeleteConfirm(id): void {
    // todo backend

    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'une absence";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.dataAbsenceService.deleteDataAbsence(id).subscribe(
          data =>{
            this.formService.raisereReloadAbsencesEvent();
          },
          error =>{
            this.alertService.error(error);
          }
        )
      }
    });
  }
  updateDataAbsence(employee, absence: any) {
    const modalRef = this.modalService.open(AbsencesUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Modifications des variables d'absence pour " + this.employee.firstname + "  " + this.employee.name;
    modalRef.componentInstance.employee = employee;
    modalRef.componentInstance.absence = [absence];
    modalRef.componentInstance.period = this.period;
    modalRef.result.then(result => {
      let lstDataAbsences = result as Array<any>;
      if (result && lstDataAbsences.length > 0) {
        lstDataAbsences.forEach(abs =>{
          this.submissionAbsenceService.putDataAbsenceUnit(abs).subscribe(
            (data: any) => {

              this.formService.raisereReloadAbsencesEvent();
              this.alertService.success('Absence Mise à jour',true);
            },
            error => {
              this.formService.raisereReloadAbsencesEvent();
              let msg = error['error'] ? error['error']['error'] + ': ' : error;
              this.alertService.error(msg,true);
            }
          );
        });

      }
    });
    // modalRef.result.then(result => {
    //   if (result != false) {
    //     this.submissionAbsenceService.putDataAbsence(result).subscribe(
    //       (data: any) => {

    //         this.formService.raisereloadSearchRequestEvent();
    //       },
    //       error => {
    //         this.formService.raisereloadSearchRequestEvent();
    //         console.log(error);
    //       }
    //     );
    //   }
    // });
  }
}
