import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
const apiUrl = environment.serverUrl + 'api/';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor() {

  }
  getSageFileLink(){// http://dev.devarchi.fr:8445/api/user/upload/downloadSageFile
    return apiUrl + 'user/upload/downloadSageFile';
  }
  getRapportFileLink(){// http://dev.devarchi.fr:8445/api/user/upload/downloadReport
    return apiUrl + 'user/upload/downloadReport';
  }
}
