import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { Submission } from "../class/submission";
import { SubmissionService } from "../services/submission.service";
import { VariablesPayeService } from "../services/variable-paye-service";
import { AuthenticationService } from '../services/authentication.service';
import { formatDate } from '@angular/common';
import { AlertService } from '../services/alert-service.service';
@Component({
    selector: "app-period-update",
    templateUrl: "./saisies-period.component.html",
    styleUrls: ["./saisies.component.scss"]
})
export class SaisiesPeriodComponent implements OnInit {
    @Input() title = "Veuillez sélectionner une période.";
    @Input() employee: any;
    objSubmission: Submission;
    periodNew: any[];
    selectedDate: string;
    // model: any = {};
    // listVars: Array<any>;

    constructor(
        public activeModal: NgbActiveModal,
        private authenSer: AuthenticationService,
        private employeeService: SubmissionService,
        private alertService: AlertService
    ) { }

    validate() {
        let period = formatDate(this.selectedDate, 'MMyy', 'fr-FR')
        this.employeeService.postPeriod(period).subscribe(
            (result: any) => {

                this.alertService.success('Période validée. ');
                this.activeModal.close(period);
            },
            error => {
                console.log(error);
            }
        )
    }

    ngOnInit() {
    }
}
