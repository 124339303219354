import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { AlertService } from '../services/alert-service.service';
import { MessagesService } from '../services/messages.service';
import { ModalComponent } from '../modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from '../services/form-change.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model: any = {};
  returnUrl = '/home';
  loginForm;
  constructor(public authenticationService: AuthenticationService,
    public alertService: AlertService,
    public messageSer: MessagesService,
    private modalService: NgbModal,
    public authenSer: AuthenticationService,
    private formService: FormChangeService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder) {
      // subscribe to router event
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        let hideLogo = params['hideLogo'];
        if(hideLogo && hideLogo == "true"){
          this.authenSer.setPrefHideLogo(true);
        }
      });
    }

  ngOnInit() {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        let hideLogo = params['hideLogo'];
        if(hideLogo && hideLogo == "true"){
          this.authenSer.setPrefHideLogo(true);
        }
      });
    this.loginForm = this.fb.group({
      username: new FormControl('',[Validators.required]),
      password: new FormControl('',[Validators.required]),
    });
    if (this.authenticationService.isUserLoggedIn()) {
      this.router.navigate(['/home']);
    }
  }
  login() {

    this.authenticationService.login(this.loginForm.value.username, this.loginForm.value.password)
      .subscribe(
        data => {
          let user = data['user'];
          this.authenticationService.setCurretUser(user);

          if (this.authenticationService.isUser || this.authenticationService.isAdmin){
          if(!user.contractAccepted ){
            this.router.navigate(['conditions', 'ask'],{  skipLocationChange: true});
            return;
            } else {
            if (this.authenticationService.isUser) {
              this.messageSer.getAdminMessages().subscribe(
                data => {
                  let messages = data as Array<any>;
                  if(messages && messages.length >0){
                    this.formService.raiseUserMessagesListEvent(messages);
                  }
                  if(messages && messages.length>0){
                    this.openMessage(messages);
                  }

                },
                error => {
                  let msg = error["error"] ? error["error"]["error"] + ": " : error;
                }
              );
            }
          }
          }
          this.router.navigate([this.returnUrl]);
        },
        error => {

          const msg = 'L\'identifiant ou le mot de passe est incorrect';
          this.alertService.error(msg);
        });
  }
  openMessage(messages: Array<any>) {
    const modalRef = this.modalService.open(ModalComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Message de votre gestionnaire ";
    let firstMsg = messages[0];
    modalRef.componentInstance.msg = firstMsg;
    let remainingMsgs = new Array<any>();
    modalRef.result.then(messageId => {

      if (false != messageId) {
        this.messageSer.acknowledgeMessage(messageId).subscribe(
          data => {
            remainingMsgs = messages.filter(el => el.id != messageId);
          },
          error => {
            const msg = "erreur lors de l'aquitement du message";
            this.alertService.error(msg);
          }
        );
      } else {
        remainingMsgs = messages;
      }

      if(remainingMsgs.length >0){
        this.formService.raiseUserMessagesListEvent(remainingMsgs);
      }
    });
  }
}
