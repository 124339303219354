import { Component, OnInit, Inject, LOCALE_ID, Output, EventEmitter, Input } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { FormChangeService } from '../services/form-change.service';
import { CustomerService } from '../services/customer.service';
import { AlertService } from '../services/alert-service.service';
import { VariablesPayeService } from '../services/variable-paye-service';
import { AuthenticationService } from '../services/authentication.service';


@Component({
  selector: 'app-form-min-variable-paie',
  templateUrl: './form-min-variable-paie.component.html',
  styleUrls: ['./form-min-variable-paie.component.scss']
})
export class FormMinVariablePaieComponent implements OnInit {

  sites = [
    { id: 1, name: 'Avignon' },
    { id: 2, name: 'Marseille' }];
  customerName;
  selectedCustomer: any[];
  customers;
  selectedSite;
  searchText: string;
  model: NgModel;

  constructor(
    private alertService: AlertService,
    private referencielService: CustomerService,
    private variableService: VariablesPayeService,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.rechercher();
    this.subscribeReloadSearchRequest();
  }
  subscribeReloadSearchRequest() {
    this.formService.reloadVariablePaieSearchRequest.subscribe(
      () => {
        debugger;
        this.rechercher();
      });
  }
  clearForm() {
    this.rechercher();
  }

  onChange() {
    this.rechercher();
  }

  rechercher() {
    let filter = this.getFilter();
    this.getCustomersFromBackEnd(filter)
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.customers = data;

          if (tmp) {
            if (filter) {
              // if (filter['searchText']) {
                tmp = this.findTextInCustomers(tmp, filter);
              }
            // }
          }
          debugger;
          this.selectedCustomer = tmp;
          //pour afficher le nom du cutomer en haut de la liste.
          if (this.selectedCustomer && this.selectedCustomer.length == 1) {
            this.customerName = this.selectedCustomer[0]['companyName'];
          }
          this.getVarPaieFromBackend()
            .subscribe(
              data => {
                let tmp = data as Array<any>;
                let tmp_filtred = new Array();
                  if (this.selectedCustomer && this.selectedCustomer.length > 0
                    && this.customers.length != this.selectedCustomer.length ) {
                      debugger;
                    tmp = tmp.filter(el => {
                      let selectedIds = this.selectedCustomer.map(el => el.id);
                        // let tt = tmp.filter(el =>{
                          let elCusId = el.customers.map(el => el.id);
                          if(elCusId.indexOf(selectedIds[0])> -1){
                            return el;
                          } else {
                            return null;
                          }

                        // })
                      })
                      // let f = this.selectedCustomer.find(cus => cus.id === el.customerId);

                  }
                  debugger;
                  this.formService.raiseVariablePaieListEvent(tmp);
              }
              , error => {
                console.log(error);
                this.formService.raiseVariablePaieListEvent({});
              });
        }
        , error => {
          console.log(error);
          this.formService.raiseCustomerListEvent({});
        });

  }
  getCustomersFromBackEnd(filter) {
    return this.referencielService.getCustomers();
  }
  getVarPaieFromBackend() {
    return this.variableService.getVariablePaye();
  }
  private findTextInCustomers(customers, text) {
    text = text?text.split(' '):'';

    return customers.filter(function (item) {

      return text.every(function (el) {
        return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1
          || item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }

  private getFilter() {

    let filter =  this.searchText ? this.searchText : null;
    return filter;
  }

}
