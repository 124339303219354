import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpParams,
  HttpClient,
  HttpResponse
} from "@angular/common/http";
import { IVariableAbsence, VariableAbsence } from "../class/variableAbsence";
import { Observable } from "rxjs";
import { CustomerService } from "./customer.service";
import { Role } from "../class/role";
import { environment } from "src/environments/environment";
import { createOfflineCompileUrlResolver } from "@angular/compiler";
import * as moment from "moment";
import { AuthenticationService } from './authentication.service';

const apiUrl = environment.serverUrl + "api/";
@Injectable({
  providedIn: "root"
})
export class VariablesAbsenceService {
  getEndDate(start: any, startHalfDay, endHalfDay, nbr: any) {
    const url = apiUrl + "dateCalculator/getEndDate";
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get(
      url +
        "?startDate=" +
        moment(start).format("DD/MM/YYYY") +
        "&startHalfDay="+startHalfDay +
        "&endHalfDay="+ endHalfDay +
        "&days=" +nbr,
      { withCredentials: true }
    );
  }
  getNbrJour(start: any, startHalfDay: boolean, end: any, endHalfDay: boolean) {
    const url = apiUrl + "dateCalculator/getNbWorkingDays";
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get(
      url +
        "?startDate=" +
        moment(start).format("DD/MM/YYYY") +
        "&startHalfDay="+startHalfDay +
        "&endDate=" +
        moment(end).format("DD/MM/YYYY") +
        "&endHalfDay="+ endHalfDay,
      { withCredentials: true }
    );
  }

  delete(variableAbsId: any): Observable<any> {
    const url = apiUrl + "admin/variableAbsence";
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.delete(url + "/" + variableAbsId, {
      withCredentials: true
    });
  }

  getVariableAbsence(currentUser: any) {
    // let subUrl = Role.valueOf(currentUser.role).value;
    let subUrl;
        if(this.authenSer.isAdmin){
          subUrl = 'admin';
        } else {
          subUrl = 'user';
        }
    const url = apiUrl + subUrl + "/variableAbsence";
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get(url, { withCredentials: true });
  }
  postVariableAbsenceCreate(
    variable: IVariableAbsence,
    customerId?: any
  ): Observable<any> {
    // let subUrl = '?customerIds=' + customerId[0];

    // for (let i = 1; i < customerId.length; i++) {
    //     subUrl += '&customerIds=' + customerId[i];
    //     console.log(subUrl);
    // }
    //  variable.customerId = null;

    const url = apiUrl + "admin/variableAbsence";
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post(url, variable, { withCredentials: true });
  }
  putVariableAbsence(variable: IVariableAbsence): Observable<any> {
    const url = apiUrl + "admin/variableAbsence";
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put(url, variable, { withCredentials: true });
  }

  getVariableType(currentUser: any) {
    // let subUrl = Role.valueOf(currentUser.role).value;
    let subUrl;
        if(this.authenSer.isAdmin){
          subUrl = 'admin';
        } else {
          subUrl = 'user';
        }
    const url = apiUrl + subUrl + "/variableAbsence/type";
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get(url, { withCredentials: true });
  }

  constructor(
    private http: HttpClient,
    private authenSer: AuthenticationService
  ) {}
}
