import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AlertService } from './alert-service.service';
import { Role } from '../class/role';
import { environment } from 'src/environments/environment';
// const SERVERLOCAL = 'http://dev.devarchi.fr:8445/';
// const SERVERPROD = 'http://paie.devarchi.fr:8445/';
// const SERVER = true ? SERVERLOCAL : SERVERPROD;
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  keepAlive(){
    const url = environment.serverUrl + 'api/users/keepAlive' ;
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, {observe:'response', withCredentials: true });
  }
  setCurretUser(data: any) {
    localStorage.setItem('currentUser', JSON.stringify(data));
  }
  setPrefHideLogo(withLogo){
    localStorage.setItem('hideLogo', JSON.stringify(withLogo));
  }
  getPrefHideLogo(){
    return JSON.parse(localStorage.getItem('hideLogo'));
  }
  constructor(private http: HttpClient, private router: Router,
    private alertService: AlertService) { }

  login(username: string, password: string) {
    let url = environment.serverUrl + 'login';
    if (password && username) {
      url += '?username=' + username;
      url += '&password=' + password;
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.post(url, null, { headers, withCredentials: true });

  }
  resetPassword(email: any) {
    let url = environment.serverUrl + 'api/users/resetPwd';
      url += '?login=' + email;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(url, null);
  }
  changePassword(oldPassword: any, newPassword: any) {
    let url = environment.serverUrl + 'api/users/changePwd';
      url += '?oldPassword=' + oldPassword+'&newPassword='+newPassword+'&passwordConfirm='+newPassword;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(url, null, { headers, withCredentials: true });
  }

  logout() {
    // remove user from local storage to log user out

    const url = environment.serverUrl + 'logout';
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.http.post(url, null, { headers, observe: 'response' }).subscribe(
      data => {

        const res = data;
        localStorage.removeItem('currentUser');
        this.router.navigate(['/login']);
      },
      error => {

        this.alertService.error(error.message);
        localStorage.removeItem('currentUser');
        this.router.navigate(['/login']);
      }
    );

  }
  isUserLoggedIn() {
    return !!localStorage.getItem('currentUser');
  }
  getUserLoggedIn() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }
  get isAdmin() {
    let user = this.getUserLoggedIn();

    return user && (user.roles.includes(Role.Admin.toString()));
  }
get UserHasAdminPrevilege(){

  return this.isAdmin || this.isSupperAdmin;
}
  get isSale() {
    let user = this.getUserLoggedIn();
    return user && (user.roles.includes(Role.Sale.toString()));
  }
  get isUser() {
    let user = this.getUserLoggedIn();

    return user && (user.roles.includes(Role.User.toString()));
  }
  get isSupperAdmin() {
    let user = this.getUserLoggedIn();
    return user && (user.roles.includes(Role.SupperAdmin.toString()));
  }
  getUserApiTargetUrl(){
    let subUrl;
    if(this.isUser){
      subUrl = 'user';
    }
    if(this.isSale){
      subUrl = 'sale';
    }
    if(this.isAdmin){
      subUrl = 'admin';
    }
    if(this.isSupperAdmin){
      subUrl = 'superadmin';
    }
    return subUrl;
  }
}
